import styled from 'styled-components';

import RadioCheckedIcon from '../../../assets/images/radio_button_checked.svg';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid transparent;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(Button)`
  background: #003f2d;
  color: #ffffff;

  &:disabled {
    background: #1a1a1a1f !important;
    color: #1a1a1a75 !important;
  }

  &:hover {
    color: #ffffff;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #003f2d;
    cursor: pointer;
  }

  &:focus {
    color: #ffffff;
    background: #003f2d;
    box-shadow: 0px 0px 2px 2px #003f2d;
  }
`;

export const SecondaryButton = styled(Button)`
  color: #003f2d;
  border: 1px solid #003f2d;
  background: #ffffff;

  &:hover {
    background: #1a1a1a0a;
    border-color: #003f2d;
    cursor: pointer;
  }

  &:disabled {
    background: rgba(26, 26, 26, 0.12);
    color: rgba(26, 26, 26, 0.46);
    border: none;
    cursor: not-allowed;
  }
`;

export const RadioButton = ({ checked, onChange }) => (
  <RadioButtonContainer checked={checked} onClick={onChange}>
    <img src={RadioCheckedIcon} width={20} height={20} style={{ opacity: checked ? '1' : '0' }} />
  </RadioButtonContainer>
);

const RadioButtonContainer = styled.button`
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: ${({ checked }) =>
    !checked ? '1px solid rgba(0, 0, 0, 0.54)' : '1px solid rgba(0, 0, 0, 0.01)'};
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
  }
`;
