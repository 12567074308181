import React, { useCallback, useEffect, useState, useContext } from 'react';

import DropdownTreeSelect from 'react-dropdown-tree-select';

import './ProviderSearch.css';
import './searchStyles.css';
import { SearchService } from './SearchService';

// const assignObjectPaths = (obj, stack) => {
//   Object.keys(obj).forEach((k) => {
//     const node = obj[k];

//     if (typeof node === 'object') {
//       node.path = stack ? `${stack}.${k}` : k;
//       assignObjectPaths(node, node.path);
//     }
//   });
// };

const regions = ['APAC', 'EMEA', 'LATAM', 'NAM'];

const ProviderSearch = (props) => {
  const [countriesData, setCountriesData] = useState([]);

  const { selectedData, onSelectData } = props;

  const getNodePathfromId = (id = '') => {
    // example of a node id : 'rdts1-1-5-0-0'
    // where rdts means react-dropdown-tree-select (name of the package), which is a base string
    // rdts1 represents the (root + 1) level branch index
    // rest of the symbols (1-5-0-0) represents the path of the selected node starting from (root + 1) level node

    // reversing the path to traverse
    const selectedNodePath = id.split('-').reverse();

    // removing the base string rdts{n}
    selectedNodePath.pop();

    return selectedNodePath;
  };

  const onChangeTreeValue = (data, key, value) => {
    data[key] = value;
    if (!!data.children && Array.isArray(data.children)) {
      data.children.forEach((_c) => {
        onChangeTreeValue(_c, key, value);
      });
    }
  };

  const onChangeCountriesData = (data, nodepath = [], key, value = false, applyToTree = false) => {
    if (nodepath.length === 0 || !data) return;
    const index = Number(nodepath.pop());

    if (typeof index !== 'number') return;

    if (nodepath.length === 0) {
      data.children[index][key] = value;
      applyToTree && onChangeTreeValue(data.children[index], key, value);
    } else onChangeCountriesData(data.children[index], nodepath, key, value, applyToTree);
  };

  const onChange = (currentNode, selectedNodes) => {
    localStorage.setItem('selectedOprionsCount', selectedNodes.count);

    selectedNodes && onSelectData(selectedNodes);

    const selectedNodeId = currentNode._id;
    const selectedNodeValue = currentNode.checked;

    const selectedNodePath = getNodePathfromId(selectedNodeId);

    onChangeCountriesData(
      { children: countriesData },
      selectedNodePath,
      'checked',
      selectedNodeValue,
      true,
    );
  };

  const onNodeToggle = (currentNode) => {
    const selectedNodeId = currentNode._id;
    const selectedNodeValue = currentNode.expanded;
    const selectedNodePath = getNodePathfromId(selectedNodeId);

    onChangeCountriesData(
      { children: countriesData },
      selectedNodePath,
      'expanded',
      selectedNodeValue,
      !selectedNodeValue, // apply the value to entire tree when the parent is collapsed(false)
    );
  };

  const clearSelection = () => {
    onChangeCountriesData({ children: [{ children: countriesData }] }, [0], 'checked', false, true);
    onSelectData([]);
  };

  const clearSearchVal = () => {};

  const fetchCountriesByRegion = useCallback(async () => {
    const countriesArray = [];

    try {
      const res = await Promise.all(regions.map((region) => SearchService.getCountries(region)));

      // isDefaultValue
      regions.filter((region, key) => {
        const citiesObj = SearchService.generateSearchDataObject(region, res[key]?.data);

        countriesArray.push(citiesObj);
      });

      setCountriesData(countriesArray);
    } catch (e) {
      console.log(e);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCountriesByRegion();
  }, [fetchCountriesByRegion]);

  return (
    <>
      {countriesData && (
        <div style={{ position: 'relative' }}>
          <DropdownTreeSelect
            texts={{ placeholder: 'Search by Region, Country, State, City' }}
            data={countriesData}
            onChange={onChange}
            onNodeToggle={onNodeToggle}
            className="mdl-demo"
            // clearSearchOnChange={false}
            keepOpenOnSelect={true}
            // keepChildrenOnSearch={true}
            keepTreeOnSearch={true}
            showPartiallySelected={true}
            hierarchical={false}
            // onAction={onAction}
          />
          <button
            style={{
              right: '10px',
              position: 'absolute',
              zIndex: '100',
              top: '18px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
            }}
            onClick={clearSearchVal}
          >
            X
          </button>
          {/* {isClearAllBtnVisible && ( */}
          <button
            className="clearSearchBtn"
            disabled={selectedData.length === 0}
            onClick={clearSelection}
          >
            Clear All
          </button>
          {/* )} */}
        </div>
      )}
    </>
  );
};

export default React.memo(ProviderSearch);
