import React, { useState } from 'react';

import axios from 'axios';
import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { PrimaryButton, SecondaryButton } from '../../common/Button';
import { APPLICATION_HEADERS, API_URLS } from '../../common/headers';
import ModalScreen from '../../common/Modal';
import SnackBar from '../../common/Snackbar';
import { Block } from '../../common/StyledComponents/StyledBlock';
import '../ProviderLocations.css';

const STAGES = {
  ARE_YOU_SURE: 'are_you_sure',
  REASON: 'reason',
};

const reasons = ['Closed', 'Not a coworking space', 'Not available', 'Not interesting'];

const DeleteLocationModal = ({ isOpen, onClose, label, item }) => {
  const [reason, setReason] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [currentStage, setCurrentStage] = useState(STAGES.ARE_YOU_SURE);
  const [reasonDropDownOpen, setReasonDropDownOpen] = useState(false);

  const onRemove = async () => {
    if (!reason) {
      setIsError(true);

      return;
    }

    const url = API_URLS.deleteProviderLocationsURL;
    const userDataStore = JSON.parse(sessionStorage.getItem('USER_DATA'));

    const locationObj = {
      provider_id: item.provider_id,
      updatedBy: userDataStore['User email'],
      soft_delete_summary: {
        reason,
        is_softdeleted: 'yes',
      },
    };

    setIsLoading(true);

    try {
      const res = await axios.put(url, locationObj, {
        headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
      });

      setIsSnackBarOpen(true);
      onClose('deleted');
    } catch (e) {
      console.error('Occurred error while trying to delete option, ', e);
      // onFail?.();
    } finally {
      setIsLoading(false);
      // onClose();
    }
  };

  const handleSelectReason = (reason) => {
    setReason(reason);
    setReasonDropDownOpen(false);
  };

  React.useEffect(() => {
    if (!isOpen) {
      setCurrentStage(STAGES.ARE_YOU_SURE);
      setReason('');
      isError && setIsError(false);
    }
  }, [isOpen, isError]);

  const handleChange = (e) => {
    setReason(e.target.value);
    setReasonDropDownOpen(false);
  };

  return (
    <>
      {isSnackBarOpen && (
        <SnackBar
          title="Location successfully deleted"
          delay={8000}
          onClose={() => setIsSnackBarOpen(false)}
        />
      )}
      <ModalScreen isOpen={isOpen} onClose={onClose} label={label} width="30%">
        <Container>
          {currentStage === STAGES.ARE_YOU_SURE && (
            <>
              <Block display="flex" flexDirection="column" gap="8">
                <Title>Are you sure you want to delete this provider location ?</Title>
                <Title fontSize="16px">Provider Name: {item?.provider_name}</Title>
                <Title fontSize="16px">Location: {item?.formatted_address || 'N/A'}</Title>
              </Block>
              <Block display="flex" marginTop="25" gap="30">
                <BackButton onClick={onClose}>No</BackButton>
                <NextButton onClick={() => setCurrentStage(STAGES.REASON)}>Yes</NextButton>
              </Block>
            </>
          )}
          {currentStage === STAGES.REASON && (
            <ReasonContainer>
              {isLoading ? (
                <Block
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="420"
                  marginBottom="20"
                >
                  <Spinner />
                </Block>
              ) : (
                <Block display="flex" flexDirection="column" gap="20">
                  {isError ? (
                    <Error>Please choose the reason</Error>
                  ) : (
                    <Reason>Select reason for deletion</Reason>
                  )}
                  <DropDownContainer>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select1"
                        value={reason}
                        label="Select Reason"
                        onChange={handleChange}
                      >
                        <MenuItem className="muiCustomMenuStyles" value="Closed">
                          Closed
                        </MenuItem>
                        <MenuItem className="muiCustomMenuStyles" value="Duplicate">
                          Duplicate
                        </MenuItem>
                        <MenuItem className="muiCustomMenuStyles" value="Not a Flex space">
                          Not a Flex space
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </DropDownContainer>
                  <Block display="flex" marginTop="25" gap="30">
                    <BackButton onClick={onClose}>Cancel</BackButton>
                    <NextButton onClick={onRemove}>Delete Location</NextButton>
                  </Block>
                </Block>
              )}
            </ReasonContainer>
          )}
        </Container>
      </ModalScreen>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const ReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  .MuiInputBase-input {
    padding: 16.5px 14px !important;
  }
`;

const Title = styled.div`
  font-weight: 500;
  color: #1a1a1a;
  font-size: ${({ fontSize }) => fontSize || '20px'};
`;

export const Spinner = styled.div`
  width: ${({ width }) => width || '80'}px;
  height: ${({ height }) => height || '80'}px;
  display: inline-block;

  &:after {
    content: '';
    display: block;
    width: ${({ width }) => width - 16 || '64'}px;
    height: ${({ height }) => height - 16 || '64'}px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #009f6f;
    border-color: #009f6f transparent;
    animation: spinner 1.2s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ReasonSelect = styled.select`
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
  border-radius: 8px;
  z-index: 1000;
  padding: 10px 15px;
  cursor: pointer;
  width: 50%;
  font-size: 16px;
`;
const ReasonOptions = styled.option`
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
  border-radius: 8px;
  z-index: 1000;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
`;

const Reason = styled.div`
  font-weight: 400;
  color: #1a1a1a;
  font-size: 16px;
`;

const ReasonOption = styled(Reason)`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  gap: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(26, 26, 26, 0.12);
  }
`;

const DropDownContainer = styled.div`
  display: inline-flex;
`;

export const BackButton = styled(SecondaryButton)`
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
  background: #fff;
  color: rgb(160, 53, 48);
  border: 1px solid rgb(160, 53, 48);
  &:hover {
    border: 1px solid rgb(160, 53, 48);
    cursor: pointer;
  }
`;
export const NextButton = styled(PrimaryButton)`
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
  background: rgb(160, 53, 48);
  color: #fff;
  border: 1px solid rgb(160, 53, 48);
  &:hover {
    background: rgb(160, 53, 48);
    cursor: pointer;
    color: #ffffff;
  }
`;
const Error = styled.div`
  color: #eb5534;
  font-size: 14px;
  font-weight: 400;
`;

export default DeleteLocationModal;
