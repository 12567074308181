export const APPLICATION_HEADERS = {
  mkiiAPIKey: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY.trimEnd(),
  },
  agilePythonAPIKey: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_LOCATIONS_API_KEY.trimEnd(),
  },
  agileBaseAPIKey: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    'x-api-key': process.env.REACT_APP_PROVIDER_API_KEY.trimEnd(),
  },
  agileBaseLocationsAPIKey: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-api-key': process.env.REACT_APP_PROVIDER_API_KEY.trimEnd(),
  },
  clientDeliverablesAPIKey: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_CD_API_KEY.trimEnd(),
  },
  mapBoxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN.trimEnd(),
};
export const API_URLS = {
  agileAnalyticsURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/agileanalytics`,
  providerLocationsURL: `${process.env.REACT_APP_LOCATIONS_URL.trimEnd()}/locations`,
  actualPricingURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/actualpricingdetails`,
  pricingURL: `${process.env.REACT_APP_LOCATIONS_URL.trimEnd()}/PriceModel`,
  getSurveysURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/surveysView`,
  demandAnalyticsURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/demandanalytics`,
  fileUploadURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/providerlocation/bulkupload`,
  searchLocationsCountURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/searchlocationscount`,
  searchProviderLocationsURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/searchproviderlocation`,
  getCountriesURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/getcountries`,
  deleteProviderLocationsURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/providerlocation`,
  getMajorProvidersURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/majorproviders`,
  getFixedamenitiesURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/fixedamenities`,
  getCurrenciesURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/currency`,
  updateDealURL: `${process.env.REACT_APP_CD_URL.trimEnd()}/updatedealinfo`,
  getDealURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/dealdetails`,
  privacyPolicyURL: 'https://www.cbre.com/about/privacy-policy',
  myTransactionAnalytics: `${process.env.REACT_APP_MK2_URL.trimEnd()}/mytransactionanalytics`,
  createAccountTeamsURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/createaccountteams`,
  getClientsURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/clients`,
  requestMarketDataURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/requestmarketdata`,
  requestSurveysURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/requestsurveys`,
  updateAccountTeamURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/updateaccountteam`,
  validateUserURL: `${process.env.REACT_APP_MK2_URL.trimEnd()}/validateuser`,
  mapBoxURL: `${process.env.REACT_APP_MAPBOX_URL.trim()}/mapbox.places/`,
  userGuideURL: 'https://s3.amazonaws.com/dev-agile.cbre.com/Docs/mk2-userguide.docx',
  homeURL: '/MKII/locations/place/-78.549668/39.014/7',
  providerProfileURL: `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL.trimEnd()}/majorproviders`,
};
export const EMAILS = {
  adminEmail: `${process.env.REACT_APP_ADMIN_EMAIL.trim()}`,
};
