import React, { Component } from 'react';

import Modal from 'react-modal';

import MaterialCloseIcon from '../../core/MaterialIcons/MaterialCloseIcon';

import './PopupModal.css';

const customStyles = {
  overlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: '#232323c4',
    zIndex: 100,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '7px',
  },
};

class PopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: props.open,
      contentLabel: 'Example Label',
    };
  }

  show = () => this.setState({ isVisible: true });
  hide = () => this.props.onClose();
  setLabel = (label) => this.setState({ contentLabel: label });

  render() {
    return (
      <div className="Modal" data-testid="modal-react-window">
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.hide}
          style={customStyles}
          contentLabel={this.state.contentLabel}
        >
          <button className="modal-close" onClick={this.hide}>
            <MaterialCloseIcon />
          </button>
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

export default PopupModal;
