import { useState } from 'react';

import useCounter from '../../hooks/Couters';
import AccordionCommon from '../common/Accordion/AccordianHelp';
import PopupModal from '../common/PopupModal/PopupModal';
import MaterialHelpIcon from '../core/MaterialIcons/MaterialHelpIcon';
const NavHelpMenu = () => {
  const [isModalOpen, SetIsModalOpen] = useState(false);
  const { helpViewCounter } = useCounter();
  const showAccordion = (e) => {
    e.preventDefault();
    SetIsModalOpen(!isModalOpen);
    helpViewCounter(e);
  };

  const closeHandler = () => {
    console.log('closed');
  };

  const showVideo = (event) => {
    event.preventDefault();
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  const closeModal = () => {
    SetIsModalOpen(false);
  };

  return (
    <>
      <button
        className="button text navbar-help-link"
        onClick={showAccordion}
        title="How to Request for Survey"
      >
        <MaterialHelpIcon></MaterialHelpIcon>
      </button>
      {isModalOpen && (
        <PopupModal open={isModalOpen} onClose={closeModal}>
          <AccordionCommon headerType="Broker" />
        </PopupModal>
      )}
    </>
  );
};

export default NavHelpMenu;
