import { TextField as MaterialField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextField = styled(MaterialField)({
  '& .MuiOutlinedInput-root.Mui-error': {
    borderColor: '#FF543E !important',
    color: '#FF543E !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(26, 26, 26, 0.6)',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontWeight: '400',
    color: '#1a1a1a',
  },
  '& .Mui-error.MuiFormHelperText-root': {
    color: '#FF543E !important',
  },
});
