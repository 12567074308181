import { useCallback, useContext } from 'react';

import useHttp from './http';

import { APPLICATION_HEADERS, API_URLS } from '../components/common/headers';
import AuthContext from '../store/auth-context';

const useCouter = () => {
  const ctx = useContext(AuthContext);
  const { isLoading, data, error, sendRequest, clear } = useHttp();
  const requestObj = { email: sessionStorage.getItem('ssoEmail')?.toLowerCase() };

  const sendRequestHandler = useCallback(
    (request) => {
      const headers = APPLICATION_HEADERS.mkiiAPIKey;
      const clientURL = `${API_URLS.updateAccountTeamURL}`;

      sendRequest(clientURL, 'PATCH', JSON.stringify(request), headers);
    },
    [sendRequest],
  );

  const uploadViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['uploadToolViewCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('upload view counter success');
      }
    }
  };
  const helpViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['helpViewCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('demand view counter success');
      }
    }
  };
  const demandViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['demandViewCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('demand view counter success');
      }
    }
  };
  const transactionViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['myTransactionViewCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('transaction view counter success');
      }
    }
  };
  const demandHelpViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['providerAnalyticsHelpDemandCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('demand view counter success');
      }
    }
  };
  const uploadFileHelpViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['providerAnalyticsHelpUploadCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('uploadFile view counter success');
      }
    }
  };

  const transactionHelpViewCounter = (e) => {
    if (!e.currentTarget.ariaCurrent) {
      requestObj['providerAnalyticsHelpTransactionsCounter'] = 1;
      sendRequestHandler(requestObj);
      if (data) {
        console.log('transaction view counter success');
      }
    }
  };

  const updateAnalyticsCounters = (counterName) => {
    const counterObj = {
      email: sessionStorage.getItem('ssoEmail')?.toLowerCase(),
    };

    counterObj[counterName] = 1;
    sendRequestHandler(counterObj);
    if (data) {
      console.log('Analytics Counter success');
    }
  };

  const inventoryPortalCounter = (e) => {
    requestObj['inventoryPortalCounter'] = 1;
    sendRequestHandler(requestObj);
    if (data) {
      console.log('Inventory portal view counter success');
    }
  };

  const inventoryRecordupdateCounter = (e) => {
    requestObj['recordUpdateCounter'] = 1;

    sendRequestHandler(requestObj);
    if (data) {
      console.log('Inventory record update counter success');
    }
  };

  const inventoryDataUploadCounter = (e) => {
    requestObj['dataUploadCounter'] = 1;

    sendRequestHandler(requestObj);
    if (data) {
      console.log('Inventory data upload counter success');
    }
  };

  return {
    uploadViewCounter,
    helpViewCounter,
    demandViewCounter,
    transactionViewCounter,
    transactionHelpViewCounter,
    demandHelpViewCounter,
    uploadFileHelpViewCounter,
    updateAnalyticsCounters,
    inventoryPortalCounter,
    inventoryRecordupdateCounter,
    inventoryDataUploadCounter,
  };
};

export default useCouter;
