import imageCompression from 'browser-image-compression';

export const compressingImageOptions = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  fileType: 'image/png',
};

const COMPRESSING_MIN_SIZE_MB = 4000000;

export class CompressingImageService {
  constructor() {
    this.imagesForCompressing = [];
    this.formData = new FormData();
    this.allImages = [];
    this.totalImagesSize = 0;
  }

  addImage(image) {
    // if image size is more than 4MB we compress it to 0.8MB
    // othewise we don't compress it, and just add into formData

    this.totalImagesSize += image.size;
    this.allImages.push(image);
  }

  prepareImagesForCompressing() {
    if (this.totalImagesSize > COMPRESSING_MIN_SIZE_MB) {
      this.allImages.forEach((image) => {
        // if image's size is less than 0.8 MB that we are using for converting bigger images to the smaller, we just skipping compressing
        if (image.size < 500000) {
          this.formData.append('images', image);
        } else {
          this.imagesForCompressing.push(imageCompression(image, compressingImageOptions));
        }
      });
    } else {
      this.allImages.forEach((image) => this.formData.append('images', image));
    }
  }

  async getCompressedImages() {
    this.prepareImagesForCompressing();

    const compressedImages = await Promise.allSettled(this.imagesForCompressing);

    compressedImages.forEach((promise) =>
      this.formData.append('images', new File([promise.value], promise.value.name)),
    );

    return this.formData;
  }
}
