import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';

import axios from 'axios';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { TextField, Autocomplete, IconButton } from '@mui/material';
// import ClearIcon from '@mui/material/Clear';
import Grid from '@mui/material/Grid';

import ProviderLocationsTable from './ProviderLocationsTable';
import RegionSearchBox from './ProviderSearch/ProviderSearch';
import { Skeleton } from './Skeleton';

import noRecordsIcon from '../../assets/images/provider_locations/not_listed_location.svg';
import useCouter from '../../hooks/Couters';
import { ProviderLocationsContext, loadState } from '../../store/ProviderLocationsContext';
import { PrimaryButton } from '../common/Button';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import SnackBar from '../common/Snackbar/ErrorSnackBar';
import Tooltip from '../common/Tooltip/Tooltip';
import Layout from '../Layout/ProviderLayout';
import LoadingSpinner from '../MySurveys/LoadingSpinner';
// import styles from '../ProviderTransactions/ProviderTransactions.module.css';
import { sortAlphabeticalStrings } from '../Utils/Utils';

const checkObjectsEquality = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const ProviderLocations = () => {
  const [responseData, setResponseData] = useState([]);
  const [value, setValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [isEmptyResponse, setIsEmptyResponse] = useState(false);
  const [isEmptySearchValue, setIsEmptySearchValue] = useState(false);
  const res = JSON.parse(sessionStorage.getItem('USER_DATA'));
  const teamType = JSON.parse(sessionStorage.getItem('TEAM_TYPE'));
  const [providerName, setProviderName] = useState('');
  const [majorProvidersList, setMajorProvidersList] = useState([]);
  const [searchDropdownData, setSearchDropdownData] = useState([]);
  const [isListenFromTableEnd, setIsListenFromTableEnd] = useState(false);
  const [searchRequestObject, setSearchRequestObject] = useState({});
  const [paramType, setParamType] = useState('');

  const [totalCount, setTotalCount] = useState(0);
  const [totalDownloadCount, setTotalDownloadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [renderData, setRenderData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const [selectedDataCount, setSelectedDataCount] = useState(0);
  const [searchReqTransactionsObject, setSearchReqTransactionsObject] = useState({});
  const [issearchProviderLocationsClick, setIssearchProviderLocationsClick] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isEditDeleteSuccessCB, setIsEditDeleteSuccessCB] = useState(false);
  const [isDeleteSuccessCB, setIsDeleteSuccessCB] = useState(false);
  const [formattedaddressVal, setFormattedaddressVal] = useState('');
  const [isHoveredFA, setIsHoveredFA] = useState(false);
  const { inventoryPortalCounter } = useCouter();

  const userData = res;

  const { ProviderLocationsState, dispatch } = useContext(ProviderLocationsContext);
  const preSearchRequestObjectRef = useRef({});
  const preSearchRequestTransactionsObjectRef = useRef({});

  const handleMouseEnterFA = useCallback(() => {
    setIsHoveredFA(true);
  }, []);

  const handleMouseLeaveFA = useCallback(() => {
    setIsHoveredFA(false);
  }, []);

  const handleClearFA = useCallback(() => {
    setFormattedaddressVal('');
  }, []);

  useEffect(() => {
    inventoryPortalCounter();

    const storedState = loadState();

    setMajorProvidersList(storedState?.majorProvidersList);
  }, []);

  useEffect(() => {
    preSearchRequestObjectRef.current = searchRequestObject;
  }, [searchRequestObject]);

  useEffect(() => {
    preSearchRequestTransactionsObjectRef.current = searchReqTransactionsObject;
  }, [searchReqTransactionsObject]);

  const uniqueLocationObjects = useCallback((originalArray) => {
    return originalArray.reduce((accumulator, current) => {
      const existingObject = accumulator.find((obj) => obj.provider_id === current.provider_id);

      if (!existingObject) {
        accumulator.push(current);
      }

      return accumulator;
    }, []);
  }, []);

  const fetchTransactions = useCallback(
    async (currentPage, totalCount, reqObject, isEditDeleteSuccessCB) => {
      if (currentPage > Math.ceil(totalCount / 1000)) {
        // All data has been fetched
        return;
      }

      setIsRendering(false);

      const updatedPage = { cPage: page };
      const updatedReqObj = { ...reqObject, callNumber: updatedPage.cPage };

      setSearchReqTransactionsObject(updatedReqObj);

      // If Previous and current objects are same just return
      if (
        checkObjectsEquality(updatedReqObj, preSearchRequestTransactionsObjectRef.current) &&
        !isEditDeleteSuccessCB
      ) {
        return;
      }

      setIsLoading(true);
      setIsListenFromTableEnd(false);
      preSearchRequestTransactionsObjectRef.current = updatedReqObj;

      const URL = `${API_URLS.searchProviderLocationsURL}`;

      try {
        const response = await axios.post(URL, updatedReqObj, {
          headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
        });

        if (response?.status === 200 && response?.data && response?.data?.length > 0) {
          const obj_arr_appended = response.data.map(function (currentValue, Index) {
            let preResponseLength = 0;

            if (!!responseData && responseData?.length > 0) {
              preResponseLength = responseData?.length;
            }

            currentValue.SERIAL_NO = preResponseLength + Index + 1;

            const emailContatcs =
              currentValue && currentValue?.provider_contact_info
                ? currentValue.provider_contact_info.map((s) => s?.email)
                : [];

            currentValue.emailContacts = emailContatcs;

            if (!currentValue.provider_updated_date) {
              currentValue.provider_updated_date = currentValue.provider_created_date;
            }

            return currentValue;
          });

          setResponseData((prevData) => {
            const concatDatLoc = [...prevData, ...obj_arr_appended];

            return uniqueLocationObjects(concatDatLoc);
          });
          setPage((prevPage) => {
            updatedPage.cPage = prevPage + 1;

            return prevPage + 1;
          });
          setIsEmptyResponse(false);

          setIsRendering(true);
        } else {
          console.error('Error: Data is not found in the response');
          setIsEmptyResponse(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
        setIsEditDeleteSuccessCB(false);
      }
    },
    [page, responseData],
  );

  useEffect(() => {
    if (!isLoading && isListenFromTableEnd) {
      setIsEditDeleteSuccessCB(false);
      fetchTransactions(page, totalCount, searchRequestObject, isEditDeleteSuccessCB);
    }
  }, [
    fetchTransactions,
    page,
    totalCount,
    isListenFromTableEnd,
    isLoading,
    searchRequestObject,
    isEditDeleteSuccessCB,
  ]);

  const detectBottomScroll = useCallback(() => {
    !isLoading && setIsListenFromTableEnd(true);
  }, [isLoading]);

  const fetchLocationsCount = useCallback(async (reqObj, isEditDeleteSuccessCB) => {
    if (checkObjectsEquality(reqObj, preSearchRequestObjectRef.current) && !isEditDeleteSuccessCB) {
      return;
    }

    setIsLoading(true);

    setResponseData([]);

    preSearchRequestObjectRef.current = reqObj;

    const URL = `${API_URLS.searchLocationsCountURL}`;

    try {
      const response = await axios.post(URL, reqObj, {
        headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
      });

      if (response?.status === 200 && response?.data && response?.data?.count) {
        const { count } = response.data;

        setTotalCount(count);
        setPage(1);
      } else {
        console.error('Error: Count is not found in the response');
        preSearchRequestTransactionsObjectRef.current = '';
        if (totalCount == 0) {
          setIsEmptyResponse(true);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      // setIsEditSuccessCB(false);
    }
  }, []);

  const refreshLocations = (dataStr) => {
    if (dataStr === 'isEditSuccess' || dataStr === 'isDeleteSuccess') {
      setIsEditDeleteSuccessCB(true);
      preSearchRequestTransactionsObjectRef.current = '';
    }
    if (dataStr === 'isDeleteSuccess') {
      setIsDeleteSuccessCB(true);
    }
  };

  useEffect(() => {
    if (isEditDeleteSuccessCB) {
      fetchLocationsCount(searchRequestObject, isEditDeleteSuccessCB);
    }
  }, [fetchLocationsCount, isEditDeleteSuccessCB, searchRequestObject]);

  useEffect(() => {
    if (totalCount > 0 && page === 1) {
      fetchTransactions(page, totalCount, searchRequestObject, isEditDeleteSuccessCB);
    }
  }, [
    fetchTransactions,
    page,
    providerName,
    totalCount,
    searchRequestObject,
    isEditDeleteSuccessCB,
  ]);

  const onRenderedChildTable = useCallback(() => {
    setIsRendering(false);
  }, []);

  const handleKeyDown = (event) => {
    searchProviders(event.target.value, 'inputval');
  };

  const emptyData = () => {
    setResponseData([]);
    setIsEmptyResponse(false);
  };

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      provider: '',
      emailid: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      lat: '',
      long: '',
      region: '',
      currency: '',
      price: '',
      selectedDate: '',
      amneties: '',
      // images:'',
    },
    mode: 'onChange',
  });

  const searchProviders = (name, type) => {
    if (name && name.length > 1) {
      setProviderName(name);
      dispatch({ type: 'providerName', payload: name });
    } else {
      setProviderName('');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCheckBoxObj = {
    regions: [],
    countries: [],
    states: [],
    cities: [],
  };

  const [finalData, setFinalData] = React.useState([]);

  const handleFormattedAddressChange = (e) => {
    setFormattedaddressVal(e.target.value);
  };

  const handleSelectData = React.useCallback((data) => {
    setFinalData(data);
  }, []);

  const searchProviderLocations = () => {
    if (issearchProviderLocationsClick) {
      return;
    }
    setIssearchProviderLocationsClick(true);
    setIsEmptySearchValue(false);

    if (finalData && finalData.length > 0) {
      const searchData = finalData.map((value) => value.bread);

      setSearchDropdownData(searchData);
      searchData.forEach((searchVal) => {
        const nodeArr = searchVal?.split('>');

        searchCheckBoxObj.regions.indexOf(nodeArr?.[0] !== -1) &&
          searchCheckBoxObj.regions.push(nodeArr?.[0]?.trim());
        nodeArr?.[1] &&
          searchCheckBoxObj.countries.indexOf(nodeArr?.[1] !== -1) &&
          searchCheckBoxObj.countries.push(nodeArr?.[1]?.trim());
        nodeArr?.[2] &&
          searchCheckBoxObj.states.indexOf(nodeArr?.[2] !== -1) &&
          searchCheckBoxObj.states.push(nodeArr?.[2]?.trim());
        nodeArr?.[3] &&
          searchCheckBoxObj.cities.indexOf(nodeArr?.[3] !== -1) &&
          searchCheckBoxObj.cities.push(nodeArr?.[3]?.trim());
      });
    }

    const reqObj = {};

    if (providerName) {
      reqObj.providerName = providerName.trim();
    }
    if (formattedaddressVal) {
      reqObj.providerAddress = formattedaddressVal.trim();
    }

    if (searchCheckBoxObj) {
      if (searchCheckBoxObj?.cities?.length > 0) {
        reqObj.city = [...new Set(searchCheckBoxObj.cities)].join('|').trim();
      }
      if (searchCheckBoxObj?.states?.length > 0) {
        reqObj.state = [...new Set(searchCheckBoxObj.states)].join('|').trim();
      }
      if (searchCheckBoxObj?.countries?.length > 0) {
        reqObj.country = [...new Set(searchCheckBoxObj.countries)].join('|').trim();
      }
      if (searchCheckBoxObj?.regions?.length > 0) {
        reqObj.region = [...new Set(searchCheckBoxObj.regions)].join('|').trim();
      }
    }

    if (Object.keys(reqObj)?.length > 0) {
      setSearchRequestObject(reqObj);
      fetchLocationsCount(reqObj);
    } else {
      setIsEmptySearchValue(true);
      setSearchRequestObject({});
      preSearchRequestTransactionsObjectRef.current = '';
    }

    setTimeout(() => {
      setIssearchProviderLocationsClick(false);
    }, 0);
  };

  useEffect(() => {
    if (userData && userData['Team Name'] && teamType == 'Provider') {
      setProviderName(userData['Team Name']);
      if (providerName && totalCount == 0) {
        searchProviderLocations();
      }
    }
  }, [providerName]);

  let returnArray = [];

  const workbook = new Excel.Workbook();
  const workBookName = 'ProviderLocations';
  const workSheetName = 'Worksheet-1';

  const saveExcel = async (downloadDataArray) => {
    try {
      setDownloadLoading(false);

      const fileName = providerName + '_' + workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      const excelColumns = [
        {
          accessorKey: 'provider_id',
          header: 'Provider ID',
          key: 'provider_id',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'provider_name',
          header: 'Provider Name',
          key: 'provider_name',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'formatted_address',
          header: 'Address',
          key: 'formatted_address',
          columnFilterModeOptions: ['contains'],
        },
        {
          accessorKey: 'city',
          header: 'City',
          key: 'city',
        },
        {
          accessorKey: 'state',
          header: 'State',
          key: 'state',
        },
        {
          accessorKey: 'country',
          header: 'Country',
          key: 'country',
        },
        {
          accessorKey: 'zipPostal',
          header: 'Zipcode',
          key: 'zipPostal',
        },

        {
          accessorKey: 'product_type',
          header: 'Product Type',
          key: 'product_type',
        },

        {
          accessorKey: 'floor_amenities',
          header: 'Amenities',
          key: 'floor_amenities',
        },
        {
          accessorKey: 'provider_contact_email',
          header: 'Provider Contact Email',
          key: 'provider_contact_email',
        },
        {
          accessorKey: 'region',
          header: 'Region',
          key: 'region',
        },
        {
          accessorKey: 'source_system',
          header: 'Source',
          key: 'source_system',
        },
        {
          accessorKey: 'coordinates',
          header: 'Coordinates',
          key: 'coordinates',
        },
        {
          accessorKey: 'createdBy',
          header: 'Created By',
          key: 'createdBy',
        },
        {
          accessorKey: 'provider_created_date',
          header: 'Created Date',
          key: 'provider_created_date',
        },
        {
          accessorKey: 'updatedBy',
          header: 'Updated By',
          key: 'updatedBy',
        },
        {
          accessorKey: 'provider_updated_date',
          header: 'Updated Date',
          key: 'provider_updated_date',
        },
        {
          accessorKey: 'picture_path',
          header: 'Images',
          key: 'picture_path',
        },
      ];

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = excelColumns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header?.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      downloadDataArray.forEach((locData) => {
        const emailContacts =
          locData && locData.provider_contact_info
            ? locData.provider_contact_info.map((s) => s.email)
            : [];

        locData.provider_contact_email = emailContacts?.toString().replace(/^,|,$/g, '');

        if (locData?.['source_system']) {
          locData.source_system = locData?.['source_system'][0];
        }

        if (locData?.['coordinates']) {
          let coordinatesString;

          // Check if coordinates is an object and convert it to string
          if (typeof locData.coordinates === 'object') {
            coordinatesString = JSON.stringify(locData.coordinates);
          } else {
            coordinatesString = locData.coordinates;
          }

          locData.coordinates = coordinatesString
            .replace(/[{}"]/g, '')
            .replace(/:/g, ': ')
            .replace(/,/g, ', ');
        }

        if (locData?.['floor_picture_path']) {
          locData.picture_path = locData?.['floor_picture_path'];
        }
      });

      // loop through data and add each one to worksheet
      downloadDataArray.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  const getDownloadData = async (pageno, totalCount, reqObject) => {
    setDownloadLoading(true);
    if (pageno > Math.ceil(totalCount / 1000)) {
      saveExcel(returnArray);
      returnArray = [];

      // All data has been fetched
      return;
    }

    const URL = `${API_URLS.searchProviderLocationsURL}`;
    const headers = { 'Content-Type': 'application/json' };

    const updatedPage = { cPage: pageno };

    reqObject.callNumber = updatedPage.cPage;

    try {
      await axios.post(URL, reqObject, headers).then(function (response) {
        if (response?.status === 200 && response?.data && response?.data?.length > 0) {
          const obj_arr_appended = response.data.map(function (currentValue, Index) {
            let preResponseLength = 0;

            if (!!responseData && responseData?.length > 0) {
              preResponseLength = responseData?.length;
            }

            currentValue.SERIAL_NO = preResponseLength + Index + 1;

            const emailContatcs =
              currentValue && currentValue?.provider_contact_info
                ? currentValue.provider_contact_info.map((s) => s?.email)
                : [];

            currentValue.emailContacts = emailContatcs;

            if (!currentValue.provider_updated_date) {
              currentValue.provider_updated_date = currentValue.provider_created_date;
            }

            return currentValue;
          });

          returnArray = returnArray.concat(obj_arr_appended);

          getDownloadData(pageno + 1, totalCount, searchRequestObject);
        } else {
          console.error('Error: Data is not found in the response');
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // finally {
    //   // return downloadedData;
    // }
  };

  const downloadLocationsData = () => {
    getDownloadData(1, totalCount, searchRequestObject);
  };

  return (
    <Layout>
      <Container>
        <Grid>
          {teamType === 'Inventory Analyst' && (
            <div
              style={{
                display: 'grid',
                gap: '20px',
                gridTemplateColumns: '0.8fr 0.8fr 1.4fr 90px',
              }}
            >
              <Controller
                style={{ marginBottom: '20px' }}
                name="provider"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    freeSolo
                    options={
                      majorProvidersList?.length > 0
                        ? sortAlphabeticalStrings('providerName', majorProvidersList)
                        : []
                    }
                    getOptionLabel={(option) =>
                      typeof option === 'object' ? option.providerName : option
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        style={{ fontSize: '14px', color: '#1a1a1a' }}
                        label=""
                        placeholder="Search by Provider Name"
                        onBlur={handleKeyDown}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {' '}
                              <SearchIcon style={{ color: '#1a1a1a99' }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: '450px',
                      },
                    }}
                    onChange={(_, data) => {
                      if (data) {
                        searchProviders(data.providerName, 'dropdown');
                      }
                    }}
                  />
                )}
              />
              <div>
                <Controller
                  name="formattedaddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      value={formattedaddressVal}
                      placeholder="Formatted Address"
                      onMouseEnter={handleMouseEnterFA}
                      onMouseLeave={handleMouseLeaveFA}
                      onChange={handleFormattedAddressChange}
                      className="formatted_address_wrapper"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: '#1a1a1a99' }} />
                          </InputAdornment>
                        ),
                        endAdornment: formattedaddressVal && isHoveredFA && (
                          <InputAdornment position="end">
                            <IconButton title="Clear" onClick={handleClearFA}>
                              <ClearRoundedIcon style={{ color: '#1a1a1a' }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                      }}
                    />
                  )}
                ></Controller>
              </div>
              <div>
                <RegionSearchBox selectedData={finalData} onSelectData={handleSelectData} />
              </div>
              <div>
                <PrimaryButton style={{ padding: '16px' }} onClick={searchProviderLocations}>
                  Search
                </PrimaryButton>
              </div>
            </div>
          )}
          <div style={{ marginTop: '5px' }}>
            {isLoading &&
              Array(20)
                .fill(0, 0)
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    height={60}
                    sx={{ borderBottom: '1px solid #ccc' }}
                    animation="wave"
                  />
                ))}
          </div>
          {responseData?.length > 0 && Object.keys(searchRequestObject)?.length > 0 && (
            <>
              <ProviderLocationsTable
                providerData={responseData}
                providerName={providerName}
                teamType={teamType}
                majorProvidersList={majorProvidersList}
                refreshLocations={refreshLocations}
                totalCount={totalCount}
                detectBottomScroll={detectBottomScroll}
                isLoading={isLoading}
                downloadLoading={downloadLoading}
                page={page}
                onRenderedChildTable={onRenderedChildTable}
                isRendering={isRendering}
                downloadLocationsData={downloadLocationsData}
              />
            </>
          )}
          {isEmptyResponse && (
            <div className="noRow">
              <img src={noRecordsIcon} />
              <span style={{ fontWeight: '500' }}>No locations found</span>
            </div>
          )}
          {isEmptySearchValue && (
            <div className="noRow">
              <span style={{ fontWeight: '500', paddingLeft: '5px' }}>
                Please select Provider name or formatted address or Region, Country, State, City to
                proceed further
              </span>
            </div>
          )}
        </Grid>
      </Container>

      <SnackBarContainer>
        {isDeleteSuccessCB && (
          <SnackBar
            title="Location successfully deleted"
            onClose={() => setIsDeleteSuccessCB(false)}
            delay={4000}
            customClass="snackBarBlock"
          />
        )}
      </SnackBarContainer>
    </Layout>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 10px 48px 68px 0px;

  .MuiAutocomplete-input {
    font-size: 14px;
    color: #1a1a1a;
  }
  .MuiOutlinedInput-root {
    height: 100%;
  }
  .clear-text-formatted-address {
    background-color: #aaa;
    height: 32px;
    text-align: center;
    width: 40px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  & .formatted_address_wrapper {
    height: 53px;
    & .MuiOutlinedInput-input {
      font-size: 14px;
    }
    & .MuiSvgIcon-root {
      font-size: 1.25rem !important;
    }
  }
`;

const SnackBarContainer = styled.div`
  position: relative;
  & .snackBarBlock {
    position: fixed;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%);
  }
`;

export default ProviderLocations;
