import React, { useEffect, createContext, useReducer, useState } from 'react';

import axios from 'axios';

import { APPLICATION_HEADERS, API_URLS } from '../components/common/headers';

export const ProviderLocationsContext = createContext();

const defaultState = {
  majorProvidersList: null,
  providerName: null,
  selectedOptionsCount: 0,
  fixedAmenitiesList: null,
  currenciesList: null,
};

const ACTION_TYPES = {
  MAJOR_PROVIDERS_LIST: 'majorProvidersList',
  FIXED_AMENITIES_LIST: 'fixedAmenitiesList',
  CURRENCIES_LIST: 'currenciesList',
};

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('ProviderLocationsState');

    return serializedState ? JSON.parse(serializedState) : defaultState;
  } catch (err) {
    console.error('Error loading data from session storage:', err);

    return defaultState;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem('ProviderLocationsState', serializedState);
  } catch (err) {
    console.error('Error saving data to session storage:', err);
  }
};

const reducer = (state, action) => {
  if (action?.payload) {
    return { ...state, [action.type]: action.payload };
  }

  return state;
};

export const ProviderLocationsContextProvider = ({ children }) => {
  const initialState = loadState();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [providersLoaded, setProvidersLoaded] = useState(false);
  const [amenitiesLoaded, setAmenitiesLoaded] = useState(false);
  const [currenciesLoaded, setcurrenciesLoaded] = useState(false);

  const fetchMajorProviders = async () => {
    const url = `${API_URLS.getMajorProvidersURL}`;

    try {
      const response = await axios.get(url, { headers: APPLICATION_HEADERS.agileBaseAPIKey });

      if (response.status === 200 && response?.data?.list?.length > 0) {
        dispatch({ type: ACTION_TYPES.MAJOR_PROVIDERS_LIST, payload: response?.data?.list });
      } else {
        console.log('Major Providers data not coming');
      }
    } catch (e) {
      console.error('Error fetching Major Providers', e);
    }
  };

  const fetchFixedAmenities = async () => {
    const url = `${API_URLS.getFixedamenitiesURL}`;

    try {
      const response = await axios.get(url, { headers: APPLICATION_HEADERS.mkiiAPIKey });

      if (response.status === 200 && response?.data?.list?.length > 0) {
        dispatch({ type: ACTION_TYPES.FIXED_AMENITIES_LIST, payload: response?.data?.list });
      } else {
        console.log('Amenities data not coming');
      }
    } catch (e) {
      console.error('Error fetching Fixed Amenities', e);
    }
  };

  const fetchCurrencies = async () => {
    const url = `${API_URLS.getCurrenciesURL}`;

    try {
      const response = await axios.get(url, { headers: APPLICATION_HEADERS.mkiiAPIKey });

      if (
        response.status === 200 &&
        response?.data?.currencyMap &&
        Object.keys(response?.data?.currencyMap)?.length > 0
      ) {
        dispatch({ type: ACTION_TYPES.CURRENCIES_LIST, payload: response?.data?.currencyMap });
      } else {
        console.log('Currencies data not coming');
      }
    } catch (e) {
      console.error('Error fetching Currencies', e);
    }
  };

  useEffect(() => {
    if (!state.majorProvidersList && !providersLoaded) {
      fetchMajorProviders();
      setProvidersLoaded(true);
    }
  }, [providersLoaded, state.majorProvidersList]);
  useEffect(() => {
    if (!state.fixedAmenitiesList && !amenitiesLoaded) {
      fetchFixedAmenities();
      setAmenitiesLoaded(true);
    }
  }, [amenitiesLoaded, state.fixedAmenitiesList]);
  useEffect(() => {
    if (!state.currenciesList && !currenciesLoaded) {
      fetchCurrencies();
      setcurrenciesLoaded(true);
    }
  }, [currenciesLoaded, state.currenciesList]);

  useEffect(() => {
    saveState(state);
  }, [state]);

  return (
    <ProviderLocationsContext.Provider value={{ state, dispatch }}>
      {children}
    </ProviderLocationsContext.Provider>
  );
};
