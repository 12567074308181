const constants = {
  pricePerSeatTooltip:
    'Estimate based on 6 month term in a 30-seat private suite.\n Includes amenities and light meeting room usage.\n Excludes custom IT, design modifications and parking',
  providersTooltip:
    'Known providers operating in this area. Does not indicate current availability at a location.',
  coworkingTooltip: '- Shared workspace\n- Shared amenities\n- Shared meeting rooms',
  suitesTooltip: '- Dedicated workspace\n- Shared amenities\n- Shared or private meeting rooms',
  enterpriseTooltip:
    '- Dedicated amenities\n- Dedicated meeting rooms\n- Private entrance\n- Customizable IT',
  avail: 'Available in this area',
  notAvail: 'Not available in this area',
  addClientBtnTooltip: 'Add a client location pin to map',
  notEnoughData: 'We dont have enough data to display provider information in this area',
  surveyCompltionTime: 'Avg. Survey Completion Time',
  noOfSurveys: 'No. of Surveys',
  closedDeals: 'Closed Deals',
  providerResponseRate: 'Provider Response Rate',
  surveyCompltionTimeTooltip:
    'Average no. of business days from the time a deal is created to the time the client deliverable is produced',
  noOfSurveysTooltip: 'Total no. of surveys requested globally',
  closedDealsTooltip: 'Percentage of deals that were closed globally',
  providerResponseRateTooltip:
    'Providers who have responded to Agile surveys (Locations available or Not available)',
  apacRegion: 'APAC',
};

export const KEYS_TO_ANALYTICS_DATA_BY_TITLE = {
  [constants.surveyCompltionTime]: 'surveyAroundData',
  [constants.noOfSurveys]: 'noOfSurveysData',
  [constants.closedDeals]: 'closedDealsData',
  [constants.providerResponseRate]: 'providerResponsData',
};

export default constants;
