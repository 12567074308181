import React, { useEffect, useState, useCallback } from 'react';

import axios from 'axios';

import Grid from '@mui/material/Grid';

import LoadingSpinner from './../MySurveys/LoadingSpinner';
import styles from './ProviderTransactions.module.css';
import TransactionsTabs from './TransactionsTabs';

import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import Tooltip from '../common/Tooltip/Tooltip';
import Layout from '../Layout/ProviderLayout';

const ProviderTransactions = () => {
  const [response, setResponse] = useState(null);
  const [value, setValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const res = JSON.parse(sessionStorage.getItem('USER_DATA'));
  const userData = res;
  let teamName = '';

  if (userData && userData['Team Name']) {
    teamName = userData['Team Name'];
  }

  const fetchTransactions = useCallback(async (provider) => {
    setIsLoading(true);
    const URL = `${API_URLS.myTransactionAnalytics}?providername=` + provider;

    const headers = APPLICATION_HEADERS.mkiiAPIKey;
    const res = await axios.get(URL, { headers });

    if (res?.status === 200 && res?.data) {
      setIsLoading(false);
      setResponse(res.data.list);
    }
  }, []);

  useEffect(() => {
    if (teamName) fetchTransactions(teamName);
  }, [fetchTransactions, teamName]);

  return (
    <Layout>
      <div className="pageContainer1">
        <div className="dashboardDiv">
          <div className="dashboard-heading">
            <h3>
              My Transactions
              <Tooltip
                val="providerTooltip"
                text="View your performance of transactions from CBRE"
              />
            </h3>
          </div>

          <div className="dashboard-content">
            {isLoading && (
              <div className={styles.spinner__container}>
                <LoadingSpinner />
              </div>
            )}
            {response && response.length > 0 && (
              <Grid classes={{ root: styles.dashboard__grid }}>
                <TransactionsTabs providerData={response} providerName={teamName} />
              </Grid>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProviderTransactions;
