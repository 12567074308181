import React from 'react';

import styles from './ProvidersTable.module.css';

import otherIcon from '../../assets/images/pin_other providers.svg';
import { providericons } from '../common/providerIconList';

class ProvidersTable extends React.Component {
  state = {
    providerName: '',
  };

  render() {
    const { Other, total, data, setHoveredProvider } = this.props;

    const res1 = JSON.parse(sessionStorage.getItem('result'));
    const currentUser = res1.data.records[0].fields;
    let isEdgeUser = false;

    if (currentUser['MKII Version'] === 'Edge') {
      isEdgeUser = true;
    }
    if (isEdgeUser) {
      return (
        <div className="tableView__container">
          <div className="table__container">
            <table>
              <thead>
                <tr>
                  <th className="providersTitle__name">Name</th>
                  <th className="providerTitle__locations">Locations</th>
                </tr>
              </thead>
              <tbody onMouseLeave={() => setHoveredProvider(null)}>
                {data.map((v) => {
                  let iconImg = '';

                  if (v) {
                    providericons.map((n) => {
                      if (
                        n.name.toLowerCase().replace(/\s/g, '') ===
                        v[0].toLowerCase().replace(/\s/g, '')
                      ) {
                        iconImg = n.img;
                      }
                    });

                    return (
                      <tr
                        onMouseEnter={() => setHoveredProvider(v[0])}
                        onMouseLeave={() => setHoveredProvider(null)}
                        key={v[0]}
                        title={v[0]}
                      >
                        <td className="provider__details">
                          <div className={styles.provider__block_icon}>
                            {iconImg ? (
                              <img className={styles.provider__icon} src={iconImg} alt={v[0]} />
                            ) : (
                              <div>
                                {' '}
                                <img className={styles.provider__icon} src={otherIcon} />
                              </div>
                            )}
                          </div>

                          <span className="providerDetails__text">
                            {' '}
                            {v[0].length > 30 ? v[0].substring(0, 30) + '...' : v[0]}
                          </span>
                        </td>
                        <td align="right" className="text-right">
                          {v[1]}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <table className="providerTotal__table">
              <thead>
                <tr>
                  <th className={`${styles.providerTotal__title} text-left`}>Total</th>
                  <th className={`${styles.providerTotal__text} text-right`}>{total}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      );
    }

    return (
      <table className="providerTable">
        <thead>
          <tr>
            <th className="providerTableHead__subtitle">Name</th>
            <th className="providerTableHead__subtitle text-right">Locations</th>
          </tr>
        </thead>
        <tbody onMouseLeave={() => setHoveredProvider(null)}>
          {data.map((v) => {
            let iconImg = '';

            if (v) {
              providericons.map((n) => {
                if (
                  n.name.toLowerCase().replace(/\s/g, '') === v[0].toLowerCase().replace(/\s/g, '')
                ) {
                  iconImg = n.img;
                }
              });

              return (
                <tr
                  onMouseEnter={() => setHoveredProvider(v[0])}
                  onMouseLeave={() => setHoveredProvider(null)}
                  key={v[0]}
                >
                  <td>
                    <div className={styles.providerBlock_icon}>
                      {iconImg ? (
                        <img className={styles.provider__icon} src={iconImg} alt={v[0]} />
                      ) : (
                        <div>
                          {' '}
                          <img className={styles.provider__icon} src={otherIcon} />
                        </div>
                      )}
                    </div>

                    {v[0].length > 15 ? v[0].substring(0, 15) + '...' : v[0]}
                  </td>
                  <td className="text-right">{v[1]}</td>
                </tr>
              );
            }
          })}

          <tr>
            <td>
              <div className={styles.providerBlock_icon}>
                <img className={styles.provider__icon} src={otherIcon} />
              </div>
              Other
            </td>
            <td className="text-right">{Other}</td>
          </tr>
          <tr>
            <th className={styles.total__title}>
              <div className={styles.informationBlock__title}>Total</div>
            </th>
            <th className={styles.total__text}>
              <div className={`${styles.informationBlock__title} text-right`}>{total}</div>
            </th>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default ProvidersTable;
