import { useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { styled as materialStyled } from '@mui/material/styles';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './DatePicker.css';

const DateTextField = materialStyled(TextField)({
  '& .MuiInputLabel-root': {
    color: '#1a1a1a !important',
    backgroundColor: '#fff',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    display: 'flex !important',
    alignItems: 'center !important',
    left: '7px !important',
  },
  '& .MuiOutlinedInput-root': {
    color: '#1a1a1a !important',
    paddingRight: '0 !important',
  },
  '& .MuiOutlinedInput-input': {
    border: 'none !important',
    height: '30px !important',
    fontFamily: 'Calibre-R !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    color: '#1a1a1a !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
    zIndex: '1000',
  },
  '& .MuiTextField-root': {
    maxHeight: '48px',
    paddingTop: '2px !important',
  },
});

const convertDate = (date, dateFormat) => {
  if (!dateFormat) {
    return date;
  }

  return date.format(dateFormat);
};

const DatePicker = ({
  handleChange,
  disablePast,
  inputFormat,
  label,
  dateFormat,
  selectedDate,
  fullWidth,
}) => {
  const [value, setValue] = useState(() => {
    if (selectedDate) {
      const day = convertDate(dayjs(selectedDate), dateFormat);

      handleChange(day);

      return day;
    }

    const nextDay = dayjs(new Date().setDate(new Date().getDate()));

    handleChange(convertDate(nextDay, dateFormat));

    return nextDay;
  });

  const onDateChange = (newDate) => {
    const date = convertDate(newDate, dateFormat);

    setValue(newDate);
    handleChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        inputFormat={inputFormat}
        value={value}
        onChange={onDateChange}
        views={['year', 'month', 'day']}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <DatePickerContainer fullWidth={fullWidth}>
            <InputContainer>
              <DateTextField
                label={label}
                ref={inputRef}
                {...inputProps}
                InputProps={{ readOnly: true }}
              />
            </InputContainer>
            <InputContainer>{InputProps?.endAdornment}</InputContainer>
          </DatePickerContainer>
        )}
        disablePast={disablePast}
        disableMaskedInput
      />
    </LocalizationProvider>
  );
};

const DatePickerContainer = styled.div`
  height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
  border-radius: 8px;
  flex: ${({ fullWidth }) => fullWidth && '1 1 100%'};
`;

const InputContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;

export default DatePicker;
