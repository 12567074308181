import React from 'react';

const CurrentLocationIcon = ({ color, width, height }) => {
  const currentWidth = width || '39';
  const currentHeight = height || '43';
  const currentBackgroundFill = color || '#003F2D';
  const viewBox = `0 0 ${currentWidth} ${currentHeight}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={currentWidth}
      height={currentHeight}
      fill="none"
      viewBox={viewBox}
    >
      <g filter="url(#filter0_d)">
        <path
          fill="#fff"
          d="M19.534 4.105C13.937 4.105 9.4 8.89 9.4 14.79c0 5.9 10.134 19.716 10.134 19.716S29.667 20.69 29.667 14.79c0-5.9-4.536-10.684-10.133-10.684z"
        ></path>
        <path
          fill={currentBackgroundFill}
          fillRule="evenodd"
          d="M29.667 14.79c0-5.901-4.536-10.685-10.133-10.685C13.937 4.105 9.4 8.89 9.4 14.79c0 5.9 10.134 19.716 10.134 19.716S29.667 20.69 29.667 14.79zm-5.143-.538a5.054 5.054 0 11-10.108 0 5.054 5.054 0 0110.108 0z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="46"
          height="46"
          x="-3.491"
          y="0.305"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default CurrentLocationIcon;
