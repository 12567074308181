import React, { useRef, useState } from 'react';

import axios from 'axios';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import './FileUpload.css';
import { PromptState } from 'msal/lib-commonjs/utils/Constants';

import LoadingSpinner from './../MySurveys/LoadingSpinner';
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  InputLabel,
  MoreInformation,
  ErrorsContainer,
  SpinnerContainer,
  UploadTextBlock,
} from './fil-upload.styles';

import successIcon from '../../assets/images/successIcon.svg';
import uploadIcon from '../../assets/images/uploadIcon.svg';
import { API_URLS, APPLICATION_HEADERS } from '../common/headers';
import { Block } from '../common/StyledComponents/StyledBlock';
import MaterialErrorIcon from '../core/MaterialIcons/MaterialErrorIcon';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: '#3B8159',
  },
}))(LinearProgress);

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const { providerEmail, dealID } = otherProps;
  const [showProgress, setShowProgress] = React.useState(false);
  const [errors, setErrors] = React.useState();
  const [errorCount, setErrorCount] = React.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = React.useState();

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;

    if (newFiles.length) {
      handleFileUpload(e);
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const currentFile = event.target.files[0];

    const filename = currentFile.name;

    setUploadedFileName(filename);
    const extension = filename.split('.').pop();

    if (!providerEmail || providerEmail === undefined) {
      setErrors('Please enter your email before uploading the file');
      setShowProgress(true);
      setErrorCount(1);

      return;
    }

    if (extension !== 'xlsx') {
      setErrors('Please upload .xlsx file only');
      setShowProgress(true);
      setErrorCount(1);

      return;
    }

    const headers = APPLICATION_HEADERS.agileBaseAPIKey;

    setIsLoading(true);

    const URL = API_URLS.fileUploadURL;
    const formData = new FormData();

    formData.append('file', currentFile);
    formData.append('email', providerEmail);

    axios
      .post(URL, formData, { headers })
      .then((res) => {
        setIsLoading(false);

        if (res && res.status === 202) {
          setErrors('');
          setShowProgress(true);
          if (dealID) {
            saveToAirtable(dealID);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          const response = error.response.data;

          if (typeof response === 'string') {
            const errorsArray = response.split(',');

            errorsArray.length > 1
              ? setErrorCount(errorsArray.length - 1)
              : setErrorCount(errorsArray.length);
            setErrors(response);
            setShowProgress(true);
          }

          if (typeof response === 'object') {
            setErrorCount(1);
            setErrors(response.message);
            setShowProgress(true);
          }
        }
      });
  };
  const saveToAirtable = (id) => {
    const headers = APPLICATION_HEADERS.clientDeliverablesAPIKey;
    const URL = API_URLS.updateDealURL;

    const updateObj = {
      dealId: id,
      locationUploadStatus: 'Yes',
    };

    axios
      .put(URL, updateObj, { headers })
      .then((res) => {
        if (res && res.status === 200) {
          console.log('Deal update success');
        }
      })
      .catch((error) => {
        console.error('Error during update deal information', error);
      });
  };

  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>

        <button
          className="button primary fileupload-btn"
          type="button"
          onClick={handleUploadBtnClick}
        >
          <img src={uploadIcon} />
          <UploadTextBlock> Upload {otherProps.multiple ? 'files' : 'file'}</UploadTextBlock>
        </button>
        <DragDropText>You can drag & drop your file here</DragDropText>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <MoreInformation>
          For more information about how we use the data you provide please refer to our
          <a href={API_URLS.privacyPolicyURL} target="_blank" rel="noreferrer">
            {' '}
            Global Web Privacy and Cookie Policy
          </a>
        </MoreInformation>
        {showProgress && (
          <Block>
            <Block display="flex">
              {!errors && (
                <Block display="flex" gap="8">
                  <Block className="magenta">
                    <img src={successIcon} width="24"></img>
                  </Block>
                  <Block className="green">
                    <Block className="successMsg">
                      "{uploadedFileName}" upload is in progress. You will receive an email once the
                      process is completed. You can upload another file by clicking on Upload File
                      button
                    </Block>
                  </Block>
                </Block>
              )}
            </Block>
            {errors && (
              <Block display="flex" gap="8">
                <Block>
                  <MaterialErrorIcon sx={{ fontSize: '24px' }}></MaterialErrorIcon>
                </Block>
                <Block className="green">
                  <Block className="errMsg">Upload failed : {errorCount} Error(s) found</Block>
                  <ErrorsContainer>
                    {errors &&
                      errors.split(',').map((text) => {
                        if (text) {
                          return (
                            <span key={text}>
                              {text}
                              <br />
                            </span>
                          );
                        }
                      })}
                  </ErrorsContainer>
                </Block>
              </Block>
            )}
          </Block>
        )}

        {isLoading && (
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        )}
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;
