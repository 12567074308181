import React, { useEffect, useState } from 'react';

import axios from 'axios';
import SearchBar from 'material-ui-search-bar';

import Card from './Card';
import LoadingSpinner from './LoadingSpinner';
import classes from './MySurveysPage.module.css';
import TableSurveys from './TableSurveys';

import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import Tooltip from '../common/Tooltip/Tooltip';
import Layout from '../Layout/Layout';
import { formatDate } from '../Utils/Utils';

const cardList = [
  {
    id: 1,
    title: 'All',
    label: 'ALL',
    quantity: null,
  },
  {
    id: 2,
    title: 'Survey',
    label: 'SURVEY',
    quantity: null,
  },
  {
    id: 3,
    title: 'Tour',
    label: 'TOUR',
    quantity: null,
  },
  {
    id: 4,
    title: 'Negotiation',
    label: 'NEGOTIATION',
    quantity: null,
  },
  {
    id: 5,
    title: 'Closed',
    label: 'CLOSED',
    quantity: null,
  },
  {
    id: 6,
    title: 'On Hold',
    label: 'ON HOLD',
    quantity: null,
  },
  {
    id: 7,
    title: 'Inactive',
    label: 'INACTIVE',
    quantity: null,
  },
];

function MySurveysPage() {
  window.history.pushState('page2', 'Title', '/MKII/myTransactions');
  const [filteredVal, setFilteredVal] = useState('All');
  const [cardCollection, setCardCollection] = useState({
    activeObject: cardList[0],
    list: cardList,
  });

  const [initialSurveys, setInitialSurveys] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [surveysPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [searched, setSearched] = useState('');
  const [filterData, setFilteredData] = useState([]);

  useEffect(() => {
    // const url = `${process.env.REACT_APP_MK2_URL}/surveysView`;
    const url = API_URLS.getSurveysURL;

    const res = JSON.parse(sessionStorage.getItem('USER_DATA'));
    const userData = res;
    let inputData = {};

    if (userData && userData.ClientNames) {
      const clientNames = userData.ClientNames.toString();

      inputData = {
        clients: clientNames,
      };
    }

    const getStatus = (row) => {
      let status = row.fields.Stage && row.fields.Stage.trim();

      if (status === 'Lost') {
        status = 'Inactive';
      }
      if (row.fields['Vital Sign'] && status) {
        const vitalSign = row.fields['Vital Sign'].trim();

        if (
          vitalSign === 'On Hold' &&
          (status === 'Survey' || status === 'Negotiation' || status === 'Tour')
        )
          status = 'On Hold';
        if (
          (status === 'Closed' && vitalSign === 'On Hold') ||
          (vitalSign === 'Closed' && status !== 'Inactive')
        )
          status = 'Closed';
        if (status === 'Lost' || vitalSign === 'Dead') status = 'Inactive';
      }

      return status;
    };

    const fetchData = async () => {
      const headers = APPLICATION_HEADERS.mkiiAPIKey;

      setIsLoading(true);
      const res1 = JSON.parse(sessionStorage.getItem('result'));
      const isEdgeUser = true;

      try {
        if (inputData) {
          const response = await axios.post(url, inputData, { headers });

          if (response && response.data && response.data.records) {
            const { records } = response.data;
            const filteredRecords = records.filter(function (value, index) {
              value.fields['Target Market'] =
                value.fields['Target Market'] && value.fields['Target Market'].charAt(0) === ','
                  ? value.fields['Target Market'].substring(1)
                  : value.fields['Target Market'];

              if (value.fields.Stage !== 'Discovery' && value.fields.Stage !== 'Research') {
                value.fields['Stage'] = getStatus(value);
              }

              return value.fields.Stage !== 'Discovery' && value.fields.Stage !== 'Research';
            });

            cardList.map((card) => {
              const title = card && card.title && card.title.toLowerCase();
              const matchingFields = filteredRecords.filter(
                (record) =>
                  record &&
                  record.fields &&
                  record.fields.Stage &&
                  record.fields.Stage.toLowerCase() === title,
              );

              card.quantity = matchingFields.length;
            });

            let tableArray = [];

            filteredRecords.map((row) => {
              const obj = {
                DealID: row.fields.DealID,
                'Date Requested': formatDate(row.fields['Date Requested']),
                'Client Name': row.fields['Client Name'],
                'Projected Size': row.fields['Projected Size'],
                'Target Market': row.fields['Target Market'],
                Stage: row.fields.Stage,
                'Survey Status': row.fields['Survey Status'],
                SurveyLink: row.fields.SurveyLink,
                MoveInDate: formatDate(row.fields['Projected Move In Date']),
                RequesterEmail: row.fields['Requester Email'],
                ClientCurrentLocation: row.fields['Client Current Location'],
                SpecialRequirements: row.fields['Location / Special Requirements'],
                'Max Projected Size': row.fields['Max Projected Size'],
              };

              if (
                isEdgeUser &&
                obj['Max Projected Size'] &&
                obj['Projected Size'] !== obj['Max Projected Size']
              ) {
                obj['Projected Size'] = obj['Projected Size'] + ' - ' + obj['Max Projected Size'];
              }

              tableArray.push(obj);
            });

            tableArray = tableArray.sort(function (a, b) {
              return b.DealID - a.DealID;
            });

            cardList[0].quantity = tableArray.length;
            setSurveys(tableArray);
            setInitialSurveys(tableArray);
            setFilteredData(tableArray);
          }

          setIsLoading(false);
        } else {
          setErrorMsg('No data to display');
        }
      } catch (error) {
        setErrorMsg('Could not fetch data...');
      }
    };

    fetchData();
  }, []);

  const toggleActiveCard = (id) => {
    setCardCollection({
      ...cardCollection,
      activeObject: cardCollection.list[id],
    });
  };

  const toggleActiveStyle = (index) => {
    let _val = null;

    if (cardCollection.list[index] === cardCollection.activeObject) {
      _val = classes.activeCard;
    }

    return _val;
  };

  const clickHandler = (val) => {
    setCurrentPage(0);
    setFilteredVal(val);
    searched && setSearched('');

    if (!val) return;
    if (val === 'All') {
      setSurveys(initialSurveys);
      setFilteredData(initialSurveys);

      return;
    }

    const filteredVal = val && val.toLowerCase();
    const tData = initialSurveys.filter(
      (data) => data && data.Stage && data.Stage.toLowerCase() === filteredVal,
    );

    setFilteredData(tData);
    setSurveys(tData);
  };

  const indexOfLastSurvey = currentPage * surveysPerPage;
  const indexOfFirstSurvey = indexOfLastSurvey - surveysPerPage;

  const cancelSearch = () => {
    setSearched('');
    clickHandler(filteredVal);
  };

  const handleSearch = (searchedVal) => {
    if (searchedVal) {
      setSearched(searchedVal);
      setCurrentPage(0);

      const value = searchedVal.toLowerCase().trim();
      let result = [];

      result = filterData.filter((data) => {
        return (
          (data['Target Market'] && data['Target Market'].toLowerCase().includes(value)) ||
          (data['Client Name'] &&
            data['Client Name'].toString().toLowerCase().indexOf(value) != -1) ||
          (data['Stage'] && data['Stage'].toLowerCase().includes(value)) ||
          (data['DealID'] && data['DealID'].toString().indexOf(value) != -1) ||
          (data['Projected Size'] && data['Projected Size'].toString().indexOf(value) != -1) ||
          (data['Date Requested'] && data['Date Requested'].toLowerCase().indexOf(value) != -1)
        );
      });

      setSurveys(result);
    } else {
      cancelSearch();
    }
  };

  return (
    <Layout>
      <div className={classes.surveys}>
        <div className={classes.heading}>
          My Transactions
          <Tooltip val="myTransaction">
            <span>
              You can see the status of all the surveys
              <br />
              Survey:
              <span className={classes.subText}>
                {' '}
                Client has requested and is either waiting for or currently reviewing{' '}
              </span>
              <br />
              Tour:{' '}
              <span className={classes.subText}>
                {' '}
                Client has requested to visit or has toured options
              </span>
              <br />
              Negotiation:{' '}
              <span className={classes.subText}>
                {' '}
                Currently negotiating with one or more providers
              </span>
              <br />
              Closed: <span className={classes.subText}>Deal has been fully executed </span>
              <br />
              On Hold: <span className={classes.subText}> Requirement currently on hold </span>
              <br />
              Inactive:
              <span className={classes.subText}>
                {' '}
                Client has decided to not continue with an Agile solution
              </span>
            </span>
          </Tooltip>
        </div>
        <div className={classes.lineBreak}></div>

        {isLoading && !errorMsg && (
          <div className={classes.spinner__countainer}>
            <LoadingSpinner />
          </div>
        )}
        {errorMsg && <p>{errorMsg}</p>}
        {!isLoading && !errorMsg && (
          <div>
            <div className={classes.cards}>
              {cardCollection.list.map((eachCard, index) => (
                <div
                  key={index}
                  className={toggleActiveStyle(index)}
                  onClick={() => toggleActiveCard(index)}
                >
                  <Card
                    quantity={eachCard.quantity}
                    title={eachCard.label}
                    clickHandle={() => clickHandler(eachCard.title)}
                  />
                </div>
              ))}
            </div>

            <div className={classes.lineBreak}></div>
            <div className={classes.surveyHead}>
              {' '}
              {filteredVal} Results ({surveys.length})
            </div>
            <div className={classes.searchBox}>
              <SearchBar
                className={classes.surveys__searchBar}
                value={searched}
                onChange={(searchVal) => handleSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </div>
            <div className={classes.surveyTable}>
              <TableSurveys
                filteredVal={filteredVal}
                tableData={surveys}
                currentPage={currentPage}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default MySurveysPage;
