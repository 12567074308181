import React from 'react';

import axios from 'axios';
import { useHistory, Redirect } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';

import PopupModal from '../common/PopupModal/PopupModal';

import './oktaLogin.css';

const updateUserCounter = async (email, loginCounter) => {
  try {
    const requestObj = {
      usageCounter: loginCounter,
      email: email?.toLowerCase(),
    };
    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': process.env.REACT_APP_API_KEY,
    };

    const clientURL = `${process.env.REACT_APP_MK2_URL}/updateaccountteam`;
    const res = await axios.patch(clientURL, requestObj, { headers });

    if (res?.data?.records) {
      console.log('counter update success');
    }
  } catch (e) {
    console.error('error in update teams:', e);
  }
};

async function callValidateUserAPI(
  url,
  headers,
  ssoUserMail,
  { setIsLoading, setIsModalOpen, history },
) {
  let loginCounter = 0;

  setIsLoading(true);

  try {
    const response = await axios.get(url, { headers });

    if (response.data.records.length === 0) {
      setIsLoading(false);
      setIsModalOpen(true);

      return;
    }

    if (!response.data.records[0].fields['MKII Usage Counter']) {
      response.data.records[0].fields['MKII Usage Counter'] = 0;
    }

    loginCounter = response.data.records[0].fields['MKII Usage Counter'] + 1;
    const teamType = response.data.records[0].fields['Team Type'];
    const redirectPath = localStorage.getItem('prevPath');

    sessionStorage.setItem('result', JSON.stringify(response));
    sessionStorage.setItem('USER_DATA', JSON.stringify(response.data.records[0].fields));
    sessionStorage.setItem('TEAM_TYPE', JSON.stringify(teamType));

    if (teamType === 'Provider' || teamType === 'Inventory Analyst') {
      history.push('/providerLocations');
    } else if (redirectPath) {
      history.push(redirectPath);
    } else {
      history.push('/locations/place/-78.549668/39.014/7');
    }
  } catch (error) {
    console.error('Occured error while validating user, ', error);

    if (error.response || error.request) {
      setIsModalOpen(true);
    }
  } finally {
    setIsLoading(false);
  }

  //api to update counter in accountteams table
  await updateUserCounter(ssoUserMail, loginCounter).catch((err) => console.error(err));
}

const getUserDetails = (email, history, { logoutCallback, setIsLoading, setIsModalOpen }) => {
  const ssoUserMail = email || sessionStorage.getItem('ssoEmail');

  if (ssoUserMail) {
    const url = process.env.REACT_APP_MK2_URL + '/validateuser?email=' + ssoUserMail.toLowerCase();
    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': process.env.REACT_APP_API_KEY,
    };

    callValidateUserAPI(url, headers, ssoUserMail, { setIsLoading, setIsModalOpen, history });
  } else {
    localStorage.clear();
    sessionStorage.clear();
    logoutCallback({ revokeAccessToken: false });
  }
};

const OktaLogin = () => {
  const [userEmail, setUserEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();

  const onCloseModal = () => {
    setIsErrorModalOpen(false);
    setLoading(false);
    oktaAuth.signInWithRedirect();
  };

  React.useEffect(() => {
    if (isErrorModalOpen) {
      sessionStorage.clear();
    }
  }, [isErrorModalOpen]);

  if (authState?.isAuthenticated) {
    localStorage.setItem('MKII_access', 'yes');

    const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    if (tokenStorage && tokenStorage?.idToken && tokenStorage?.accessToken) {
      const artificialExpiration = tokenStorage?.accessToken['expiresAt']; // getting the accessToken expiry values

      authState.idToken['expiresAt'] = artificialExpiration;
      tokenStorage.idToken['expiresAt'] = artificialExpiration;

      if (tokenStorage?.idToken?.claims) {
        authState.idToken.claims['exp'] = artificialExpiration;
        tokenStorage.idToken.claims['exp'] = artificialExpiration;
      }

      localStorage.setItem('okta-token-storage', JSON.stringify(tokenStorage));
    }
  }

  React.useEffect(() => {
    if (authState?.isAuthenticated && !userEmail) {
      setLoading(true);

      oktaAuth
        .getUser()
        .then((info) => {
          sessionStorage.setItem('ssoEmail', info.email);

          setUserEmail(info.email);
          setLoading(false);
        })
        .catch((err) => {
          console.error('Occured error while fetching user data ', err);

          setIsErrorModalOpen(true);
          setLoading(false);
        });
    }
  }, [authState?.isAuthenticated, userEmail]);

  React.useEffect(() => {
    if (userEmail) {
      getUserDetails(userEmail, history, {
        logoutCallback: oktaAuth.signOut,
        setIsLoading: setLoading,
        setIsModalOpen: setIsErrorModalOpen,
      });
    }
  }, [userEmail]);

  React.useEffect(() => {
    if (!authState) {
      localStorage.removeItem('MKII_access');

      return;
    }
  }, [authState, oktaAuth]);

  return (
    <>
      {loading && (
        <div className="spinner__container">
          <div>
            <div className="spinner"></div>
          </div>
        </div>
      )}
      {isErrorModalOpen && (
        <PopupModal open={isErrorModalOpen} onClose={onCloseModal}>
          <p>
            <span>
              You are not authorised to access MKII. Please contact{' '}
              <a href="mailto:Agile_Admin@cbre.com" className="contact__link">
                {' '}
                Agile_Admin@cbre.com{' '}
              </a>{' '}
              to get the access.
            </span>
          </p>
          <button className="button primary mt-20 refreshLogin__btn" onClick={onCloseModal}>
            OK
          </button>
        </PopupModal>
      )}
    </>
  );
};

export default OktaLogin;
