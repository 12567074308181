import React, { useState, useReducer, useEffect } from 'react';

import './ProviderComponent.css';
import './ProviderTable.css';

import _, { filter } from 'lodash';
import SearchBar from 'material-ui-search-bar';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Card from './Card';
import classes from './ProviderPage.module.css';

import Pagination from '../common/Pagination/Pagination';
import Tooltip from '../common/Tooltip/Tooltip';
import { formatDate } from '../Utils/Utils';

const initialState = {
  isAllTabSelected: true,
  isOnTabSelected: false,
  isNotOnTabSlected: false,
  tabularContent: 'All Surveys',
};

const providerTabReducer = (state = { initialState }, action) => {
  switch (action.type) {
    case 'AllSurveys':
      return initialState;
    case 'OnSurvey':
      return {
        ...state,
        isAllTabSelected: false,
        isOnTabSelected: true,
        isNotOnTabSlected: false,
        tabularContent: action.type,
      };
    case 'NotOnSurvey':
      return {
        ...state,
        isAllTabSelected: false,
        isOnTabSelected: false,
        isNotOnTabSlected: true,
        tabularContent: action.type,
      };

    default:
      return state;
  }
};

const columns = [
  { id: 'dealId', label: 'Deal ID', minWidth: 50, align: 'right' },
  {
    id: 'requestedDate',
    label: 'Requested Date',
    minWidth: 110,
    format: (value) => value.toLocaleString('en-US'),
  },
  { id: 'client', label: 'Client', minWidth: 80 },
  { id: 'targetMarket', label: 'Target Market', minWidth: 80 },
  {
    id: 'deskCount',
    label: 'Desk Count',
    minWidth: 80,
    align: 'right',
  },
  {
    id: 'moveInDate',
    label: 'Move-in date',
    minWidth: 100,
    format: 'dd/MM/yyyy',
  },
  {
    id: 'stage',
    label: 'Stage',
    minWidth: 50,
  },
  { id: 'surveyInclusion', label: 'Survey Inclusion', minWidth: 80 },
  { id: 'status', label: 'Status', minWidth: 80 },
];

const cardList = [
  {
    id: 1,
    title: 'all',
    label: 'All',
    quantity: null,
    infoText:
      'Transactions where you were reached out and are currently in progress/ dead or closed',
  },
  {
    id: 2,
    title: 'active',
    label: 'Active',
    quantity: null,
    infoText: 'Transactions where you were reached out and are currently in progress',
  },
  {
    id: 3,
    title: 'completed',
    label: 'Completed',
    quantity: null,
    infoText: 'Transactions where you were reached out and are either closed or dead',
  },
];

export default function TransactionTabs(props) {
  const { providerData, providerName } = props;
  const [value, setValue] = React.useState(0);
  const [filterData, setFilteredData] = useState([]);
  const [searched, setSearched] = useState('');
  const [surveys, setSurveys] = useState([]);
  const [type, setType] = useState('All Surveys');
  const [filteredVal, setFilteredVal] = useState('all');
  const [status, setStatus] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [changeTab, setChangeTab] = useState(false);
  const [statusArrayValues, setStatusArrayValues] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [statusVal, setStatusVal] = useState('AllStatus');
  const [beforeStatusData, setBeforeStatusData] = useState([]);
  const [cardCollection, setCardCollection] = useState({
    activeObject: cardList[0],
    list: cardList,
  });

  const totalPages = Math.ceil(filterData.length / rowsPerPage);
  const offsetPerPage = page * rowsPerPage;
  const filteredData = filterData.slice(offsetPerPage, offsetPerPage + rowsPerPage);

  let initialSurveys = providerData;

  useEffect(() => {
    const filterProviderData = async () => {
      if (initialSurveys) {
        // eslint-disable-next-line
        initialSurveys = initialSurveys.sort(function (a, b) {
          return b.dealId - a.dealId;
        });

        cardList.map((card) => {
          const title = card && card.title && card.title.toLowerCase();
          const matchingFields = initialSurveys.filter(
            (record) => record && record.stage && record.stage.toLowerCase() === title,
          );

          card.quantity = matchingFields.length;
        });
        cardList[0].quantity = initialSurveys.length;
        clickHandler('all');
      }
    };

    if (providerData) {
      filterProviderData();
    }
  }, []);

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const toggleActiveCard = (id) => {
    setCardCollection({
      ...cardCollection,
      activeObject: cardCollection.list[id],
    });
  };

  const onAllTabs = () => {
    dispatch({ type: 'AllSurveys' });
    setType('AllSurveys');
    setStatusVal('AllStatus');
    setSearched('');
    setPage(0);
    setBeforeStatusData(surveys);
    setFilteredData(surveys);
    fetchStatusArray(surveys);
    setSearchedData(surveys);
  };
  const onTab = () => {
    dispatch({ type: 'OnSurvey' });
    setType('OnSurvey');
    setSearched('');
    setPage(0);
    const selectedTabVal = 'On Survey';

    setStatusVal('AllStatus');

    const tData = surveys.filter(
      (data) => data && data.surveyInclusion && data.surveyInclusion === selectedTabVal,
    );

    setFilteredData(tData);
    setBeforeStatusData(tData);

    fetchStatusArray(tData);
    setSearchedData(tData);
  };
  const onNotOnTab = () => {
    setSearched('');
    dispatch({ type: 'NotOnSurvey' });
    setStatusVal('AllStatus');

    setType('NotOnSurvey');
    setPage(0);
    const selectedTabVal = 'Not On Survey';
    const tData = surveys.filter(
      (data) => data && data.surveyInclusion && data.surveyInclusion === selectedTabVal,
    );

    setBeforeStatusData(tData);
    setFilteredData(tData);
    fetchStatusArray(tData);
    setSearchedData(tData);
  };
  const fetchStatusArray = (statusArray) => {
    const obj = statusArray.reduce(function (acc, cv) {
      acc[cv.status] = (acc[cv.status] || 0) + 1;

      return acc;
    }, {});

    let newArrayOfStatus = Object.keys(obj).map(function (status) {
      return {
        title: status,
        count: obj[status],
      };
    });
    const totalCount = newArrayOfStatus.reduce(function (sum, current) {
      return sum + current.count;
    }, 0);

    setTotalSum(totalCount);
    newArrayOfStatus = _.sortBy(newArrayOfStatus, 'title');

    setStatusArrayValues(newArrayOfStatus);
  };

  const clickHandler = (val) => {
    setPage(0);
    setStatusVal('AllStatus');

    setFilteredVal(val);
    searched && setSearched('');
    if (val && initialSurveys) {
      if (val == 'all') {
        setSurveys(initialSurveys);
        setChangeTab(true);
        setFilteredData(initialSurveys);
        fetchStatusArray(initialSurveys);
        dispatch({ type: 'AllSurveys' });
        setBeforeStatusData(initialSurveys);
      } else {
        const filteredVal = val && val.toLowerCase();
        const tData = initialSurveys.filter(
          (data) => data && data.stage && data.stage.toLowerCase() === filteredVal,
        );

        setSurveys(tData);
        setChangeTab(true);
        setFilteredData(tData);
        fetchStatusArray(tData);
        dispatch({ type: 'AllSurveys' });
        setBeforeStatusData(tData);
      }
    }
  };

  const handleStatusChange = (searchedVal) => {
    if (searchedVal) {
      setStatusVal(searchedVal);
      setPage(0);
      if (searchedVal == 'AllStatus') {
        cancelSearch();
      } else {
        const value = searchedVal.toLowerCase().trim();
        let result = [];

        result = beforeStatusData.filter((data) => {
          return data['status'] && data['status'].toLowerCase().indexOf(value) != -1;
        });
        setFilteredData(result);
      }
    }
  };

  const handleSearch = (searchedVal) => {
    if (searchedVal) {
      setSearched(searchedVal);
      setPage(0);
      setStatusVal('AllStatus');

      const value = searchedVal.toLowerCase().trim();
      let result = [];

      result = surveys.filter((data) => {
        return (
          (data['targetMarket'] && data['targetMarket'].toLowerCase().includes(value)) ||
          (data['client'] && data['client'].toString().toLowerCase().indexOf(value) != -1) ||
          (data['stage'] && data['stage'].toLowerCase().includes(value)) ||
          (data['dealId'] && data['dealId'].toString().indexOf(value) != -1) ||
          (data['deskCount'] && data['deskCount'].toString().indexOf(value) != -1) ||
          (data['providerName'] && data['providerName'].toLowerCase().indexOf(value) != -1) ||
          (data['status'] && data['status'].toLowerCase().indexOf(value) != -1) ||
          (data['surveyInclusion'] && data['surveyInclusion'].toLowerCase().indexOf(value) != -1)
        );
      });

      setFilteredData(result);
    } else {
      cancelSearch();
    }
  };
  const cancelSearch = () => {
    setSearched('');
    setStatusVal('AllStatus');
    setFilteredData(beforeStatusData);
  };

  const toggleActiveStyle = (index) => {
    let _val = null;

    if (cardCollection.list[index] === cardCollection.activeObject) {
      _val = classes.activeCard;
    }

    return _val;
  };

  const [state, dispatch] = useReducer(providerTabReducer, initialState);

  return (
    <div className={classes.transactionWrapper}>
      <div className={classes.cards}>
        {cardCollection.list.map((eachCard, index) => (
          <div
            key={index}
            className={toggleActiveStyle(index)}
            onClick={() => toggleActiveCard(index)}
          >
            <Card
              quantity={eachCard.quantity}
              infoText={eachCard.infoText}
              title={eachCard.label}
              clickHandle={() => clickHandler(eachCard.title)}
            />
          </div>
        ))}
      </div>

      <h4>All transactions for {providerName}</h4>

      <div>
        <SearchBar
          classes={{ root: classes.searchBar__container }}
          value={searched}
          onChange={(searchVal) => handleSearch(searchVal)}
          onCancelSearch={cancelSearch}
        />
      </div>
      <div className="transaction-tab-container">
        <FormControl className="transaction-tabs">
          <nav className="shortlist__tabs">
            <ul>
              <li id="1" onClick={onAllTabs} className={state.isAllTabSelected ? 'active' : ''}>
                All Surveys
              </li>
              <li id="2" onClick={onTab} className={state.isOnTabSelected ? 'active' : ''}>
                <div>On Survey</div>
                <Tooltip
                  val="transactionTooltip"
                  text="Transactions where your location(s) were presented to client"
                />
              </li>
              <li id="3" onClick={onNotOnTab} className={state.isNotOnTabSlected ? 'active' : ''}>
                <div>Not On Survey</div>
                <Tooltip
                  val="transactionTooltip"
                  text="Transactions where your location(s) were not presented to client"
                />
              </li>
            </ul>
          </nav>
        </FormControl>
        <FormControl className="statusDropdown">
          <Select
            className=""
            native
            value={statusVal}
            defaultValue="All Status()"
            onChange={(e) => handleStatusChange(e.target.value)}
          >
            <option value="AllStatus">All Status ({totalSum})</option>
            {statusArrayValues &&
              statusArrayValues.map((x, y) => (
                <option key={y} value={x.title}>
                  {x.title} ({x.count})
                </option>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="shortListTable">
        <table className="surveyTable">
          <thead>
            {columns.map((column) => (
              <th key={column.id} style={{ minWidth: column.minWidth, textAlign: column.align }}>
                {column.label}
              </th>
            ))}
          </thead>
          <tbody>
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={9} className="text-center">
                  No Records
                </td>
              </tr>
            )}
            {filteredData?.map((row) => {
              return (
                <tr key={row.dealid}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <td key={column.id} style={{ textAlign: column.align }}>
                        {column.id == 'moveInDate' || column.id == 'requestedDate'
                          ? formatDate(value)
                          : value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {filterData.length > 10 && (
        <Pagination
          page={page + 1}
          pageSize={rowsPerPage}
          toPage={setPage}
          onPageSize={handleChangeRowsPerPage}
          totalCount={totalPages}
          padding="0 0 35px 0"
        />
      )}
    </div>
  );
}
