import { useState, useRef, useEffect } from 'react';

import styled, { css } from 'styled-components';

import arrowDownIcon from '../../../assets/images/provider_locations/arrow_down.svg';
import { Block } from '../../common/StyledComponents/StyledBlock';

const AccordionLocation = ({ title, subtitle, children, defaultIsOpen, isError, id }) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false);

  const accordionRef = useRef(null);

  const handleToggle = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const content = accordionRef.current;

    content.style.maxHeight = `${content.scrollHeight * 100}px`;
    content.style.height = 'auto';

    if (!isOpen) {
      content.style.maxHeight = '0';
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultIsOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [defaultIsOpen]);

  return (
    <AccordionContainer isError={isError} id={id}>
      <AccordionHeader onClick={handleToggle}>
        <Block display="flex" gap="14">
          <AccordionIcon isOpen={isOpen} src={arrowDownIcon} alt="open" />
          <AccordionTitle>{title}</AccordionTitle>
        </Block>
        <AccordionSubtitle>{subtitle}</AccordionSubtitle>
      </AccordionHeader>
      <AccordionBlock ref={accordionRef} isOpen={isOpen}>
        {children}
      </AccordionBlock>
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${({ isError }) => (!isError ? '#e6eaea' : '#ff543e')};
`;
const AccordionHeader = styled.div`
  box-sizing: border-box;
  background: #e6eaea;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 8px;
`;
const AccordionIcon = styled.img`
  transition: all 200ms ease-in-out;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: rotate(-90deg);
    `}
`;
const AccordionTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
`;
const AccordionSubtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #565656;
`;
const AccordionBlock = styled.div`
  background: #ffffff;
  display: block;
  overflow: hidden;
  transition: all 600ms ease-in-out;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export default AccordionLocation;
