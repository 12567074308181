import { useState, useEffect, useMemo } from 'react';

const checkImage = (url) => {
  const trimmedUrl = url.trim();

  if (!trimmedUrl) {
    return Promise.resolve({ url, valid: false });
  }

  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => resolve({ url: trimmedUrl, valid: true });
    img.onerror = () => resolve({ url: trimmedUrl, valid: false });
    img.src = trimmedUrl;
  });
};

const useImageUrlValidation = (urlListStr) => {
  const [validationResults, setValidationResults] = useState([]);
  const urlList = useMemo(() => {
    return urlListStr?.length > 0
      ? urlListStr.map((obj) => obj?.url).filter((url) => url && url.trim() !== '')
      : [];
  }, [urlListStr]);

  useEffect(() => {
    if (!urlList || urlList.length === 0) {
      return;
    }

    Promise.all(urlList.map(checkImage))
      .then(setValidationResults)
      .catch((error) => console.log('Error in validating images:', error));
  }, [urlList]);

  return validationResults;
};

export default useImageUrlValidation;
