import styled from 'styled-components';

import errorIcon from './../../../assets/images/errorIcon.svg';
import successIcon from './../../../assets/images/successIcon.svg';

const ToastifyAlert = ({ isError, title, subtitle }) => {
  return (
    <ToastifyContainer>
      <IconContainer>
        <InfoIcon src={isError ? errorIcon : successIcon} />
      </IconContainer>
      <TextContainer>
        <Title>{title}</Title>
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
      </TextContainer>
    </ToastifyContainer>
  );
};

const ToastifyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const IconContainer = styled.div`
  padding: 0 16px 0 0;
`;
const InfoIcon = styled.img`
  max-width: 24px;
  max-height: 24px;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
`;
const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
`;

export default ToastifyAlert;
