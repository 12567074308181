import React, { Component } from 'react';

import './RequestMarketDataModal.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import { TextField } from '../common/TextField/TextField';
import { toastify } from '../common/Toastify';
import Spinner from '../Spinner/Spinner';
import { getKeysByValue } from '../Utils/Utils';

toast.configure();
const initialState = {
  yourName: '',
  yourEmail: '',
  nameError: '',
  emailError: '',
  disableButton: false,
};

class RequestMarketDataModel extends Component {
  state = initialState;

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove('is-danger');
  };

  validate = () => {
    let nameError = '';
    let emailError = '';

    if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.yourEmail,
      )
    ) {
      emailError = 'Please enter a valid email address';
    }
    if (this.state.yourEmail.trim() === '') {
      emailError = 'Please enter a valid email address';
    }
    if (this.state.yourName.trim() === '') {
      nameError = 'Please enter your name';
    }

    if (emailError || nameError) {
      this.setState({ emailError, nameError });

      return false;
    }

    return true;
  };
  closeModal = () => {
    this.props.close();
  };
  handleRequestSubmit = async (event) => {
    event.preventDefault();
    const isValid = this.validate();

    if (isValid) {
      this.setState({ disableButton: true });

      try {
        if (this.state.yourName && this.state.yourEmail) {
          this.setState({ loading: true });
          const address = this.props.centerData[3];

          const today = new Date();
          const res = JSON.parse(sessionStorage.getItem('result'));

          const userData = res.data.records[0].fields;

          const Projected_Move_In_Date =
            today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
          const reqObject = {
            fields: {
              'Projected Size': 30,
              'Projected Move In Date': Projected_Move_In_Date, // "2021-10-21",
              // "Request Type": "Market Data Request",
              'Requester name': this.state.yourName,
              'Requester Email': this.state.yourEmail,
              'Map URL': window.location.href,
              'Target Market': address && address.cityState ? address.cityState : '',
              'Market Address': address && address.place_name ? address.place_name : '',
              mkiiUserLogin: userData.Password,
            },
          };

          if (address?.region) {
            const regionValue = getKeysByValue(address.region);

            reqObject.fields.Region = regionValue.toString();
          }
          if (address?.country) {
            reqObject.fields['country'] = address.country;
          }
          if (!address?.cityState && address?.place_name) {
            reqObject.fields['Target Market'] = address.place_name;
          }

          const headers = APPLICATION_HEADERS.mkiiAPIKey;

          const url = API_URLS.requestMarketDataURL;

          await axios.post(url, reqObject, { headers });

          this.setState({ loading: false });
          this.props.close();

          toastify({
            title: 'Your request was submitted',
            type: 'success',
            position: 'top-center',
            hideProgressBar: true,
            autoClose: true,
          });
          this.setState(initialState);
        }
      } catch (error) {
        this.setState({ loading: false });
        console.log(error);
        this.props.close();
        toastify({
          title: 'Something went wrong..',
          type: 'error',
          position: 'top-center',
          hideProgressBar: true,
          autoClose: true,
        });
      }
    }
  };

  render() {
    return (
      <div className="modalBox">
        {this.state.loading && (
          <div className="parentDisable" width="100%">
            <Spinner />
          </div>
        )}
        <div className="market-container">
          <h3 className="requestMarket__title">Request Market Data</h3>

          <p> Submit your name and email to be notified when market data is added for this area.</p>
          <p>Requests are typically fulfilled in 3 business days</p>
          <form onSubmit={this.handleRequestSubmit}>
            <div className="">
              <div className="field requestTextField">
                <TextField
                  id="yourName"
                  label="Your name (required)"
                  error={!!this.state.nameError}
                  value={this.state.yourName}
                  onChange={this.onInputChange}
                  helperText={this.state.nameError}
                  fullWidth
                />
              </div>
            </div>
            <div className="">
              <div className="field requestTextField">
                <TextField
                  id="yourEmail"
                  label="Email address (required)"
                  error={!!this.state.emailError}
                  value={this.state.yourEmail}
                  onChange={this.onInputChange}
                  helperText={this.state.emailError}
                  fullWidth
                />
              </div>
            </div>
            <div className="btnField">
              <button className="button secondary" onClick={this.closeModal}>
                Cancel
              </button>
              <button className="button primary" disabled={this.state.disableButton}>
                Submit Request
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default RequestMarketDataModel;
