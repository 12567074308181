import React, { Component } from 'react';

import { withOktaAuth } from '@okta/okta-react';

import './../Navbar/Navbar.css';

import history from './../../history';
import ProviderNavHelpMenu from './ProviderNavHelpMenu';

export default withOktaAuth(
  class ProviderHeader extends Component {
    constructor(props) {
      super(props);
      this.logout = this.logout.bind(this);
    }

    async logout() {
      localStorage.clear();
      sessionStorage.clear();
      await this.props.oktaAuth.signOut({ revokeAccessToken: false });
    }

    render() {
      return (
        <div className="header">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              {JSON.parse(sessionStorage.getItem('TEAM_TYPE')) == 'Inventory Analyst' && (
                <div className="CBRELogo"> CBRE Agile Inventory Management</div>
              )}
              {JSON.parse(sessionStorage.getItem('TEAM_TYPE')) == 'Provider' && (
                <div className="CBRELogo"> CBRE Agile Provider Portal</div>
              )}
            </div>

            {history.location && !history.location.search && (
              <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-end">
                  <div className="navbar-help-menu">
                    <ProviderNavHelpMenu></ProviderNavHelpMenu>
                  </div>
                  <div>
                    <button className="button secondary" onClick={this.logout}>
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            )}
          </nav>
        </div>
      );
    }
  },
);
