import { useState, useEffect, useCallback } from 'react';

import { TextField, FormControl } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import './MaterialDatePicker.css';
// eslint-disable-next-line import/order
import moment from 'moment';

const MaterialDatePicker = ({ selectedDate, error, handleChange, label, isResetDatePickerLoc }) => {
  const [value, setValue] = useState(selectedDate);

  const convertDate = (date, dateFormat) => {
    if (!dateFormat) {
      return date;
    }

    return moment(date).format(dateFormat);
  };

  const onDateChange = useCallback(
    (newDate) => {
      if (newDate) {
        const date = convertDate(newDate, 'YYYY-MM-DD');

        setValue(newDate);
        handleChange && handleChange(date);
      }

      if (!isResetDatePickerLoc) {
        handleChange && handleChange(newDate);
      }
    },
    [handleChange, isResetDatePickerLoc],
  );

  useEffect(() => {
    onDateChange(selectedDate);
  }, [onDateChange, selectedDate]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label ? label : 'Date Available (required)*'}
          views={['year', 'month', 'day']}
          value={!isResetDatePickerLoc ? selectedDate : value}
          onChange={onDateChange}
          renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} error={error} />}
          disablePast={true}
          // disableMaskedInput={true}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default MaterialDatePicker;
