import React from 'react';

import styled from 'styled-components';

import mk2Logo from '../../assets/images/mk2_logo.svg';

const logo = (props) => (
  <LogoContainer height={props.height}>
    <Logo src={mk2Logo} alt="CBRE" />
  </LogoContainer>
);

const LogoContainer = styled.div`
  height: ${(props) => props.height}px;
  background-color: white;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #003f2d;
  font-weight: 500;
`;

const Logo = styled.img`
  margin-left: 6px;
`;

export default logo;
