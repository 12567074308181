import React, { useEffect, useState, useCallback } from 'react';

import axios from 'axios';

import { TextField, MenuItem, FormControl, InputLabel, Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';

import LoadingSpinner from './../MySurveys/LoadingSpinner';
import ProviderChart from './ProviderChart';
import ProviderMapDisplay from './ProviderMapDisplay';

import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import Layout from '../Layout/ProviderLayout';
import './ProviderDashboard.css';
import { SearchService } from '../ProviderLocations/ProviderSearch/SearchService';
import { getAnalyticsYears } from '../Utils/Utils';

const ANALYTIC_YEARS = getAnalyticsYears();

const ProviderDashboard = () => {
  const [type, setType] = React.useState('Geography');
  const [geographyData, setGeographyData] = React.useState({});
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());
  const [dealSizeData, setDealSizeData] = useState(null);
  const [dealSizeResponseData, setDealSizeResponseData] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [countriesData, setCountriesData] = useState([]);
  const [selectedRegionCountries, setSelectedRegionCountries] = useState([]);
  const [selectedRegionYears, setSelectedRegionYears] = useState([]);
  const [selectedcountry, setSelectedCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedYears, setSelectedYears] = useState();
  const [selectedYearCountry, setSelectedYearCountry] = useState('');
  const [yearsData, setYearsData] = useState();
  // const [selectedRegionYear, setSelectedRegionYear] = useState();

  const sortedRegion = [
    { name: 'APAC', region: 'APAC' },
    { name: 'NAM (US & Canada)', region: 'NAM' },
    { name: 'EMEA', region: 'EMEA' },
    { name: 'LATAM', region: 'LATAM' },
  ];

  const calculatedDemandData = {
    Large: 0,
    Small: 0,
    Medium: 0,
    NoOfLargeDeals: 0,
    NoOfSmallDeals: 0,
    NoOfMediumDeals: 0,
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const regions = ['APAC', 'EMEA', 'LATAM', 'NAM'];

  const fetchAnalytics = useCallback(async () => {
    setIsLoading(true);
    // const URL = API_URLS.demandAnalyticsURL + '?year=' + selectedYear;
    const URL = API_URLS.demandAnalyticsURL;

    const headers = APPLICATION_HEADERS.mkiiAPIKey;
    const res = await axios.get(URL, { headers });

    if (res?.status === 200 && res?.data) {
      setIsLoading(false);
      setGeographyData(res?.data?.geographyCount);
      setDealSizeResponseData(res?.data?.dealSizeCount);
      // setDealSizeData(res?.data?.dealSizeCount);
    }
  }, []);

  useEffect(() => {
    fetchAnalytics();
  }, [fetchAnalytics]);

  const calculateDemandData = () => {
    const apacData = dealSizeResponseData['NAM'];

    const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);

    let small_sum = 0;
    let medium_sum = 0;
    let large_sum = 0;

    const totalCities = Object.keys(apacData).length;

    // if (!Object.values(apacData).length) return sum;

    for (const value of Object.values(apacData)) {
      small_sum = small_sum + value['Small'];
      medium_sum = medium_sum + value['Medium'];
      large_sum = large_sum + value['Large'];
    }

    // calculatedDemandData

    // return sum;
  };

  const handleSelectedRegion = (region) => {
    setSelectedRegion(region);
    setCountries([]);
    setCountriesData();
    setSelectedCountry('');
    onInputChange('reset');
    setSelectedYearCountry('');
    setDealSizeData(null);

    setYearsData();

    let data = {};

    for (const key of Object.keys(dealSizeResponseData)) {
      if (key === region) {
        data = dealSizeResponseData[key];
      }
    }

    // calculateDemandData();

    setSelectedRegionCountries(data);
    setCountries(Object.keys(data));
  };

  const handleSelectedCountry = (event, country) => {
    event.preventDefault();
    setSelectedYearCountry('');
    setDealSizeData(null);

    country ? setSelectedCountry(country) : setSelectedCountry('');

    setYearsData();

    let yearsData = {};

    for (const key of Object.keys(selectedRegionCountries)) {
      if (key === country) {
        yearsData = selectedRegionCountries[key];
      }
    }

    setSelectedYears(yearsData);
    setYearsData(Object.keys(yearsData));
  };

  const handleSelectedYear = (year) => {
    setSelectedYearCountry(year);

    let dealsData = {};

    for (const key of Object.keys(selectedYears)) {
      if (key === year) {
        dealsData = selectedYears[key];
      }
    }

    setDealSizeData(dealsData);
  };

  function onInputChange(event, newValue, eventType) {
    if (eventType === 'reset') {
      setSelectedCountry('');
    } else {
      setSelectedCountry(newValue);
    }
  }

  return (
    <Layout>
      <div className="pageContainer1">
        <div className="dashboardDiv">
          <div className="dashboard-heading">
            <h3>Inquiries</h3>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Types</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Types"
                  onChange={handleChange}
                >
                  <MenuItem value="Geography">Geography</MenuItem>
                  <MenuItem value="Dealsize">Deal Size</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {type === 'Geography' && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="cyear">Year</InputLabel>
                  <Select
                    labelId="cyear"
                    id="demo-simple-select"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    label="Year"
                  >
                    {ANALYTIC_YEARS.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {type === 'Dealsize' && (
              <>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="region">Region *</InputLabel>
                    <Select
                      labelId="region"
                      id="demo-simple-select"
                      value={selectedRegion}
                      // onChange={(e) => setSelectedRegion(e.target.value)}
                      onChange={(e) => handleSelectedRegion(e.target.value)}
                      label="Region *"
                      // onKeyDown={handleKeyDown}
                    >
                      {sortedRegion.map((option) => (
                        <MenuItem
                          className="muiCustomMenuStyles"
                          key={option.region}
                          value={option.region}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disableClearable
                      id="free-solo-2-demo"
                      options={countries?.length > 0 ? countries : []}
                      className="inquirydropdown"
                      // key={(option) => option}
                      onChange={handleSelectedCountry}
                      // onInputChange={onInputChange}
                      disabled={countries.length == 0}
                      value={selectedcountry}
                      // getOptionKey={option}
                      // getOptionLabel={(option) => option}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country *"
                          placeholder="Select Country"
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                    {/* {countries.length <= 0 && <div>Please select country</div>} */}
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="cyear">Year *</InputLabel>
                    <Select
                      labelId="cyear"
                      id="cyear"
                      disabled={!yearsData}
                      // value={selectedYear}
                      value={selectedYearCountry}
                      onChange={(e) => handleSelectedYear(e.target.value)}
                      label="Year"
                    >
                      {yearsData?.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
            {isLoading && (
              <div className="loadingspinner">
                <LoadingSpinner />
              </div>
            )}
          </div>

          <div className="dashboard-content">
            {type && type == 'Geography' && (
              <div className="geography-container">
                <div className="mapDiv">
                  <ProviderMapDisplay geography_Data={geographyData} selectedYear={selectedYear} />
                </div>
                <div className="locationContainer_img">
                  <div className="continentLocation__block continentLocation__americas"></div>{' '}
                  <label className="continentLocation__label">AMERICAS</label>
                  <div className="continentLocation__block continentLocation__emea"></div>{' '}
                  <label className="continentLocation__label">EMEA</label>
                  <div className="continentLocation__block continentLocation__apac"></div>{' '}
                  <label className="continentLocation__label">APAC</label>
                  <div className="continentLocation__block continentLocation__latam"></div>{' '}
                  <label className="continentLocation__label">LATAM</label>
                </div>
              </div>
            )}
            {type === 'Dealsize' && dealSizeData && <ProviderChart dealSizeData={dealSizeData} />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProviderDashboard;
