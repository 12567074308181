import React, { useEffect, useState, useReducer, useCallback } from 'react';

import axios from 'axios';
import styled from 'styled-components';

import { TextField, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

import DeleteProfileModal from './DeleteProfile';
import ViewProfile from './ViewProfile';

import downloadIcon from '../../assets/images/downloadIcon.svg';
import pdfIcon from '../../assets/images/picture_as_pdf.svg';
import { ProviderLocationsContext, loadState } from '../../store/ProviderLocationsContext';
import { PrimaryButton, SecondaryButton } from '../common/Button';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import SnackBar from '../common/Snackbar';
import { Block } from '../common/StyledComponents/StyledBlock';
import LoadingSpinner from '../Spinner/Spinner';
import { UploadImageService } from '../Utils/UploadImage';
import './ProviderProfile.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const AddProfile = () => {
  const [formValues, setFormValues] = useState([{ maxCapcity: '' }]);
  const [pdfFileUrl, setPDFFileUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [providerId, setProviderId] = useState();
  const [capacity, setCapacity] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [majorProviderData, setMajorProviderData] = useState();
  const [majorProviderPDFPath, setMajorProviderPDFPath] = useState('');
  const [responseMessage, setResponseMessage] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [uploadedFileName, setUploadedFileName] = React.useState();
  const [majorProvidersList, setMajorProvidersList] = useState([]);
  const [errors, setErrors] = useState('');
  const [deleteProfileModalOpen, setDeleteProfileModalOpen] = useState(false);
  const [currentCapacity, setCurrentCapacity] = useState(0);
  // const [initialState, setinitialState] = useState({});

  const reducer = (state, action) => {
    if (action?.payload) {
      return { ...state, [action.type]: action.payload };
    }

    return state;
  };

  const initialState = loadState();
  const [state, dispatch] = useReducer(reducer, initialState);

  const userData = JSON.parse(sessionStorage.getItem('USER_DATA'));

  const fetchMajorProviders = useCallback(async () => {
    const url = `${API_URLS.getMajorProvidersURL}`;

    try {
      const response = await axios.get(url, { headers: APPLICATION_HEADERS.agileBaseAPIKey });

      if (response.status === 200 && response?.data?.list?.length > 0) {
        dispatch({ type: 'majorProvidersList', payload: response?.data?.list });

        const providerData = response?.data?.list.filter(
          (data) => data.providerName == userData['Team Name'],
        );

        setProviderId(providerData?.[0]?.majorProviderId);
        setMajorProviderData(providerData?.[0]);
        setMajorProviderPDFPath(providerData?.[0]?.pdfPath);
        providerData?.[0]?.capacity
          ? setCurrentCapacity(providerData?.[0]?.capacity)
          : setCurrentCapacity(0);
      } else {
        console.log('Major Providers data not coming');
      }
    } catch (e) {
      console.error('Error fetching Major Providers', e);
    }
  }, []);

  useEffect(() => {
    fetchMajorProviders();
  }, [fetchMajorProviders]);

  const handleUploadImages = (event) => {
    event.preventDefault();
    const currentFile = event.target.files[0];

    const filename = currentFile.name;

    setUploadedFileName(filename);
    const extension = filename.split('.').pop();

    if (extension !== 'pdf') {
      setErrors('Please upload .pdf file only');

      return;
    }

    setCurrentFile(currentFile);
  };

  const saveProfile = async () => {
    setIsLoading(true);
    const url = `${API_URLS.providerProfileURL}`;

    const formData = new FormData();

    formData.append('file', currentFile);
    let resultImages;

    if (currentFile) {
      await UploadImageService.upload(formData, {
        onSuccess: (response) => {
          if (Object.keys(response?.data)?.length > 0) {
            resultImages = Object.entries(response.data).map(([name, url]) => {
              return { url };
            });
            setMajorProviderPDFPath(resultImages?.[0]?.url);
          }
        },
      });
    }

    console.log(providerId, majorProviderData, majorProviderPDFPath);

    const reqData = {
      majorProviderId: providerId,
      capacity: capacity,
      pdfPath: currentFile && resultImages?.[0]?.url,
      updatedBy: sessionStorage.getItem('ssoEmail'),
    };

    const data = await axios.put(url, reqData, {
      headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
    });

    if (data) {
      setIsLoading(false);
      setCurrentCapacity(capacity);
      setCapacity('');
      setResponseMessage('Profile updated successfully');

      setIsSnackBarOpen(true);
      setUploadedFileName('');
    }
  };

  const onCapacityChange = (e) => {
    e.preventDefault();

    let value = e.target.value.replace(' ', '').replace('.', '');
    const isNumberPattern = /^\d+\.?\d*$/;

    const capacity = Number(e.target.value);

    if (Number(value) === 0) {
      value = '';
    }
    if (value) {
      if (!isNumberPattern.test(value)) return;
    }

    setCapacity(value);
  };

  const deleteProfile = () => {
    setDeleteProfileModalOpen(true);
  };

  const handleDeleteProfile = async () => {
    setIsLoading(true);
    const url = `${API_URLS.providerProfileURL}`;

    const reqData = {
      majorProviderId: providerId,
      capacity: 0,
      pdfPath: '',
      updatedBy: sessionStorage.getItem('ssoEmail'),
    };

    const data = await axios.put(url, reqData, {
      headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
    });

    if (data) {
      setIsLoading(false);
      setResponseMessage('Profile deleted successfully');
      setIsSnackBarOpen(true);
      setMajorProviderPDFPath('');
      setDeleteProfileModalOpen(false);
      fetchMajorProviders();
      setCurrentCapacity(0);
    }
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    const url = majorProviderPDFPath;
    const fileName = uploadedFileName ? uploadedFileName : 'downloadProfile.pdf';
    // const fileName = userData?.['Team Name'] + '_profile.pdf';

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        setDownloadLoading(false);
      })
      .catch(console.error);
  };

  return (
    <Block display="flex" flexDirection="column" gap="30">
      {isLoading && (
        <div className="parentDisable" width="100%">
          <LoadingSpinner />
        </div>
      )}
      {isSnackBarOpen && (
        <SnackBar title={responseMessage} delay={8000} onClose={() => setIsSnackBarOpen(false)} />
      )}
      <OptionTitleBlock className="optionMainBlock">
        <OptionTitle>Company's marketing brochure</OptionTitle>
        <OptionSubTitle>
          Upload your company's marketing brochure to include in our client surveys so they can
          better understand <br /> your product and differentiators
        </OptionSubTitle>

        <UploadBlock>
          <UploadButton component="label" variant="contained" onChange={handleUploadImages}>
            Upload marketing brochure here
            <VisuallyHiddenInput type="file" />
          </UploadButton>
          <div style={{ marginTop: '9px', fontSize: '14px' }}>{uploadedFileName}</div>
        </UploadBlock>
        <div style={{ marginTop: '9px', fontSize: '14px', marginLeft: '50px' }}>
          Please upload .pdf file only
        </div>
        {errors && (
          <div style={{ marginTop: '9px', fontSize: '14px', color: '#ff543e', marginLeft: '50px' }}>
            {errors}
          </div>
        )}
      </OptionTitleBlock>

      <OptionTitleBlock className="optionMainBlock">
        <OptionTitle>Max seating capacity</OptionTitle>
        <OptionSubTitle>
          If you don't want us to reach out with all requirements, Please specify the max seating
          capacity you can <br /> accomodate
        </OptionSubTitle>

        <CapacityBlock>
          <TextField
            fullWidth
            style={{
              fontSize: '14px',
            }}
            id="capacity"
            value={capacity}
            label="Enter max seating capacity"
            variant="outlined"
            onChange={onCapacityChange}
          />
          <PrimaryButton onClick={saveProfile} disabled={!currentFile && !capacity}>
            Save
          </PrimaryButton>
        </CapacityBlock>
        <div style={{ marginTop: '9px', fontSize: '14px', marginLeft: '50px' }}>
          Current max seating capacity: {currentCapacity}
        </div>
      </OptionTitleBlock>

      <ProfileBlock>
        <ProfileTitleBlock>
          <Block display="flex" alignItems="center" gap="10">
            <OptionTitle>Provider Profile</OptionTitle>
          </Block>
          <div className="item ml-auto">
            <Block display="flex" alignItems="center" gap="10">
              <PrimaryButton onClick={deleteProfile} disabled={!majorProviderPDFPath}>
                Delete
              </PrimaryButton>

              <DownloadButton
                color="primary"
                startIcon={<downloadIcon />}
                variant="contained"
                onClick={handleDownload}
                disabled={!majorProviderPDFPath}
              >
                <span>
                  <img src={downloadIcon} alt="Download Pdf" />
                </span>
                Download
                {/* {downloadLoading ? <CircularProgress size={20} /> : ''} */}
              </DownloadButton>
            </Block>
          </div>
        </ProfileTitleBlock>

        {/* <BlockChildContainer> */}
        <div style={{ background: '#fff', height: '500px', overflow: 'auto' }}>
          {majorProviderPDFPath ? (
            <ViewProfile pdfURL={majorProviderPDFPath} />
          ) : (
            <div style={{}}>
              <div
                style={{
                  textAlign: 'center',
                  margin: '0 auto',
                  display: 'block',
                  marginTop: '100px',
                }}
              >
                Provider profile not uploaded yet.
                <br />
                Add Provider Profile to view here
              </div>
              {/* <img
                src={pdfIcon}
                style={{ margin: '0 auto', display: 'block', paddingTop: '100px' }}
              /> */}
            </div>
          )}
        </div>

        {/* // </BlockChildContainer> */}
      </ProfileBlock>
      {deleteProfileModalOpen && (
        <DeleteProfileModal
          isOpen={true}
          onClose={() => setDeleteProfileModalOpen(false)}
          handleDelete={handleDeleteProfile}
          // providerName={userData['Team Name']}
        />
      )}
    </Block>
  );
};

const OptionTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const UploadBlock = styled.div`
  display: flex;
  gap: 20px;
`;

const LoaderBlock = styled.div`
  position: relative;
`;

const ProfileTitleBlock = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ProfileBlock = styled.div`
  border-top: 1px solid #003f2d;
  margin: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  .button.primary {
    background: #ffffff;
    color: #003f2d;
    border: 1px solid #003f2d;
  }
`;

const DownloadButton = styled.button`
  color: rgb(0, 63, 45);
  border: 1px solid rgb(0, 63, 45);
  background: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  &:disabled {
    background: #1a1a1a1f !important;
    color: #1a1a1a75 !important;
  }
`;

const CapacityBlock = styled.div`
  width: 336px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 50px;
`;

const CapacityTextField = styled.input`
  width: 300px;
  padding: 20px 10px;
`;

const SaveButton = styled.button`
  width: 100px;
`;
const UploadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  width: 315px;
  margin-left: 50px;
  margin-top: 15px;
  background: #ffffff !important;
  color: #003f2d !important;
  border: 1px solid #003f2d !important;
  margin-left: 50px !important;
`;

const OptionTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 15px;
`;

const OptionSubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 15px;
`;

const BlockChildContainer = styled.div`
  ::-webkit-scrollbar {
    height: 0;
  }
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  position: relative;

  .hashTextValidating {
    & .MuiInputBase-input {
      color: #999;
    }
  }
  .CheckBoxLocationHeading {
    grid-column: 1/ -1;
    margin-top: 25px;
  }

  .MuiAutocomplete-input {
    padding: 6.5px 5px 6px 7px !important;
  }

  .MuiFormControl-root {
    .MuiFormLabel-root {
      line-height: 1em;
      color: #1a1a1a60 !important;
    }
    .MuiFormLabel-filled,
    .MuiInputLabel-shrink {
      line-height: 1.6em;
    }
    .MuiInputBase-formControl {
      line-height: 1em;
      background: #fff;
      .MuiInputAdornment-root {
        svg {
          width: 0.7em;
          height: 0.7em;
        }
      }
      &.MuiInputBase-adornedStart {
        .MuiInputAdornment-root {
          color: rgba(0, 0, 0, 0.87);
        }
        .MuiOutlinedInput-input {
          padding-left: 0px;
        }
      }
      .MuiOutlinedInput-input {
        height: 1em;
        padding: 15px 14px;
        min-height: auto;
      }
    }
  }
`;

export default AddProfile;
