import breather from './../../assets/images/pin_breather3.svg';
import expansive from './../../assets/images/pin_expansive.svg';
import intelligentOffice from './../../assets/images/pin_intelligentoffice3.svg';
import iwg from './../../assets/images/pin_iwg3.svg';
import premier from './../../assets/images/pin_premier.svg';
import servcorp from './../../assets/images/pin_servcorp3.svg';
import executiveCentre from './../../assets/images/pin_theexecutivecentre3.svg';
import ucommune from './../../assets/images/pin_ucommune3.svg';
import wojo from './../../assets/images/pin_wojo3.svg';

import convene from '../../assets/images/pin_convene.svg';
import executiveCenter from '../../assets/images/pin_executive_center.svg';
import HQ from '../../assets/images/pin_HQ.svg';
import industrious from '../../assets/images/pin_industrious.svg';
import knotel from '../../assets/images/pin_knotel.svg';
// import novel from '../../assets/images/pin_novel.svg';
import other from '../../assets/images/pin_other providers.svg';
import regus from '../../assets/images/pin_Regus.svg';
import serendipity from '../../assets/images/pin_serendipity.svg';
import spaces from '../../assets/images/pin_spaces.svg';
import wework from '../../assets/images/pin_WeWork.svg';

export const providericons = [
  { name: 'WeWork', img: wework },
  { name: 'Convene', img: convene },
  { name: 'Executive center', img: executiveCenter },
  { name: 'HQ', img: HQ },
  { name: 'Industrious', img: industrious },
  { name: 'Knotel', img: knotel },
  { name: 'Expansive', img: expansive },
  { name: 'Regus', img: regus },
  { name: 'Serendipity', img: serendipity },
  { name: 'Spaces', img: spaces },
  { name: 'theIntelligentOffice', img: intelligentOffice },
  { name: 'Iwg', img: iwg },
  { name: 'Servcorp', img: servcorp },
  { name: 'ExecutiveCentre', img: executiveCentre },
  { name: 'Ucommune', img: ucommune },
  { name: 'Wojo', img: wojo },
  { name: 'Breather', img: breather },
  { name: 'Premier', img: premier },
  { name: 'other', img: other },
];
