import React, { useEffect, useState, useCallback, useRef } from 'react';

import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import SearchBar from 'material-ui-search-bar';
import { Redirect } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';

import downImg from './../../assets/images/arrow_downward_24px.svg';
import upImg from './../../assets/images/arrow_upward_24px.svg';
import downloadIcon from './../../assets/images/downloadIcon.svg';
import DeleteLocation from './DeleteLocation';
import EditLocation from './EditLocation';
import NewLocationModal from './NewLocation/AddLocation';

import checkedIcon from '../../assets/images/checkedIcon.svg';
import deleteIcon from '../../assets/images/deleteIconRed.svg';
import editLocationIcon from '../../assets/images/EditLocation.svg';
import notCheckedIcon from '../../assets/images/notCheckedIcon.svg';
import plusIcon from '../../assets/images/plusIcon.svg';
import viewLocationIcon from '../../assets/images/previewData.svg';
import deleteButtonIcon from '../../assets/images/provider_locations/deleteWhiteIcon.svg';
import editButtonIcon from '../../assets/images/provider_locations/editButtonIcon.svg';
import { PrimaryButton, SecondaryButton, Button } from '../common/Button';
import Pagination from '../common/Pagination/Pagination';
import SnackBar from '../common/Snackbar';
import { Block } from '../common/StyledComponents/StyledBlock';
import './ProviderLocations.css';
// eslint-disable-next-line import/order
import history from '../../history';

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: '#F5F7F7',
    width: 'none',
  },
}))(MuiTableHead);

const TableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    borderBottom: '1px solid #ccc',
  },
}))(MuiTableCell);

const workSheetName = 'Worksheet-1';
const workBookName = 'ProviderLocations';

function Row(props) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false);
  const [isEditOption, setIsEditOption] = useState(false);
  const [isOnlyViewOption, setIsOnlyViewOption] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isSnackBarOpenLocAlreadyExists, setIsSnackBarOpenLocAlreadyExists] = useState(false);

  const {
    row,
    sno,
    currentPage,
    handleSelectOption,
    selectedOptions,
    refreshLocations,
    providerName,
    userType,
    majorProvidersList,
  } = props;

  const [open, setOpen] = React.useState(false);

  const onCloseDeleteItemModal = (val) => {
    setIsDeleteModalOpen(false);
    setCurrentItem(null);
    if (val == 'deleted') {
      setOpen(false);
      refreshLocations('isDeleteSuccess');
    }
  };

  const onCloseEditLocation = () => {
    setIsViewEditModalOpen(false);
    setIsEditOption(false);
    setIsOnlyViewOption(false);
  };

  const onClickEditBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsViewEditModalOpen(true);
    setIsEditOption(true);
  };

  const onClickViewBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsViewEditModalOpen(true);
    setIsOnlyViewOption(true);
  };

  const onSuccessEditLocation = () => {
    setIsSnackBarOpen(true);
    setTimeout(() => {
      refreshLocations('isEditSuccess');
    }, 1000);
  };

  const onSuccessEditLocationAlreadyExists = () => {
    setIsSnackBarOpenLocAlreadyExists(true);
  };

  const onClickedEditFromView = React.useCallback((flag) => {
    setIsOnlyViewOption(false);
    setIsEditOption(true);
  }, []);

  return (
    <React.Fragment>
      {isSnackBarOpen && (
        <SnackBar
          title="Location successfully updated"
          delay={8000}
          onClose={() => setIsSnackBarOpen(false)}
        />
      )}
      {isSnackBarOpenLocAlreadyExists && (
        <SnackBar
          title="'The location you are trying to add already exists in the database. Please verify the details and add the provider location again.';"
          onClose={() => setIsSnackBarOpenLocAlreadyExists(false)}
          delay={8000}
          isError
          customClass="snackBarBlock"
        />
      )}

      {/* {row?.formatted_address && ( */}
      <>
        <TableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        >
          <TableCell width="2%" style={{ textAlign: 'center' }}>
            {row.SERIAL_NO}
          </TableCell>
          <TableCell width="15%">{row.provider_name}</TableCell>
          <TableCell width="20%">{row.formatted_address}</TableCell>
          <TableCell width="10%">{row?.provider_updated_date}</TableCell>
          <TableCell colSpan={2} width="15%">
            <ActionButtonsBlockWrapper>
              <img
                src={deleteIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsDeleteModalOpen(true)}
                alt="delete icon"
              />
              <img
                src={editLocationIcon}
                onClick={onClickEditBtn}
                style={{ cursor: 'pointer' }}
                alt="Edit icon"
              />

              <img
                src={viewLocationIcon}
                onClick={onClickViewBtn}
                style={{ cursor: 'pointer' }}
                alt="View icon"
              />
            </ActionButtonsBlockWrapper>
          </TableCell>
        </TableRow>

        {!!isDeleteModalOpen && (
          <DeleteLocation item={row} isOpen={isDeleteModalOpen} onClose={onCloseDeleteItemModal} />
        )}
        {!!isViewEditModalOpen && (
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            libraries={['places']}
            loadingElement={<div style={{ height: `1%` }} />}
          >
            <EditLocation
              item={row}
              providerName={providerName}
              userType={userType}
              majorProvidersList={majorProvidersList}
              isOpen={isViewEditModalOpen}
              onClose={onCloseEditLocation}
              isEditOption={isEditOption}
              isOnlyViewOption={isOnlyViewOption}
              onSuccessEditLocation={onSuccessEditLocation}
              onSuccessEditLocationAlreadyExists={onSuccessEditLocationAlreadyExists}
              onClickedEditFromView={onClickedEditFromView}
            />
          </LoadScript>
        )}
      </>
      {/* )} */}
    </React.Fragment>
  );
}

const ProviderLocationsTable = ({
  providerData,
  providerName,
  teamType,
  majorProvidersList,
  refreshLocations,
  totalCount,
  detectBottomScroll,
  isLoading,
  page,
  onRenderedChildTable,
  isRendering,
  downloadLoading,
  downloadLocationsData,
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searched, setSearched] = React.useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isFieldSort, setIsFieldSort] = React.useState('SERIAL_NO');
  const [isSorted, setIsSorted] = React.useState('asc');
  const [isInputSearchEmpty, setIsInputSearchEmpty] = React.useState(false);

  useEffect(() => {
    if (providerData?.length > 0) {
      setFilteredData(providerData);
    }
  }, [providerData]);

  const debounceHandleSearch = (func, delay) => {
    let timeoutId;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounceHandleSearch((query) => {}, 300),
    [],
  );

  const cancelSearch = useCallback(() => {
    searched && setSearched('');
    setFilteredData(providerData);
    setIsInputSearchEmpty(false);
    // observerRef.current.observe(sentinelRef.current);
  }, [providerData, searched]);

  const handleSearch = (searchedVal) => {
    if (searchedVal) {
      debouncedSearch(searchedVal);
      setIsInputSearchEmpty(true);
      setSearched(searchedVal);

      const value = searchedVal.toLowerCase().trim();
      let result = [];

      result = providerData.filter((data) => {
        const emailContacts =
          data && data.provider_contact_info ? data.provider_contact_info.map((s) => s.email) : [];

        return (
          (data['provider_name'] &&
            data['provider_name'].toLowerCase().includes(value.toLowerCase())) ||
          (data['formatted_address'] &&
            data['formatted_address'].toLowerCase().includes(value.toLowerCase())) ||
          (data['provider_updated_date'] &&
            data['provider_updated_date'].toLowerCase().includes(value.toLowerCase())) ||
          (data['source_system'] &&
            data['source_system'].toString().toLowerCase().indexOf(value.toLowerCase()) != -1) ||
          (data['provider_id'] &&
            data['provider_id'].toString().toLowerCase().indexOf(value.toLowerCase()) != -1) ||
          (emailContacts &&
            emailContacts.length > 0 &&
            emailContacts.toString().toLowerCase().includes(value.toLowerCase()))
        );
      });

      setFilteredData(result);
    } else {
      setIsInputSearchEmpty(false);
      cancelSearch();
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (!isInputSearchEmpty) {
        cancelSearch();
      }
    };

    !isInputSearchEmpty && handleClick();

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [cancelSearch, isInputSearchEmpty]);

  const closeNewLocation = (count) => {};

  //new Code for Download calling count and data api's
  const downloadData = () => {
    downloadLocationsData();
  };

  const handleSelectOption = (optionId) => {
    if (!selectedOptions.includes(optionId)) {
      setSelectedOptions((prev) => [...prev, optionId]);
    } else {
      setSelectedOptions((prev) => prev.filter((id) => id !== optionId));
    }
  };

  const gotoFileUpload = () => {
    history.push('/MKII/uploadFile');
    window.location.href = '/MKII/uploadFile';
    <Redirect to="/MKII/uploadFile" push={true} />;
  };

  const sortResponse = (name) => {
    setIsFieldSort(name);
    handleRequestSort(isSorted, name);
  };

  const sortCounts = (fieldName) => {
    return (
      // eslint-disable-next-line no-unreachable
      <span className="sortedLocationImage__Icon">
        {isSorted == 'desc' ? <img src={downImg}></img> : <img src={upImg}></img>}
      </span>
    );
  };

  const handleRequestSort = (sortOrder, sortField) => {
    if (sortField == 'provider_updated_date') {
      if (sortOrder == 'desc') {
        const sortedData = filteredData.sort((a, b) => {
          return new Date(a[sortField]) - new Date(b[sortField]);
        });

        setFilteredData(sortedData);
        setIsSorted('asc');
      }
      if (sortOrder == 'asc') {
        const sortedData = filteredData.sort((a, b) => {
          return new Date(b[sortField]) - new Date(a[sortField]);
        });

        setFilteredData(sortedData);
        setIsSorted('desc');
      }
    }
    if (sortField == 'SERIAL_NO') {
      if (sortOrder == 'desc') {
        setFilteredData(filteredData.sort((a, b) => a.SERIAL_NO - b.SERIAL_NO));
        setIsSorted('asc');
      } else {
        setFilteredData(filteredData.sort((a, b) => b.SERIAL_NO - a.SERIAL_NO));
        setIsSorted('desc');
      }
    }

    if (sortField !== 'provider_updated_date' && sortField !== 'SERIAL_NO') {
      if (sortOrder == 'desc') {
        const sortedData = filteredData.sort((a, b) => a[sortField].localeCompare(b[sortField]));

        setFilteredData(sortedData);
        setIsSorted('asc');
      } else {
        const sortedData = filteredData.sort((a, b) => b[sortField].localeCompare(a[sortField]));

        setFilteredData(sortedData);
        setIsSorted('desc');
      }
    }
  };

  return (
    <Container>
      <div className="flex-container">
        <Block display="flex" alignItems="center" gap="10">
          {providerName && (
            <TotalSelected>
              Provider Name: <span style={{ fontWeight: '100' }}>{providerName}</span>
            </TotalSelected>
          )}
          <Title>
            Total No. of Locations: <span style={{ fontWeight: '100' }}>{totalCount}</span>
          </Title>
        </Block>
        <div className="item ml-auto">
          <DownloadButton
            color="primary"
            startIcon={<downloadIcon />}
            variant="contained"
            onClick={downloadData}
          >
            <span>
              <img src={downloadIcon} alt="Download Excel" />
            </span>
            Download
            {downloadLoading ? <CircularProgress size={20} /> : ''}
          </DownloadButton>
        </div>
      </div>
      <Block display="flex" justifyContent="space-between" padding="0px 15px" background="#fff">
        <>
          <Block display="flex" alignItems="center" gap="36">
            <Title style={{ fontSize: '16px' }}>
              Showing {filteredData && filteredData?.length} Locations{' '}
            </Title>
          </Block>
          <SearchBarContainer>
            <SearchBar
              placeholder="Search"
              style={{
                width: '260px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
              value={searched}
              onChange={(searchVal) => handleSearch(searchVal)}
              onCancelSearch={cancelSearch}
            />
          </SearchBarContainer>
        </>
      </Block>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" className="locationsTable">
          <TableHead>
            <TableRow>
              <TableCell
                width="2%"
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => sortResponse('SERIAL_NO')}
              >
                S.No
                {isFieldSort == 'SERIAL_NO' && sortCounts('SERIAL_NO')}
              </TableCell>
              <TableCell
                width="15%"
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => sortResponse('provider_name')}
              >
                Provider Name
                {isFieldSort == 'provider_name' && sortCounts('provider_name')}
              </TableCell>
              <TableCell
                width="20%"
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => sortResponse('formatted_address')}
              >
                Address
                {isFieldSort == 'formatted_address' && sortCounts('formatted_address')}
              </TableCell>
              <TableCell
                width="10%"
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => sortResponse('provider_updated_date')}
              >
                Last Updated
                {isFieldSort == 'provider_updated_date' && sortCounts('provider_updated_date')}
              </TableCell>
              <TableCell width="10%">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData.map((location, idx) => (
                <Row
                  key={idx}
                  sno={location.SERIAL_NO}
                  row={location}
                  currentPage={currentPage}
                  handleSelectOption={handleSelectOption}
                  selectedOptions={selectedOptions}
                  refreshLocations={refreshLocations}
                  providerName={providerName}
                  userType={teamType}
                  majorProvidersList={majorProvidersList}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredData &&
        filteredData?.length > 100 &&
        !isInputSearchEmpty &&
        (page - 1) * 1000 <= providerData?.length && (
          <TypingWrapper>
            <TypingBlock>
              <PrimaryButton onClick={detectBottomScroll} disabled={!isRendering}>
                {!isRendering ? (
                  <>
                    <div style={{ color: '#555' }}>Loading </div> <CircularProgress size={20} />
                  </>
                ) : (
                  'Load more locations'
                )}
              </PrimaryButton>
            </TypingBlock>
          </TypingWrapper>
        )}
    </Container>
  );
};

export default ProviderLocationsTable;

const ActionButtonsBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`;

const TypingWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const TypingBlock = styled.div`
  margin: 25px 0 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  margin-top: 20px;
  position: relative;
`;
const SearchBarContainer = styled.div`
  margin: 10px;
  margin-left: auto;
`;
const TitleText = styled.label`
  font-weight: 500;
  display: table-cell;
`;

const ProviderIDTitleText = styled.label`
  font-weight: 500;
  width: 100px;
  font-size: 9px;
  color: #ccc;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 13px;
`;
const DownloadButton = styled.button`
  color: rgb(0, 63, 45);
  border: 1px solid rgb(0, 63, 45);
  background: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
`;

const TotalSelected = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  margin-left: 16px;
  margin-right: 20px;
`;
const LocationButton = styled.button`
  color: white;
  border: 1px solid rgb(0, 63, 45);
  background: #003f2d;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
`;

export const CheckedIcon = styled.img`
  width: 18px;
  height: 18px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
`;
const DeleteButton = styled(Button)`
  color: #a03530;
  border: 1px solid #a03530;
  background: #ffffff;

  &:hover {
    background: #1a1a1a0a;
    border-color: #a03530;
    cursor: pointer;
  }

  &:disabled {
    background: rgba(26, 26, 26, 0.12);
    color: rgba(26, 26, 26, 0.46);
    border: none;
    cursor: not-allowed;
  }
`;

const ExpandRowMainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
`;

const ExpandRowColumnWrapper = styled.div`
  display: grid;
  gap: 20px;

  & .additional-info-wrapper {
    color: #1a1a1a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.14px;
  }
  & .inner-row-column-wrapper {
    display: grid;
    grid-template-columns: 130px auto;
  }
`;
