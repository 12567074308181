import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function pricingLoadingSkeleton(props) {
  return (
    <Grid container wrap="nowrap">
      {Array.from(new Array(3)).map((item, index) => (
        <Box key={index} sx={{ width: 60, marginRight: 5, my: 1 }}>
          <Skeleton variant="rectangular" width={45} height={20} />
          <Box sx={{ pt: 0.6 }}>
            <Skeleton width="90%" />
            <Skeleton width="100%" />
          </Box>
        </Box>
      ))}
    </Grid>
  );
}

export default pricingLoadingSkeleton;
