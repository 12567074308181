import React from 'react';

import styled from 'styled-components';

import checkedIcon from '../../../assets/images/checkedIcon.svg';

const CheckBox = ({ checked, onChange }) => {
  return (
    <Container checked={checked} onClick={onChange}>
      {checked && <img src={checkedIcon} alt="" />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadada;
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:first-child {
    font-weight: 600;
  }
`;

export default CheckBox;
