import React from 'react';

import { useHistory } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';

export const IsLogin = () => {
  const { authState } = useOktaAuth();
  const history = useHistory();

  React.useEffect(() => {
    // redirect user to okta login component for getting his email and other data if we don't have it
    if (authState) {
      const { idToken, isAuthenticated, isPending } = authState;

      if (idToken && isAuthenticated && !isPending && !sessionStorage.getItem('ssoEmail')) {
        history.push('/okta');
      }
    }
  }, [authState?.isAuthenticated]);
};
