import React, { useState, useEffect, createRef } from 'react';

import './ProviderMap.css';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import axios from 'axios';
import _ from 'lodash';
import mapboxgl from 'mapbox-gl';

import { APPLICATION_HEADERS, API_URLS } from '../common/headers';

const ProviderMap = (props) => {
  mapboxgl.accessToken = APPLICATION_HEADERS.mapBoxAccessToken;

  const [map, setMap] = useState(null);
  const [mapData, setMapData] = useState([]);
  const [isData, setIsData] = useState(false);
  const { geography_Data } = props;
  const mapcontainer = createRef(null);
  const data = geography_Data?.[props.selectedYear];

  function getAPIs(endpoints) {
    return Promise.all(
      endpoints.map(async ([key, value]) => {
        if (key == 'New York City') {
          key = 'New York';
        }
        const apiResponse = await axios.get(
          API_URLS.mapBoxURL +
            encodeURIComponent(key) +
            '.json?limit=1&access_token=' +
            `${APPLICATION_HEADERS.mapBoxAccessToken}` +
            '&language=en',
        );

        return {
          allData: apiResponse.data,
          value: value,
        };
      }),
    );
  }
  const fetchProviderData = async () => {
    const allValues = [];
    let i = 0;

    if (data) {
      Object.entries(data).forEach(async ([key1, value]) => {
        let color;

        console.log(key1);

        if (key1 == 'APAC') {
          color = '#D2785A';
        }
        if (key1 == 'NAM') {
          color = '#435354';
        }
        if (key1 == 'EMEA') {
          color = '#3E7CA6';
        }
        if (key1 == 'LATAM') {
          color = '#DBD99A';
        }

        const endpoints = Object.entries(data[key1]);

        await getAPIs(endpoints).then((finalData) => {
          finalData.forEach((response, index) => {
            const o = {
              city: response.allData.query[0],
              type: 'Feature',
              size: response.value,
              properties: {
                circleColor: color,
                circleRadius: response.value / 50,
                title: response.allData.query[0],
                description: response.allData.query[0],
              },
            };

            if (response) {
              if (response.allData.features.length > 0 && response.allData.features[0]) {
                const coordinates = response.allData.features[0].center;

                o.geometry = {
                  type: 'Point',
                  coordinates: [coordinates[0], coordinates[1]],
                };
              }
            }
            allValues.push(o);
          });
          if (i == 3) {
            setMapData(allValues);
          }
          i++;
        });
      });
    }
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapcontainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE.trim(),

      center: [0.1, 0.1],
      zoom: 1.3,
      minZoom: 1.1,
      renderWorldCopies: false,
    });

    map.on('load', () => {
      setMap(map);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProviderData();
  }, [geography_Data, props.selectedYear]);

  useEffect(() => {
    if (!geography_Data) return;

    if (mapData.length > 0 && map) {
      if (map?.getLayer('geo_point')) {
        map?.removeLayer('geo_point');
      }

      if (map?.getSource('geography_analytics')) {
        map?.removeSource('geography_analytics');
      }

      map.addSource('geography_analytics', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: mapData,
        },
      });

      map.addLayer({
        id: 'geo_point',
        type: 'circle',
        layout: {
          visibility: 'visible',
        },
        source: 'geography_analytics',
        paint: {
          'circle-radius': ['get', 'circleRadius'],
          'circle-color': ['get', 'circleColor'],
        },
        filter: ['==', '$type', 'Point'],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapData]);

  return <div id="mapContainer" ref={mapcontainer} className="map map-container-box"></div>;
};

export default ProviderMap;
