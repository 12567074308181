import React, { useState } from 'react';

import { useTable, usePagination, useSortBy } from 'react-table';
import styled from 'styled-components';

import Modal from '@material-ui/core/Modal';

import ViewDetailsModal from './ViewDetailsModal';

import downImg from '../../assets/images/arrow_downward_24px.svg';
import upImg from '../../assets/images/arrow_upward_24px.svg';
import Pagination from '../common/Pagination/Pagination';

import './TableSurveys.css';

const Styles = styled.div`
  padding-bottom: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid rgba(0, 63, 45, 0.2);

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid rgba(0, 63, 45, 0.2);
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const columns = [
  {
    Header: 'Deal ID',
    accessor: 'DealID',
  },
  {
    Header: 'Date Requested',
    accessor: 'Date Requested',
  },
  {
    Header: 'Client Name',
    accessor: 'Client Name',
  },
  {
    Header: 'Desk Count',
    accessor: 'Projected Size',
  },
  {
    Header: 'Target Market',
    accessor: 'Target Market',
  },
  {
    Header: 'Status',
    accessor: 'Stage',
  },
];

function parseDeskCount(deskCountValue) {
  const deskCountStr = String(deskCountValue);
  const parts = deskCountStr?.split('-').map((part) => parseInt(part.trim(), 10));

  return parts[0];
}

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data

function Table({ columns, data, fetchData, loading, pageCount: controlledPageCount, totalCount }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        // hiddenColumns:["Move-In-date"],
        sortBy: [
          {
            id: 'DealID',
            desc: true,
          },
        ],
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      autoResetPage: false,
      disableSortRemove: true,
      // autoResetSortBy:false
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          let rowOneColumn = row1.values[columnName];
          let rowTwoColumn = row2.values[columnName];

          if (!rowOneColumn) rowOneColumn = 'ZZZ';
          if (!rowTwoColumn) rowTwoColumn = 'ZZZ';

          if (columnName === 'Date Requested') {
            const a1 = new Date(rowOneColumn);
            const b1 = new Date(rowTwoColumn);

            if (a1 < b1) return 1;
            else if (a1 > b1) return -1;

            return 0;
          }
          if (columnName === 'DealID') {
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          }
          if (
            columnName === 'Client Name' ||
            columnName === 'Stage' ||
            columnName === 'Target Market'
          ) {
            if (isNaN(rowOneColumn) && rowOneColumn && rowOneColumn[0]) {
              if (rowTwoColumn) {
                if (rowOneColumn[0].toUpperCase() < rowTwoColumn[0].toUpperCase()) {
                  return -1;
                } else if (rowOneColumn[0].toUpperCase() > rowTwoColumn[0].toUpperCase()) {
                  return 1;
                }
              }
            }
          }
          if (columnName === 'Projected Size') {
            const deskCount1 = parseDeskCount(row1.values[columnName]);
            const deskCount2 = parseDeskCount(row2.values[columnName]);

            return deskCount1 - deskCount2;
          }
        },
      },
    },
    useSortBy,
    usePagination,
  );

  const [showDetails, setShowDetails] = useState(false);
  const [rowData, setRowData] = useState({});
  const handleOpenDetailsModal = (value) => {
    setShowDetails(true);
    setRowData(value);
  };
  const handleCloseModal = (value) => {
    setShowDetails(false);
  };
  const handleClose = () => {
    setShowDetails(false);
  };

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, gotoPage });
  }, [fetchData, pageIndex, pageSize, gotoPage]);

  // Render the UI for your table
  return (
    <>
      {totalCount > 10 && (
        <Pagination
          page={pageIndex + 1}
          pageSize={pageSize}
          totalCount={pageCount}
          onPageSize={setPageSize}
          toPage={gotoPage}
        />
      )}

      <div className="myTransactionTableContainer">
        <table {...getTableProps()} className="surveyTable">
          <thead>
            {headerGroups.map((headerGroup, trIndex) => (
              <tr key={trIndex} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, thIndex) => (
                  <th
                    key={thIndex}
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  >
                    <div className="tableHeaderColumn">
                      {column.render('Header')}
                      <div className="sortedImage__block">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={downImg} className="sortImg"></img>
                          ) : (
                            <img src={upImg} className="sortImg"></img>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </th>
                ))}
                <th> Action</th>
                <th> </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {totalCount && totalCount === 0 ? (
              <tr>
                <td colSpan="7" className="text-center">
                  No Records
                </td>
              </tr>
            ) : (
              ''
            )}
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, idx) => {
                    return (
                      <td key={idx} {...cell.getCellProps()} className="tableSurveysBody__row">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                  <td>
                    <a
                      title="Preview Details"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDetailsModal(row.original);
                      }}
                    >
                      Details
                    </a>
                  </td>
                  <td className="surveyStatus__row">
                    {((row.original.Stage &&
                      row.original.Stage !== 'Survey' &&
                      row.original.SurveyLink) ||
                      (row.original['Survey Status'] &&
                        row.original.Stage === 'Survey' &&
                        row.original['Survey Status'] === 'Completed')) && (
                      <a
                        href={row.original.SurveyLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="View Client Deliverable"
                      >
                        View
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal
        open={showDetails}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <ViewDetailsModal handleCloseModal={handleCloseModal} rowData={rowData} />
        </div>
      </Modal>
    </>
  );
}

// Let's simulate a large dataset on the server (outside of our component)
// const serverData = makeData(10000);
// const serverData = makeData(10000);

function TableSurveys(props) {
  const { tableData, currentPage } = props;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  // We'll start our table without any data

  const [totalCount, setTotalCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  let currentpage2 = 0;
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, gotoPage }) => {
      if (tableData) setTotalCount(tableData.length);
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      if (currentpage2 == 0 && pageIndex != 0) {
        gotoPage(0);
      }
      // eslint-disable-next-line
      currentpage2 = 2;
      // console.log(currentpage2, pageIndex);
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // We'll even set a delay to simulate a server here
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex;
          const endRow = startRow + pageSize;

          setData(tableData.slice(startRow, endRow));

          // Your server could send back total page count.
          // For now we'll just fake it, too
          setPageCount(Math.ceil(tableData.length / pageSize));
          setLoading(false);
        }
      }, 100);
    },
    [tableData, currentPage],
  );

  return (
    <Styles>
      <Table
        className="surveyTable"
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalCount={totalCount}
      />
    </Styles>
  );
}

export default TableSurveys;
