import React, { Fragment, useState, useEffect } from 'react';

import LeftNavbar from '../LeftNavbar/LeftNavbar';
import Navbar from '../Navbar/Navbar';

function Layout(props) {
  const [isProviderUser, setIsProviderUser] = useState(false);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem('TEAM_TYPE'));

    if (currentUser === 'Provider') {
      setIsProviderUser(true);
    } else {
      setIsProviderUser(false);
    }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <div className="mk2 content">
        {!isProviderUser && <LeftNavbar />}
        <div className="mk2 rightbar">{props.children}</div>
      </div>
    </Fragment>
  );
}

export default Layout;
