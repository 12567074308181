import React, { useEffect, useState, useCallback } from 'react';

import AddProfile from './AddProfile';

import Layout from '../Layout/ProviderLayout';

const ProviderProfile = () => {
  return (
    <Layout>
      <div className="pageContainer1">
        <AddProfile />
      </div>
    </Layout>
  );
};

export default ProviderProfile;
