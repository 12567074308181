import React, { Component } from 'react';

import { toast } from 'react-toastify';

import { EMAILS } from '../common/headers';
import PopupModal from '../common/PopupModal/PopupModal';
import 'react-toastify/dist/ReactToastify.css';
import './../RequestMarketData/RequestMarketDataModal.css';
import { toastify } from '../common/Toastify';
import RequestSurveyModal from '../RequestSurvey/RequestSurveyModal';

export default class RequestSurveyBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newproduct: null,
      products: [],
      isModelOpen: false,
      isSurveyModalOpen: false,
      isDisabled: false,
      modelOpen: false,
    };
  }
  closeSurveyModal = () => {
    this.setState({ isSurveyModalOpen: false });
  };
  showSurveyModal = () => {
    const res_user = JSON.parse(sessionStorage.getItem('USER_DATA'));

    if (!res_user && !res_user.ClientNames) {
      toastify({
        type: 'error',
        title: 'Client not assigned. Please contact: ' + EMAILS.adminEmail,
        position: 'top-center',
        hideProgressBar: true,
        autoClose: true,
      });
    } else {
      this.setState({ isSurveyModalOpen: true });
    }
  };

  render() {
    let disableBtn = false;

    const zoomlevel = sessionStorage.getItem('ZoomLevel')
      ? sessionStorage.getItem('ZoomLevel')
      : '';

    if (zoomlevel < 10) {
      disableBtn = true;
    } else {
      disableBtn = false;
    }
    const modalOpen = false;
    const res_user = JSON.parse(sessionStorage.getItem('USER_DATA'));

    return (
      <div className="surveyDiv">
        <div className="tooltip2">
          <button
            className="button primary"
            id="checkout-button"
            disabled={!this.props.IsRequestButtonEnabled}
            onClick={this.showSurveyModal}
          >
            Request survey for this area
          </button>

          {disableBtn && <span className="tooltiptext2">Zoom in to request a survey</span>}
        </div>

        {this.state.isSurveyModalOpen && (
          <PopupModal open={this.state.isSurveyModalOpen} onClose={this.closeSurveyModal}>
            <RequestSurveyModal
              close={this.closeSurveyModal}
              centerData={this.props.centerData}
              providerdata={this.props.providerdata}
            />
          </PopupModal>
        )}
      </div>
    );
  }
}
