import axios from 'axios';

import { APPLICATION_HEADERS, API_URLS } from '../../common/headers';
import { sortAlphabeticalStrings } from '../../Utils/Utils';

export class SearchService {
  static async getCountries(region) {
    const url = `${API_URLS.getCountriesURL}?region=` + region;

    const headers = APPLICATION_HEADERS.agileBaseAPIKey;

    return axios.get(url, { headers });
  }

  static generateSearchDataObject = (region, data, bread = '') => {
    const res = {
      label: region,
      children: [],
      bread: !bread ? region : `${bread} > ${region}`,
    };

    if (!data) return res;

    if (Array.isArray(data)) {
      res.children = data
        .sort()
        .map((_value) => ({ label: _value, bread: `${res.bread} > ${_value}` }));
    } else if (typeof data === 'object') {
      res.children = Object.entries(data).map(([_key, _value]) => {
        return this.generateSearchDataObject(_key, _value, res.bread);
      });
    }

    return res;
  };

  // static downloadLocationsBasedOnSearch=useCallback(async (reqObj)=>{

  //     const URL = `${API_URLS.searchLocationsCountURL}`;
  //     const headers = { 'Content-Type': 'application/json' };

  //     try {
  //       const response = await axios.post(URL, reqObj, headers);

  //       if (response?.status === 200 && response?.data && response?.data?.count) {
  //         const { count } = response.data;

  //         return count;
  //       } else {
  //         console.error('Error: Count is not found in the response');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //     }
  //   }, []);

  // }
}
