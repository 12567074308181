import React, { Component } from 'react';

import './currentLocation.css';
import CurrentLocationIcon from '../core/icons/CurrentLocationIcon';
import MaterialCloseIcon from '../core/MaterialIcons/MaterialCloseIcon';

export default class currentLocation extends Component {
  handleRemoveCurrentLoc = () => {
    sessionStorage.removeItem('CURRENT_LOCATION');
    sessionStorage.removeItem('CURRENT_LOC_COORDINATES');
    sessionStorage.removeItem('ZoomLevel');
    const locationDiv = document.getElementById('displayLocation');

    locationDiv.style.display = 'none';
    const clientDiv = document.getElementById('addClientBtn');

    if (clientDiv) {
      clientDiv.style.display = 'flex';
      clientDiv.style.opacity = 1;
    }
    const el = document.querySelector('.marker');

    if (el) el.remove();
  };

  getPlaceNameAndUrl = () => {
    const currentLocation = sessionStorage.getItem('CURRENT_LOCATION');
    const zoomLevel = sessionStorage.getItem('ZoomLevel');
    const coordinates = sessionStorage.getItem('CURRENT_LOC_COORDINATES');
    let url = '#';
    let placeName = '';

    if (currentLocation) {
      placeName =
        currentLocation.length > 20 ? currentLocation.substring(0, 20) + '...' : currentLocation;
    }

    if (coordinates) {
      const coords = coordinates.split(',');

      url = '/locations/place/' + coords[0] + '/' + coords[1] + '/' + zoomLevel;
    }

    return [placeName, url];
  };

  render() {
    const [placeName, url] = this.getPlaceNameAndUrl();

    if (!placeName) return '';

    const locationDiv = document.getElementById('displayLocation');

    if (locationDiv) locationDiv.style.display = 'flex';

    return (
      <div className="locationDisplay" id="displayLocation">
        <div className="current-location__icon">
          <CurrentLocationIcon />
        </div>
        <a href={url} className="location">
          <h4 className="currentLocation">Current Location</h4>
          <div className="placeName">{placeName}</div>
        </a>
        <div
          className="locationClose"
          data-testid="current-location__close"
          onClick={this.handleRemoveCurrentLoc}
        >
          <MaterialCloseIcon />
        </div>
      </div>
    );
  }
}
