import React, { Component } from 'react';

import { withOktaAuth } from '@okta/okta-react';

import MapLocations from './../components/Locations/MapLocations';
import Layout from './Layout/Layout';
import MapLeftBar from './MapLeftBar/MapLeftBar';
export class MapBoxDisplay extends Component {
  state = {
    providerdata: [],
    pricedata: [],
    searchData: {},
    centerData: [],
    loaded: false,
    locData: null,
    region: [],
    hoveredProvider: null,
  };

  setProviderData = (data) => {
    this.setState({ providerdata: data.data });
  };

  setHoveredProvider = (provider) => {
    this.setState({ hoveredProvider: provider });
  };

  setSearchData = (data) => {
    this.setState({ searchData: data });
  };

  setCenterData = (data) => {
    this.setState({ centerData: data });
  };

  setloaded = () => {
    this.setState({ loaded: true });
  };

  setPriceData = (data) => {
    this.setState({ pricedata: data });
  };

  setRegion = (data) => {
    this.setState({ region: data });
  };

  setLocData = (data) => {
    this.setState({ locData: data });
  };

  render() {
    const parameters = {
      lat: this.props.match.params.lat,
      lng: this.props.match.params.lng,
      zoomlevel: this.props.match.params.zoom,
    };

    console.log(this.state.hoveredProvider, 'hovered');

    return (
      <>
        <MapLeftBar
          resppricedata={this.state.pricedata}
          providerdata={this.state.providerdata}
          centerData={this.state.centerData}
          searchdata={this.state.searchData}
          setHoveredProvider={this.setHoveredProvider}
          loaded={this.state.loaded}
          region={this.state.region}
        />
        <MapLocations
          priceData={this.setPriceData}
          pData={this.setProviderData}
          centerData={this.setCenterData}
          searchdata={this.setSearchData}
          isloaded={this.setloaded}
          locData={this.setLocData}
          URLData={parameters}
          region={this.setRegion}
          hoveredProvider={this.state.hoveredProvider}
        />
      </>
    );
  }
}

export default withOktaAuth(MapBoxDisplay);
