import _ from 'lodash';

import regionObject from '../Locations/region-list.json';

export const sortSurveysByDealId = (records, order) => {
  if (order === 'desc') {
    return records.sort((a, b) => {
      return b.fields.DealID - a.fields.DealID;
    });
  }
  if (order === 'asc') {
    return records.sort((a, b) => {
      return a.fields.DealID - b.fields.DealID;
    });
  }
};

export const formatDate = (dt) => {
  if (dt) {
    const date = new Date(dt);
    const formattedDate =
      date.toLocaleDateString('en-US', { day: '2-digit', timeZone: 'UTC' }) +
      '-' +
      date.toLocaleDateString('en-US', { month: 'short', timeZone: 'UTC' }) +
      '-' +
      date.toLocaleDateString('en-US', { year: 'numeric', timeZone: 'UTC' });

    return formattedDate;
  }
};

export const getKeysByValue = (value) => {
  const data = regionObject;
  const dataKeys = Object.keys(data);

  return dataKeys.filter((currKey) => {
    //if region value contrycode which is length 2 adding that cond otherwise region is cityname checking with includes.
    for (const element of data[currKey])
      if (
        element.country_code_2 === value ||
        (value.length > 2 && _.includes(element.country_name, value))
      )
        return true;
  });
};

export const getAnalyticsYears = () => {
  let analyticYear = 2020;
  const currentYear = new Date().getFullYear(); // type number as default
  const analyticsYears = [];

  // if analytic first year is 2019 and current year 2023, result => ['2019', '2020', '2021', '2022', '2023']
  while (analyticYear <= currentYear) {
    analyticsYears.push(String(analyticYear));
    analyticYear += 1;
  }

  return analyticsYears;
};

export const sortAlphabeticalStrings = (name, array) =>
  array && array?.length > 0 && array?.sort((a, b) => a[name].localeCompare(b[name]));

export const checkValidImageURL = (url) => {
  return url?.replace(/^,|,$/g, '').match(/\.(jpeg|jpg|gif|png|tiff)$/) != null;
};

export const calculateAnalytics = (data) => {
  let sum = 0;
  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);
  let keysLength = 0;

  if (!Object.values(data).length) return 0;

  for (const [key, value] of Object.entries(data)) {
    sum = sum + sumValues(value);
    keysLength = keysLength + Object.keys(value).length;
  }

  const count = Math.round(sum / keysLength);

  return count;
};

export const sumOfObjectValues = (data) => {
  return Object.values(data).reduce((a, b) => a + b, 0);
};

export const strToArray = (str) => {
  if (!str) {
    return str;
  }
  let newArray = new Array();

  newArray = str.split(',').map((item) => item?.trim());

  return newArray;
};

export const stringWithSpaces = (inputString) => {
  if (!inputString) {
    return inputString;
  }

  return inputString
    .split(',')
    .map((str) => str.trim())
    .join(', ');
};
