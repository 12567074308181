import React from 'react';

import classes from './MySurveysPage.module.css';

function Card(props) {
  return (
    <div className={`${classes.card} ${props.extraClass}`} onClick={props.clickHandle}>
      <div className={classes.quantity}>{props.quantity}</div>

      <div className={classes.title}>{props.title}</div>
    </div>
  );
}

export default Card;
