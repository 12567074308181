import React, { useState, useEffect, useRef } from 'react';

import { Carousel } from 'react-carousel-minimal';
import styled from 'styled-components';

import ModalScreen from '../../common/Modal';
import { Block } from '../../common/StyledComponents/StyledBlock';

import './Slider.css';

const ImageDisplayModal = ({ isOpen, onClose, label, locationImages, imageIndex }) => {
  const carouselRef = useRef(null);

  const getImages = (images) => {
    return images?.map((image) => ({
      image: image,
      caption: '',
    }));
  };

  const onCloseImageModel = () => {
    onClose();
  };

  const images = getImages(locationImages);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (carouselRef.current) {
        const dots = carouselRef.current.querySelectorAll('.dots');
        const dotsArray = dots[0].children;

        if (dotsArray && dotsArray[imageIndex]) {
          dotsArray[imageIndex].click();
        }
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [isOpen, imageIndex]);

  return (
    <>
      <ModalScreen isOpen={isOpen} onClose={onCloseImageModel} label={label}>
        <Container>
          <Block display="flex" flexDirection="column" gap="30">
            <Block display="flex" justifyContent="space-between">
              <Title>Location Images</Title>
            </Block>

            <Block width={400} height={300} ref={carouselRef}>
              {images?.length > 0 && (
                <Carousel
                  width="400px"
                  height="300px"
                  data={images}
                  showNavBtn={images.length >= 2}
                  dots
                  slideImageFit="object-fit"
                  style={{
                    minWidth: '400px',
                    minHeight: '300px',
                  }}
                />
              )}
            </Block>
          </Block>
        </Container>
      </ModalScreen>
    </>
  );
};

export default ImageDisplayModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & .carousel-item {
    min-width: 400px;
    min-height: 300px;
  }
  & a,
  a:hover {
    underline: none;
    text-decoration: none;
  }
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
`;
const Subtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
`;
