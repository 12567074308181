import React from 'react';

import classes from './AnalyticsPage.module.css';

function Card(props) {
  return (
    <div className={`${classes.card} ${props.extraClass}`} onClick={props.clickHandle}>
      <div className={classes.image}>
        <img src={props.icon} width="20" height="20" />

        <div className={classes.quantity}>
          {props.quantity}
          {props.measure}
        </div>
        {props.analyticsTitle === 'Avg. Survey Completion Time' && (
          <div className={classes.subTitle}>(Business Days)</div>
        )}
      </div>
      <div className={classes.stitle}>
        {/* <div className={classes.subTitle}>{props.subText}</div> */}
        {props.title}
      </div>
    </div>
  );
}

export default Card;
