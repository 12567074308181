import { useEffect, useState } from 'react';

import Chart from 'react-google-charts';

import { ContactSupport } from '@material-ui/icons';
import './ProviderChart.css';
const ProviderChart = (props) => {
  const { dealSizeData } = props;
  const [chartData, setChartData] = useState([]);

  const creaCustomHTML = (title, deskCount, avg) => {
    return (
      '<div style="padding:10px"><b>' +
      title +
      '</b><br/><table style="margin-top:10px; width:100px"><tr><td>Total : </td><td style="text-align:right"><b>' +
      deskCount +
      '</b><td/><tr/>' +
      '<tr><td>Average : </td><td style="text-align:right"><b>' +
      avg +
      '</b><td/><tr/></div>'
    );
  };

  useEffect(() => {
    if (dealSizeData) {
      const { NoOfSmallDeals, NoOfMediumDeals, NoOfLargeDeals, ...noOfDeals } = dealSizeData;
      const dealsData = [];

      for (const key in noOfDeals) {
        const value = noOfDeals[key];

        if (key === 'Small') {
          dealsData.push([
            'Small (1-10 desks)',
            value,
            creaCustomHTML('Desk Count', value, (value / NoOfSmallDeals).toFixed(0)),
          ]);
        }

        if (key === 'Medium') {
          dealsData.push([
            'Medium (11-49 desks)',
            value,
            creaCustomHTML('Desk Count', value, (value / NoOfMediumDeals).toFixed(0)),
          ]);
        }
        if (key === 'Large') {
          dealsData.push([
            'Large (>= 50 desks)',
            value,
            creaCustomHTML('Desk Count', value, (value / NoOfLargeDeals).toFixed(0)),
          ]);
        }
      }

      const charDatafromAPI = dealsData.sort().reverse();
      const chartData = [
        ['Desk Count', 'Desk Count', { type: 'string', role: 'tooltip', p: { html: true } }],
        ...charDatafromAPI,
      ];

      setChartData(chartData);
    }
  }, [dealSizeData]);

  return (
    <div className="chart-container">
      <h4 className="chart-title">Size-wise desk count</h4>
      <div className="dealchart">
        <Chart
          chartType="ColumnChart"
          containerId="chart1"
          loader={<div>Loading Chart</div>}
          data={chartData}
          width="98%"
          height="100%"
          options={{
            legend: { position: 'bottom' },
            bubble: { textStyle: { fontSize: 11 } },
            tooltip: { isHtml: true },
            colors: ['#3E7CA6'],
          }}
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({ chartWrapper, controlWrapper, google }) => {
                const svg = document.querySelector('svg');
                const styles =
                  'text[text-anchor="middle"] { cursor: pointer; background:#ccc; padding:20px }';
                const css = document.createElement('style');

                if (css.styleSheet) {
                  css.styleSheet.cssText = styles;
                } else {
                  css.appendChild(document.createTextNode(styles));
                }

                svg.appendChild(css);

                const chart = chartWrapper.getChart();

                const observer = new MutationObserver(function () {
                  Array.prototype.forEach.call(
                    chart.container.getElementsByTagName('text'),
                    function (annotation) {
                      if (
                        annotation.getAttribute('text-anchor') === 'middle' &&
                        annotation.getAttribute('fill') === '#ffffff'
                      ) {
                        const chartLayout = chart.getChartLayoutInterface();

                        annotation.setAttribute(
                          'y',
                          chartLayout.getYLocation(0) -
                            parseInt(annotation.getAttribute('font-size')),
                        );
                      }
                    },
                  );
                });

                observer.observe(chart.container, {
                  childList: true,
                  subtree: true,
                });

                const handler = function (e) {
                  const parts = e.targetID.split('#');

                  if (parts.indexOf('label') >= 0) {
                    let idx = parts[parts.indexOf('label') + 1];

                    idx = parseInt(idx);
                  }
                };

                google.visualization.events.addListener(chartWrapper.getChart(), 'click', handler);
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ProviderChart;
