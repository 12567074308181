import React from 'react';

import { useHistory } from 'react-router-dom';

import { LoginCallback, useOktaAuth } from '@okta/okta-react';

const CustomLoginCallback = () => {
  const [isMounted, setIsMounted] = React.useState(false);
  const { authState } = useOktaAuth();
  const history = useHistory();

  React.useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);

      return;
    }

    if (typeof authState?.isAuthenticated !== 'undefined' && !authState?.isAuthenticated) {
      history.push('/okta');
    }

    if (typeof authState?.isAuthenticated === 'undefined') {
      history.push('/okta');
    }
  }, [authState?.isAuthenticated]);

  return <LoginCallback />;
};

export default CustomLoginCallback;
