import React, { useState } from 'react';

import axios from 'axios';

import successIcon from '../../assets/images/successIcon.svg';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';

const ThankyouModal = (props) => {
  const saveToAirtable = (id) => {
    const headers = APPLICATION_HEADERS.clientDeliverablesAPIKey;

    const URL = API_URLS.updateDealURL;
    const updateObj = {
      dealId: id,
      locationUploadStatus: 'Not Applicable',
    };

    axios
      .put(URL, updateObj, { headers })
      .then((res) => {
        if (res && res.status === 200) {
          console.log('Deal update success');
        }
      })
      .catch((error) => {
        console.error('Error during update deal information', error);
      });
  };

  saveToAirtable(props.id);

  return (
    <div className="successModal">
      <div className="successMsg1">
        <img src={successIcon} width="25"></img>
        <span className="thankyouMsg">Thank you</span>
      </div>
      <div className="text-center">
        Flexpert Support team has been notified to start the survey.
      </div>
    </div>
  );
};

export default ThankyouModal;
