import React, { useContext, useEffect, useState } from 'react';

import { LoadScript } from '@react-google-maps/api';

import { ProviderLocationsContext, loadState } from '../../store/ProviderLocationsContext';
import Layout from '../Layout/ProviderLayout';
import NewOptionModal from '../ProviderLocations/NewLocation/AddLocation';

const AddLocations = () => {
  const { state } = useContext(ProviderLocationsContext);
  const [initialState, setinitialState] = useState({});
  const teamType = JSON.parse(sessionStorage.getItem('TEAM_TYPE'));
  const providerInfoObj = JSON.parse(sessionStorage.getItem('USER_DATA'));

  useEffect(() => {
    const storedState = loadState();

    setinitialState(storedState);
  }, []);

  const closeNewLocation = (count) => {};

  return (
    <Layout>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={['places']}
        loadingElement={<div style={{ height: `1%` }} />}
      >
        {initialState &&
          initialState?.majorProvidersList &&
          initialState?.majorProvidersList?.length > 0 && (
            <NewOptionModal
              providerName={providerInfoObj['Team Name']}
              userType={teamType}
              majorProvidersList={initialState.majorProvidersList}
              onClose={closeNewLocation}
            />
          )}
      </LoadScript>
    </Layout>
  );
};

export default AddLocations;
