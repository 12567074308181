import React, { useContext, useState, useRef, useCallback, useEffect, useMemo } from 'react';

import moment from 'moment';
import styled from 'styled-components';

import closeIcon from '../../../assets/images/provider_locations/close.svg';
import { PrimaryButton, SecondaryButton } from '../../common/Button';
import { Block } from '../../common/StyledComponents/StyledBlock';
import { strToArray, stringWithSpaces } from '../../Utils/Utils';
import ImageDisplayModal from '../Slider/ImageSlider';

const ViewLocationModal = ({ onClose, currentRow, editLocationFromView }) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);

  const onCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const onClickImageSlider = (index) => {
    setIsImageModalOpen(true);
    setClickedImageIndex(index);
  };

  const handleCloseWindow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClose();
  };

  const onClickedEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    editLocationFromView(true);
  };

  const viewImagesCB = useCallback(
    (images) => {
      const locationImages = strToArray(images);

      if (locationImages?.length > 0) {
        return (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '15px' }}>
              {locationImages?.map((img, index) => {
                {
                  if (img) {
                    return (
                      <div
                        key={index}
                        onClick={() => onClickImageSlider(index)}
                        style={{ width: '144px', height: '96px', cursor: 'pointer' }}
                      >
                        <img src={img} style={{ width: '100%', height: '100%' }} />
                      </div>
                    );
                  }
                }
              })}
            </div>
            {isImageModalOpen && (
              <ImageDisplayModal
                locationImages={locationImages}
                isOpen={isImageModalOpen}
                onClose={onCloseImageModal}
                imageIndex={clickedImageIndex}
              />
            )}
          </>
        );
      }
    },
    [clickedImageIndex, isImageModalOpen],
  );

  return (
    <Block display="flex" flexDirection="column" gap="30">
      <DetailsBlockContainer>
        <DetailsBlockLeft>
          <OptionTitleBlock>
            <OptionTitle>Properties Details</OptionTitle>
          </OptionTitleBlock>

          <OptionChildRowContainer>
            <DetailsSubBlock>
              <SubContent>Provider Name:</SubContent>
              <SubContent>{currentRow?.provider_name}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Email:</SubContent>
              <SubContent>{currentRow?.provider_contact_info[0]?.email}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Address:</SubContent>
              <SubContent>{currentRow?.formatted_address}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Region:</SubContent>
              <SubContent>{currentRow?.region}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Provider ID:</SubContent>
              <SubContent>{currentRow?.provider_id}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Created Date:</SubContent>
              <SubContent>{currentRow?.provider_created_date}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Last Updated:</SubContent>
              <SubContent>{currentRow?.provider_updated_date}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Created By:</SubContent>
              <SubContent>{currentRow?.createdBy}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Last Modified By:</SubContent>
              <SubContent>{currentRow?.updatedBy}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Source:</SubContent>
              <SubContent>{currentRow?.source_system}</SubContent>
            </DetailsSubBlock>
          </OptionChildRowContainer>

          <OptionTitleBlock style={{ marginTop: '25px' }}>
            <OptionTitle>Additional Info</OptionTitle>
          </OptionTitleBlock>

          <OptionChildRowContainer>
            <DetailsSubBlock>
              <SubContent>Currency:</SubContent>
              <SubContent>{currentRow?.floor_pricing?.pricing_currency}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Price Per Month:</SubContent>
              <SubContent>{currentRow?.floor_pricing?.pricing_1_month_term}</SubContent>
            </DetailsSubBlock>

            <DetailsSubBlock>
              <SubContent>Available Date:</SubContent>
              {currentRow?.availabilityDate && (
                <SubContent>
                  {moment(currentRow?.availabilityDate).format('MMM DD, YYYY')}
                </SubContent>
              )}
            </DetailsSubBlock>
          </OptionChildRowContainer>

          <DetailsSubBlock>
            <SubContent>Amenities:</SubContent>
            <SubContent>{stringWithSpaces(currentRow?.floor_amenities)}</SubContent>
          </DetailsSubBlock>

          <DetailsSubBlock>
            <SubContent>Images:</SubContent>
          </DetailsSubBlock>

          <DetailsSubBlock style={{ marginBottom: '30px' }}>
            <div>{viewImagesCB(currentRow?.picture_path)}</div>
          </DetailsSubBlock>
        </DetailsBlockLeft>
        <DetailsBlockRight>
          <ClosedModalButton className="close-modal-sign" onClick={handleCloseWindow}>
            <img src={closeIcon} />
          </ClosedModalButton>
          <PrimaryButton
            style={{ margin: '20px 0 0', paddingLeft: '40px', paddingRight: '40px' }}
            onClick={onClickedEdit}
          >
            Edit
          </PrimaryButton>
        </DetailsBlockRight>
      </DetailsBlockContainer>
    </Block>
  );
};

const DetailsBlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 130px;
  grid-gap: 15px;
  width: 100%;
  box-sizing: border-box;
`;

const DetailsBlockLeft = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const OptionChildRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  box-sizing: border-box;
  gap: 15px;
`;

const DetailsBlockRight = styled.div`
  // width: 130px;
  display: grid;
  justify-content: right;
  justify-self: center;
  align-items: flex-start;
  align-content: flex-start;
  & .close-modal-sign {
    display: grid;
    justify-content: right;
  }
`;

const DetailsSubBlock = styled.div``;

const SubContent = styled.div`
  font-family: Calibre-R;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #1a1a1a;
  &:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
  }
`;

const OptionTitleBlock = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
`;

const OptionTitle = styled.div`
  color: #1a1a1a;
  font-family: Calibre-R;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const ClosedModalButton = styled.div`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;

export default ViewLocationModal;
