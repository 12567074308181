import React, { useEffect, useRef } from 'react';

import {
  Accordion,
  makeStyles,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Backdrop,
} from '@material-ui/core';

import useCounter from '../../../hooks/Couters';
import useWindowDimensions from '../../../hooks/windowDimensions';
import MaterialChevronRightIcon from '../../core/MaterialIcons/MaterialChevronRightIcon';
import VideoTutorial from '../../VideoTutorial/VideoTutorial';

import './Accordion.css';

const useStyles = makeStyles((theme, a) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    top: '49px',
    right: '0px',
    background: '#FFFFFF',
    padding: '8px',
    gap: '8px',
    overflow: 'auto',
    letterSpacing: '-0.01em',
  },

  accordionSummary: {
    flexDirection: 'row-reverse!important',
    padding: '0px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  gutters: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AccordionCommon = (props) => {
  const { demandHelpViewCounter, transactionHelpViewCounter, uploadFileHelpViewCounter } =
    useCounter();
  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const [temp, setTemp] = React.useState(1);
  const [demandTemp, setDemandTemp] = React.useState(1);
  const [transactionTemp, setTransactionTemp] = React.useState(1);
  const accordionRef = useRef(null);

  useEffect(() => {
    if (accordionRef.current !== null) {
      accordionRef.current.style.maxHeight = height - 150;
    }
  }, [height]);

  return (
    <div ref={accordionRef} className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<MaterialChevronRightIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.heading}>Locations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <VideoTutorial
              url="https://scribehow.com/embed/Inventory_Management_Portal__AX-QdisxQI-MTo1pJmUTuw"
              width={width / 2}
              height={height - 268}
            ></VideoTutorial>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<MaterialChevronRightIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.heading}>Add Single Location</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <VideoTutorial
              url="https://scribehow.com/shared/How_to_Add_a_Single_Location_with_Provider_Details__Y_VxqeEATza-VvRuPF4wig"
              width={width / 2}
              height={height - 268}
            ></VideoTutorial>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={(e) => {
          setTemp(temp + 1);
          if (temp % 2 !== 0) uploadFileHelpViewCounter(e);
        }}
      >
        <AccordionSummary
          expandIcon={<MaterialChevronRightIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.heading}>Upload Tool</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <VideoTutorial
              url="https://scribehow.com/embed/Cbre_Workflow__dM8NFEwfTc6IteBuid9tLg"
              width={width / 2}
              height={height - 268}
            ></VideoTutorial>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={(e) => {
          setDemandTemp(demandTemp + 1);
          if (demandTemp % 2 !== 0) demandHelpViewCounter(e);
        }}
      >
        <AccordionSummary
          expandIcon={<MaterialChevronRightIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.heading}>Inquiries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <VideoTutorial
              url="https://scribehow.com/embed/Cbre_Demand_Help_Tutorial__h4T-0xN1S2idPyiggsJRVQ"
              width={width / 2}
              height={height - 268}
            ></VideoTutorial>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={(e) => {
          setTransactionTemp(transactionTemp + 1);
          if (transactionTemp % 2 !== 0) transactionHelpViewCounter(e);
        }}
      >
        <AccordionSummary
          expandIcon={<MaterialChevronRightIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.heading}>Transactions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <VideoTutorial
              url="https://scribehow.com/embed/Cbre_Transactions_Tutorial__kU9y5OdyQFuevqLNiJlGqA"
              width={width / 2}
              height={height - 268}
            ></VideoTutorial>
          </Container>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionCommon;
