import React, { useState } from 'react';

import axios from 'axios';
import { useLocation } from 'react-router-dom';

import providerTemplate from './../../assets/Files/ProviderUploadFileTemplate_v2.xlsx';
import history from './../../history';
import FileUpload from './FileUpload';
import './ProviderFileUpload.css';
import ThankyouModal from './ThankyouModal';

import { isValidEmail } from '../../utils/isEmailValid';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import { TextField } from '../common/TextField/TextField';
import Layout from '../Layout/ProviderLayout';

const ProviderFileUpload = (props) => {
  const [providerEmail, setProviderEmail] = React.useState();
  const [emailError, setEmailError] = useState('');
  let isProviderUser = false;

  let dealID = '';
  const loc = useLocation();

  if (loc.pathname == '/uploadFile' && loc.search === '' && !sessionStorage.getItem('USER_DATA')) {
    sessionStorage.setItem('ISLOGGEDIN', false);
    sessionStorage.removeItem('result');
    sessionStorage.removeItem('prevPath');
    history.push('/MKII/');
    window.location.reload();
  }
  const query = new URLSearchParams(useLocation().search);

  if (query) {
    dealID = query.get('id');
    const uid = query.get('uniqueid');
    const uploadNeeded = query.get('uploadNeeded');

    if (uploadNeeded === 'NA') {
      return <ThankyouModal id={dealID} />;
    }
    // axios call to verify dealID and uniqueID

    const headers = APPLICATION_HEADERS.mkiiAPIKey;

    if (dealID) {
      const url = `${API_URLS.getDealURL.trimEnd()}?dealId=` + dealID;

      axios
        .get(url, { headers })
        .then((res) => {
          const logout = () => {
            sessionStorage.setItem('ISLOGGEDIN', false);
            sessionStorage.removeItem('result');
            sessionStorage.removeItem('prevPath');
            history.push('/MKII/');
            window.location.reload();
          };

          if (res && res.data && res.data.records.length > 0) {
            const unique_id = res.data.records[0].fields['Unique id'];

            if (unique_id === parseInt(uid)) {
              isProviderUser = true;
              sessionStorage.setItem('result', JSON.stringify(res));
              sessionStorage.setItem('TEAM_TYPE', 'Provider');
              sessionStorage.setItem('ISLOGGEDIN', true);
            } else {
              logout();
            }
          } else {
            logout();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  const onEmailChange = (event) => {
    setEmailError();
    const email = event.target.value;

    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address');
      setProviderEmail('');
    }

    email && setProviderEmail(email);
  };

  return (
    <div>
      <Layout>
        <div className="fileContainer">
          <div className="uploadPage">
            <h4 className="titletext">Upload Locations</h4>
            <div className="field emailfield">
              <TextField
                id="outlined-basic"
                label="Your email address (required)"
                variant="outlined"
                onBlur={onEmailChange}
                fullWidth
                helperText={
                  emailError ||
                  'We will use this email only to communicate status of the file upload'
                }
                error={!!emailError}
              />
            </div>

            <div className="fileUploadContainer">
              <FileUpload providerEmail={providerEmail} dealID={dealID} />
            </div>
          </div>

          <div className="verticalLine">
            <h4 className="head2">Instructions</h4>
            <p>
              Step 1: Download the provided &nbsp;
              <a href={providerTemplate} download="ProviderUploadFileTemplate_v2.xlsx">
                template
              </a>
              .
            </p>
            <p>Step 2: Complete the template with the necessary data and save it. </p>
            <ol type="1">
              <li>Ensure that the following mandatory fields are not left blank: </li>
              <ol type="a">
                <li>Provider Name</li>
                <li>Address</li>
                <li>City</li>
                <li>Country</li>
                <li className="d-block">
                  When entering country names, please use the following standardized formats. i.e.
                </li>
                <ul className="type-circle">
                  <li>United States: Use "United States" instead of "USA"</li>
                  <li>United Kingdom: Use "United Kingdom" instead of "UK"</li>
                  <li>Canada: Use "Canada"</li>
                  <li>Australia: Use "Australia"</li>
                  <li>Germany: Use "Germany"</li>
                </ul>
                <li className="d-block">
                  For countries not listed here, use the full official country name.
                </li>
                <li>Provider</li>
                <li>Contact Email.</li>
              </ol>
              <li>
                Each amenity has a corresponding column; indicate availability by marking "Yes".
              </li>
              <li>Image Upload </li>
              <ul className="type-circle">
                <li>Single Location Upload</li>
                <ul>
                  <li>
                    Use the Single Location Upload if you are uploading images from a local drive.
                  </li>
                </ul>
                <li>Multi Location Upload</li>
                <ul>
                  <li>
                    The Multi Location Upload functionality currently only supports images from
                    Public URLs.
                  </li>
                </ul>
              </ul>
              <li>Include the "Available date" in YYYY-MM-DD format.</li>
              <li>The file should contain no more than 1000 rows.</li>
            </ol>
            <p>Step 3: Enter your email address on the screen.</p>
            <p>
              Step 4: Upload the completed file. You'll receive an email notification once the
              process is finished.
            </p>
            <h4 className="head2">Duplicate check (Override):</h4>
            <ol>
              <li>Records are matched and deduplicated based on the following criteria:</li>
              <ul className="type-circle">
                <li>Provider Name</li>
                <li>Location Coordinates</li>
                <li className="d-block">
                  Ensure that both the provider’s name and location coordinates are accurate to
                  facilitate proper duplicate checking.
                </li>
              </ul>
              <li>
                Mandatory attributes (address, city, country) will be replaced by values in the
                upload file during the override process.{' '}
              </li>
              <li>
                Optional attributes (zip, state, currency, price per month, availability date,
                region, email, amenities) will also be replaced.{' '}
              </li>
              <li>Records that have blank attribute values will not be updated.</li>
              <li>
                Additionally, optional attribute(images) will be appended during the override
                process.{' '}
              </li>
              <li>
                If the existing record contains values that the new record does not, those fields
                will remain unchanged. For instance, if the old record has 10 field values and the
                new one has 8 fields, the updated record will retain 10 fields.
              </li>
            </ol>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ProviderFileUpload;
