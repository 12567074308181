import { useState, useEffect, useRef } from 'react';

import useWindowDimensions from '../../hooks/windowDimensions';
import LoadingSpinner from '../MySurveys/LoadingSpinner';
import './VideoTutorial.css';

const VideoTutorial = (props) => {
  const ref = useRef();
  const [loading, stillLoading] = useState(true);
  const { height, width } = useWindowDimensions();
  const iframeClass = loading ? 'iframe' : 'iframe_afterload';
  const hideSpinner = () => {
    stillLoading(false);
  };

  return (
    <>
      <div ref={ref} className="center">
        {loading && <LoadingSpinner></LoadingSpinner>}
        <iframe
          className={iframeClass}
          title="CBRE Workflow"
          src={props.url}
          width={props.width ?? width / 2}
          height={props.height ?? height - 100}
          onLoad={hideSpinner}
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
};

export default VideoTutorial;
