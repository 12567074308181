import React, { useState } from 'react';

import NewOptionModal from '../NewLocation/AddLocation';
import ModalScreen from '../NewLocation/Modal';
import ViewLocationModal from '../ViewLocation';

const EditLocationModal = ({
  isOpen,
  onClose,
  label,
  item,
  providerName,
  userType,
  majorProvidersList,
  isEditOption,
  isOnlyViewOption,
  onSuccessEditLocation,
  onSuccessEditLocationAlreadyExists,
  onClickedEditFromView,
}) => {
  const editLocationFromView = (e) => {
    onClickedEditFromView(e);
  };

  return (
    <>
      <ModalScreen
        isOpen={isOpen}
        onClose={onClose}
        label={label}
        widthProps={{ width: '80%' }}
        withoutCloseBtn
      >
        {!!isEditOption && (
          <NewOptionModal
            isOpen={isOpen}
            providerName={providerName}
            userType={userType}
            majorProvidersList={majorProvidersList}
            onClose={onClose}
            currentRow={item}
            isEditOption
            onSuccessEditLocation={onSuccessEditLocation}
            onSuccessEditLocationAlreadyExists={onSuccessEditLocationAlreadyExists}
          />
        )}

        {!!isOnlyViewOption && (
          <ViewLocationModal
            isOpen={isOpen}
            currentRow={item}
            onClose={onClose}
            label={label}
            editLocationFromView={editLocationFromView}
          />
        )}
      </ModalScreen>
    </>
  );
};

export default EditLocationModal;
