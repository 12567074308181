import React from 'react';

import styled from 'styled-components';

import deleteIcon from '../../../assets/images/provider_locations/deleteIcon.svg';

const UploadedImageBlock = styled.div`
  position: relative;
  width: 144px;
  height: 96px;
  margin-bottom: 40px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border: solid 1px #ccc;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ImageOverlayContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;

  ${ImageContainer}:hover & {
    opacity: 1;
  }
`;

const ImageURL = styled.div`
  margin-right: 8px;
  font-size: 12px;
  font-weight: normal;
  color: #000;
  display: flex;
  text-align: center;
  align-items: center;
  word-break: break-all;
  width: 80%;
  height: 100%;
`;

const DeleteIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3px;
`;

const LoadingBar = styled.div`
  width: 100%;
  height: 4px;
  background: ${({ background }) => background || '#cad1d3'};
  position: relative;
  margin-top: 8px;
`;

const ProgressBar = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  background: ${({ isCompleted }) => (isCompleted ? '#50AF78' : '#003f2d')};
  animation: loading 250ms ease-in-out;

  @keyframes loading {
    0% {
      width: 0%;
    }
    100% {
      width: ${({ uploadingWidth }) => uploadingWidth}%;
    }
  }
`;

const Progress = styled.div`
  font-weight: 400;
  font-size: 11px;
  margin-top: 7px;
  line-height: 16px;
  color: rgba(26, 26, 26, 0.6);
`;

function areEqual(prevProps, nextProps) {
  return (
    prevProps.index === nextProps.index &&
    prevProps.isHoveredImage === nextProps.isHoveredImage &&
    prevProps.image.url === nextProps.image.url &&
    prevProps.progressImageUpload === nextProps.progressImageUpload &&
    prevProps.image.isCompleted === nextProps.image.isCompleted
  );
}

// eslint-disable-next-line react/display-name
const UploadedImageBlockComponent = React.memo(
  ({
    index,
    image,
    isHoveredImage,
    handleMouseEnterUploadedImage,
    handleMouseLeaveUploadedImage,
    handleDeleteImage,
    progressImageUpload,
    handleImageClick,
  }) => {
    const imageURLPath = image?.imgURL;

    return (
      <UploadedImageBlock
        key={`${image?.url}-${index}`}
        onClick={(e) => handleImageClick(e, index)}
      >
        <ImageContainer
          onMouseEnter={() => handleMouseEnterUploadedImage(index)}
          onMouseLeave={() => handleMouseLeaveUploadedImage(index)}
          style={{ opacity: isHoveredImage ? 0.9 : 1 }}
        >
          <UploadedImage
            src={
              typeof image === 'object' && typeof image?.url === 'string'
                ? image?.url
                : URL.createObjectURL(image?.url)
            }
          />
          <ImageOverlay style={{ opacity: isHoveredImage ? 1 : 0 }}>
            <ImageOverlayContent>
              {image.isCompleted && (
                <>
                  <ImageURL>
                    {imageURLPath?.length > 50 ? imageURLPath.slice(0, 50) + '...' : imageURLPath}
                  </ImageURL>

                  <DeleteIcon
                    onClick={(e) => handleDeleteImage(e, index, imageURLPath)}
                    src={deleteIcon}
                    alt="Delete"
                  />
                </>
              )}
            </ImageOverlayContent>
          </ImageOverlay>
        </ImageContainer>
        <LoadingBar>
          <ProgressBar isCompleted={image.isCompleted} uploadingWidth={progressImageUpload} />
        </LoadingBar>
        <Progress>{image.isCompleted ? 'Completed' : `${progressImageUpload}%`}</Progress>
      </UploadedImageBlock>
    );
  },
  areEqual,
);

export default UploadedImageBlockComponent;
