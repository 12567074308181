import React from 'react';

import Modal from 'react-modal';

import closeIcon from '../../../../assets/images/closeIcon.svg';

import './modal.css';

const ModalScreen = ({ isOpen, onClose, children, label, withoutCloseBtn = false, widthProps }) => {
  const customStyles = {
    overlay: {
      position: 'fixed',
      inset: '0px',
      backgroundColor: '#232323c4',
      zIndex: 100,
    },
    content: {
      width: widthProps ? widthProps.width : 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '7px',
      maxHeight: '90vh',
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} contentLabel={label}>
      {!withoutCloseBtn && (
        <button className="modal-close" onClick={onClose}>
          <img src={closeIcon} />
        </button>
      )}
      {children}
    </Modal>
  );
};

export default ModalScreen;
