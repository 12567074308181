import React, { Fragment } from 'react';

import { useLocation } from 'react-router-dom';

import history from './../../history';

import LeftNavbar from '../LeftNavbar/ProviderLeftNavbar';
import Navbar from '../Navbar/Navbar';
import ProviderHeader from '../ProviderFileUpload/ProviderHeader';

function ProviderLayout(props) {
  return (
    <Fragment>
      <ProviderHeader />
      <div className="provider content">
        <LeftNavbar />
        <div className="provider rightbar">
          <div className="rightbarcontent">{props.children}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProviderLayout;
