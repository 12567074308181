import React, { useCallback, useContext, useEffect, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Collapse, IconButton } from '@mui/material';

import classes from './LeftNavbar.module.css';

import useCouter from '../../hooks/Couters';

const submenuItemsAddLocation = [
  { title: 'Single Location', path: '/addLocation/single' },
  { title: 'Multiple Locations', path: '/uploadFile' },
];

const mainMenuActiveCheck = (locationPath) =>
  submenuItemsAddLocation.some((loc) => loc.path === locationPath);

const pathFirstString = (paths) => {
  let strPath;

  if (!!paths && paths?.length > 0 && paths[0] !== '') {
    const path = paths[0];
    const extractedString = path.match(/\/([^/]+)/);

    if (extractedString && extractedString?.length > 1) {
      strPath = extractedString[1];
    }
  }

  return strPath;
};

const isCheckSameParent = (path1, path2) => {
  return pathFirstString(path1) === pathFirstString(path2);
};

function ProviderLeftNavbar() {
  const { uploadViewCounter, demandViewCounter, transactionViewCounter, inventoryPortalCounter } =
    useCouter();
  const teamType = JSON.parse(sessionStorage.getItem('TEAM_TYPE'));

  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
  const currentLocation = useLocation();

  useEffect(() => {
    const activeItem = submenuItemsAddLocation.find(
      (item) => item.path === currentLocation?.pathname,
    );

    setActiveSubMenuItem(activeItem);

    if (activeItem) {
      setSubMenuOpen(true);
    } else {
      setSubMenuOpen(false);
    }
  }, [currentLocation?.pathname]);

  const handleMenuOpen = () => {
    setSubMenuOpen((prev) => !prev);
  };

  const handleSubmenuItemClicked = useCallback(
    (item) => (e) => {
      if (activeSubMenuItem === item || isCheckSameParent(activeSubMenuItem?.path, item.path)) {
        return;
      }
      setSubMenuOpen(true);
      setActiveSubMenuItem(item);
      uploadViewCounter(e);
    },
    [activeSubMenuItem, uploadViewCounter],
  );

  return (
    <div className={classes.providerLeftBar}>
      <nav className={classes.nav}>
        <ul>
          <li title="Provider Locations">
            <NavLink
              className={currentLocation?.pathname === '/providerLocations' ? classes.active1 : ''}
              to="/providerLocations"
            >
              <div className={classes.iconBox2}>Provider Locations</div>
            </NavLink>
          </li>

          <li
            title="Add Location"
            className={
              mainMenuActiveCheck(currentLocation?.pathname) ? classes.subMainMenuActive1 : ''
            }
          >
            <div
              onClick={handleMenuOpen}
              className={`${classes.iconBox2} ${classes.iconBox2Border}`}
            >
              Add Location{' '}
              <IconButton aria-label="expand menu" padding="0" size="small">
                {subMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </div>
            <Collapse
              in={subMenuOpen}
              className={classes.muiCollapseVertical}
              timeout="auto"
              unmountOnExit
            >
              <ul className={classes.subMenuLeftNav}>
                {submenuItemsAddLocation?.map((item) => (
                  <li
                    key={item.path}
                    title={item.title}
                    className={activeSubMenuItem === item ? classes.active1 : ''}
                  >
                    <NavLink to={item.path} onClick={handleSubmenuItemClicked(item)}>
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Collapse>
          </li>

          {teamType && teamType != 'Inventory Analyst' && (
            <>
              <li
                title="Dashboard"
                className={
                  currentLocation?.pathname === '/providerDashboard' ? classes.active1 : ''
                }
              >
                <NavLink to="/providerDashboard" onClick={(e) => demandViewCounter(e)}>
                  <div className={classes.iconBox2}>Inquiries</div>
                </NavLink>
              </li>
              <li
                title="Dashboard"
                className={
                  currentLocation?.pathname === '/providerTransactions' ? classes.active1 : ''
                }
              >
                <NavLink to="/providerTransactions" onClick={(e) => transactionViewCounter(e)}>
                  <div className={classes.iconBox2}>Transactions</div>
                </NavLink>
              </li>
              <li
                title="Dashboard"
                className={currentLocation?.pathname === '/providerProfile' ? classes.active1 : ''}
              >
                <NavLink
                  to="/providerProfile"
                  // onClick={(e) => transactionViewCounter(e)}
                >
                  <div className={classes.iconBox2}>Profile</div>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default ProviderLeftNavbar;
