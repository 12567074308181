import React from 'react';

import classes from './ProviderPage.module.css';

import Tooltip from '../common/Tooltip/Tooltip';

function Card(props) {
  return (
    <div className={`${classes.card} ${props.extraClass}`} onClick={props.clickHandle}>
      <div className={classes.title}>
        {props.title}
        <Tooltip val="transactionTooltip" text={props.infoText} />
      </div>
      <div className={classes.quantity}>{props.quantity}</div>
    </div>
  );
}

export default Card;
