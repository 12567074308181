import styled from 'styled-components';

export const Block = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  min-width: ${({ minWidth }) => minWidth}px;
  min-height: ${({ minHeight }) => minHeight}px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  display: ${({ display }) => display};
  position: ${({ position }) => position};
  overflow: ${({ overflow }) => overflow};
  overflow-y: ${({ overflowY }) => overflowY};
  overflow-x: ${({ overflowX }) => overflowX};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-right: ${({ marginRight }) => marginRight}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  padding-top: ${({ paddingTop }) => paddingTop}px;
  padding-right: ${({ paddingRight }) => paddingRight}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap}px;
`;
