import React, { Component } from 'react';

import { withOktaAuth } from '@okta/okta-react';

import cbreAgileLogo from './../../assets/images/CBRE_Agile.svg';
import './Navbar.css';
import NavHelpMenu from './NavHelpMenu';

import { API_URLS } from '../common/headers';
import Logo from '../Logo/Logo';

export default withOktaAuth(
  class Navbar extends Component {
    constructor(props) {
      super(props);

      this.logout = this.logout.bind(this);
      this.state = {
        isModalOpen: false,
      };
    }

    async logout() {
      localStorage.clear();
      sessionStorage.clear();
      await this.props.oktaAuth.signOut({ revokeAccessToken: false });
    }

    downloadFile() {
      const element = document.createElement('a');

      element.setAttribute('href', API_URLS.userGuideURL);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

    showVideo = (event) => {
      event.preventDefault();
      this.setState({ isModalOpen: true });
    };
    closeModal = () => {
      this.setState({ isModalOpen: false });
    };

    render() {
      let isProviderUser = false;
      const currentUserType = JSON.parse(sessionStorage.getItem('TEAM_TYPE'));

      if (currentUserType === 'Provider') {
        isProviderUser = true;
      }

      const res = JSON.parse(sessionStorage.getItem('result'));
      let flexpertEmail, flexpertImg, flexpertName, userData;

      if (res) {
        userData = res && res.data && res.data.records[0] && res.data.records[0].fields;

        if (userData && userData.FlexpertName) {
          flexpertName = userData.FlexpertName[0];
          if (userData.FlexpertEmail) flexpertEmail = userData.FlexpertEmail[0];
          if (userData.FlexpertPhoto) flexpertImg = userData.FlexpertPhoto[0].thumbnails.small.url;
        }
      }

      const pathURL = API_URLS.homeURL;

      return (
        <div className="header">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              {!isProviderUser && (
                <a href={pathURL}>
                  <Logo />
                </a>
              )}
              {isProviderUser && (
                <div className="Logo">
                  <img src={cbreAgileLogo} alt="CBRE" />
                </div>
              )}
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
              <div className="navbar-end">
                <div className="navbar-help-menu">
                  <NavHelpMenu></NavHelpMenu>
                </div>

                <div>
                  <button className="button secondary" onClick={this.logout}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      );
    }
  },
);
