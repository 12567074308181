import Layout from './Layout/Layout';
import MapBoxDisplay from './MapBoxDisplay';

const Locations = (props) => {
  return (
    <Layout>
      <MapBoxDisplay {...props}></MapBoxDisplay>
    </Layout>
  );
};

export default Locations;
