import React, { useState } from 'react';

import './requestaccess.css';
import axios from 'axios';
import { toast } from 'react-toastify';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { isValidEmail } from '../../utils/isEmailValid';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import { TextField } from '../common/TextField/TextField';
import 'react-toastify/dist/ReactToastify.css';
import { toastify } from '../common/Toastify';
import Spinner from '../Spinner/Spinner';

const RequestProvider = (props) => {
  const [role, setRole] = useState();
  const [selectedClients, setSelectedClients] = useState([]);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [clientError, setClientError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const roles = ['Broker', 'Provider', 'Client'];

  const selectedClientsData = (event, value) => {
    event.preventDefault();
    console.log(value);
    setSelectedClients(value);
    setClientError('');
  };

  const splitKeyValue = (data) => {
    const arr = [];

    for (const property in data) {
      const obj = {
        id: property,
        name: data[property],
      };

      arr.push(obj);
    }
    const key = 'name';

    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };
  const validate = () => {
    let roleError = '';
    let emailError = '';
    let clientError = '';

    if (!isValidEmail(email)) {
      emailError = 'Please enter a valid email address';
    }
    if (email === '') {
      emailError = 'Please enter email address';
    }
    if (role === undefined) {
      roleError = 'Enter Role';
    }
    if (role === 'Broker' && selectedClients.length <= 0) {
      clientError = 'Select Clients';
    }

    if (emailError || roleError || clientError) {
      setEmailError(emailError);
      setRoleError(roleError);
      setClientError(clientError);

      return false;
    }

    return true;
  };
  const handleRequestSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      const userObj = {
        teamType: role,
        userEmail: email.toLowerCase(),
        accessStatus: 'In Progress',
      };

      if (selectedClients.length > 0) {
        const result = selectedClients.map((a) => a.id);

        userObj.clientId = result.toString();
      }
      saveUserData(userObj);
    }
  };

  const saveUserData = (userObj) => {
    const headers = APPLICATION_HEADERS.mkiiAPIKey;

    const toastOptions = { position: 'top-center', hideProgressBar: true, autoClose: true };

    try {
      setIsLoading(true);
      const url = API_URLS.createAccountTeamsURL;

      axios.post(url, userObj, { headers });
      setIsLoading(false);
      setEmail('');
      setRole('');
      setSelectedClients([]);

      toastify({
        title: 'Your request was submitted',
        type: 'success',
        ...toastOptions,
      });
    } catch (error) {
      setIsLoading(false);
      toastify({
        title: 'Something went wrong..',
        type: 'error',
        ...toastOptions,
      });
    }
  };
  const getClients = (event, values) => {
    event.preventDefault();
    const headers = APPLICATION_HEADERS.mkiiAPIKey;

    if (values.length < 2) {
      return;
    }
    const url = `${API_URLS.getClientsURL}?name=` + values;

    axios
      .get(url, { headers })
      .then((res) => {
        if (res && res.data) {
          const finalData = splitKeyValue(res.data);

          setOptions([...finalData]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChangeHandler = (fieldName, value) => {
    if (fieldName === 'role') {
      setRole(value);
      setRoleError('');
    } else if (fieldName === 'email') {
      setEmail(value);
      setEmailError('');
    }
  };

  return (
    <div className="register-photo">
      {isLoading && (
        <div className="parentDisable" width="100%">
          <Spinner />
        </div>
      )}
      <div className="form-container">
        <div className="image-holder"></div>

        <form method="post">
          <h2 className="requestAccessFormTitle text-left">MKII Request Access Form</h2>
          <div>
            <TextField
              fullWidth
              id="outlined-required"
              label="Email Address (required)"
              value={email}
              error={!!emailError}
              helperText={emailError}
              onChange={(e) => {
                onChangeHandler('email', e.target.value);
              }}
            />
          </div>
          <div>
            <Box sx={{ minWidth: 120, marginTop: '20px', marginBottom: '5px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Role Type"
                  onChange={(e) => {
                    onChangeHandler('role', e.target.value);
                  }}
                  error={!!roleError}
                >
                  {roles.map((e, key) => {
                    return (
                      <MenuItem value={e} key={key}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <div className="requestProviderError__block">{roleError}</div>
          </div>
          {role === 'Broker' && (
            <div className="field autocompleteField__container">
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                onInputChange={getClients}
                onChange={selectedClientsData}
                options={options}
                key={(option) => option.id}
                defaultValue={[]}
                sx={{ width: '500px' }}
                getOptionLabel={(option) => option.name}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Clients"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <div className="requestProviderError__block">{clientError}</div>
            </div>
          )}
          <div>
            <button
              onClick={handleRequestSubmit}
              className="btn btn-primary btn-block btn-signup text-uppercase requestAccess__button"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestProvider;
