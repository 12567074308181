import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import SearchBar from 'material-ui-search-bar';
import styled from 'styled-components';

import downImg from './../../assets/images/arrow_downward_24px.svg';
import upImg from './../../assets/images/arrow_upward_24px.svg';
import Pagination from './../common/Pagination/Pagination';
import classes from './AnalyticsPage.module.css';
import Card from './Card';

import constants from '../common/constants.js';
import { Block } from '../common/StyledComponents/StyledBlock';
import { sumOfObjectValues } from '../Utils/Utils';

const columns = [
  { id: 'region', label: 'Region', minWidth: 50 },
  { id: 'city', label: 'Market', minWidth: 80 },
  { id: 'count', label: 'Survey Completion Time', minWidth: 80 },
];

const regionTabs1 = [
  {
    id: 1,
    title: '',
    label: '',
    quantity: null,
  },
  {
    id: 2,
    title: '',
    label: '',
    quantity: null,
  },
  {
    id: 3,
    title: '',
    label: '',
    quantity: null,
  },
  {
    id: 4,
    title: '',
    label: '',
    quantity: null,
  },
];

const regionTabs = [];

export default function AnalyticsTable(props) {
  const { analyticsData, onClose, analyticsTitle, analyticsIcon, unit, surveyData } = props;

  const [filterData, setFilteredData] = useState([]);
  const [searched, setSearched] = useState('');
  const [selectedPageTitle, setSelectedPageTitle] = useState('');
  const [surveys, setSurveys] = useState([]);
  const [filteredVal, setFilteredVal] = useState('all');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [beforeStatusData, setBeforeStatusData] = useState([]);
  const [cardCollection, setCardCollection] = useState({
    // activeObject: regionTabs[0],
    regions: regionTabs,
  });

  const totalPages = Math.ceil(filterData.length / rowsPerPage);
  const [isSorted, setIsSorted] = React.useState('desc');
  const [isFieldSort, setIsFieldSort] = React.useState('count');

  const offsetPerPage = page * rowsPerPage;
  const filteredData = filterData.slice(offsetPerPage, offsetPerPage + rowsPerPage);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  useEffect(() => {
    const filterProviderData = () => {
      while (regionTabs.length > 0) {
        regionTabs.pop();
      }

      if (analyticsData) {
        const dataKeys = Object.keys(analyticsData).filter((item) => item);

        dataKeys.forEach(function (item, i) {
          regionTabs.push({
            id: i,
            title: item,
            label: item,
            quantity: null,
          });
        });

        let title = analyticsTitle + ' in';

        setSelectedPageTitle(title);

        if (analyticsTitle == constants.surveyCompltionTime) {
          title = analyticsTitle + ' Results for';
          setSelectedPageTitle(title);
        } else {
          setSelectedPageTitle(title);
        }

        if (analyticsTitle === constants.closedDeals) {
          columns.push(
            { id: 'count', label: 'Closed Deals', minWidth: 80 },
            { id: 'count', label: '% Closed Deals', minWidth: 80 },
          );
        } else if (analyticsTitle === 'No. of Surveys') {
          columns[2] = { id: 'scount', label: 'Surveys', minWidth: 80 };
        }

        const newRegionTabs = cardCollection.regions.map((card) => {
          const title = card && card.title;
          const matchingFields = analyticsData[title] || {};
          const matchingTotal = sumOfObjectValues(matchingFields);

          if (analyticsTitle === constants.noOfSurveys) {
            return { ...card, quantity: matchingTotal };
          }

          if (analyticsTitle === constants.closedDeals) {
            const surveytotal = sumOfObjectValues(surveyData[title] || {});

            const quantity =
              surveytotal === 0 ? 0 : Math.round((matchingTotal / surveytotal) * 100);

            return { ...card, quantity };
          }

          const offsetMatchedFields = Object.keys(matchingFields).length;
          const quantity =
            offsetMatchedFields === 0 ? 0 : Math.round(matchingTotal / offsetMatchedFields);

          return { ...card, quantity };
        });

        setCardCollection((prev) => ({
          ...prev,
          regions: getUniqueListBy(newRegionTabs, 'title'),
        }));

        onRegionclickHandler(regionTabs[0]['title']);
      }
    };

    if (analyticsData) {
      filterProviderData();
    }
  }, []);

  const toggleActiveCard = (id) => {
    setCardCollection((prev) => ({
      ...prev,
      activeObject: prev.regions[id],
    }));
  };

  const toggleActiveStyle = (index) => {
    let _val = null;

    if (cardCollection.regions[index] === cardCollection.activeObject) {
      _val = classes.activeCard;
    }

    return _val;
  };

  const handleRequestSort = (sortOrder, sortField) => {
    if (sortOrder == 'desc') {
      setFilteredData(_.orderBy(filterData, [(row) => row[sortField]], ['asc']));
      setIsSorted('asc');
    } else {
      setFilteredData(_.orderBy(filterData, [(row) => row[sortField]], ['desc']));
      setIsSorted('desc');
    }
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const onRegionclickHandler = (val) => {
    setPage(0);

    toggleActiveCard(0);
    toggleActiveStyle(0);

    setFilteredVal(val);
    let regionBasedData = [];
    const otherData = analyticsData[val] || {};

    Object.keys(otherData).map(function (city, idx) {
      const obj = {
        city: city,
        count: otherData[city],
        region: val,
      };

      if (analyticsTitle === constants.closedDeals) {
        obj.surveyCount = surveyData[val][city];
      }
      obj.percentClosedDeals = Math.round((obj.count / obj.surveyCount) * 100);

      regionBasedData.push(obj);
    });

    regionBasedData = _.orderBy(regionBasedData, [(user) => user.count], ['desc']);

    searched && setSearched('');
    if (val && analyticsData) {
      setSurveys(regionBasedData);
      setFilteredData(regionBasedData);
      setBeforeStatusData(regionBasedData);
    }
  };

  const handleSearch = (searchedVal) => {
    if (searchedVal) {
      setSearched(searchedVal);
      setPage(0);

      const value = searchedVal.toLowerCase().trim();
      let result = [];

      result = surveys.filter((data) => {
        return (
          (data['region'] && data['region'].toLowerCase().includes(value)) ||
          (data['city'] && data['city'].toLowerCase().includes(value))
        );
      });

      setFilteredData(result);
    } else {
      cancelSearch();
    }
  };
  const cancelSearch = () => {
    setSearched('');
    setFilteredData(beforeStatusData);
  };

  const sortCounts = (fieldName) => {
    return (
      // eslint-disable-next-line no-unreachable
      <span className="sortedImage__Icon">
        {isSorted == 'desc' ? (
          <img src={downImg} className={classes.sortOrderImg}></img>
        ) : (
          <img src={upImg} className={classes.sortOrderImg}></img>
        )}
      </span>
    );
  };

  const sortResponse = (name) => {
    setIsFieldSort(name);
    handleRequestSort(isSorted, name);
  };

  return (
    <>
      <div className={classes.cards}>
        {cardCollection.regions.map((eachCard, index) => (
          <div
            key={index}
            className={toggleActiveStyle(index)}
            onClick={() => toggleActiveCard(index)}
          >
            <Card
              quantity={eachCard.quantity}
              infoText={eachCard.infoText}
              title={eachCard.label}
              icon={analyticsIcon}
              measure={unit}
              analyticsTitle={analyticsTitle}
              clickHandle={() => onRegionclickHandler(eachCard.title)}
            />
          </div>
        ))}
      </div>

      <Block display="flex" alignItems="center" className={classes.searchBlock}>
        <Title>
          {selectedPageTitle} {filteredVal}
        </Title>
      </Block>
      <SearchBar
        classes={{ root: classes.searchBar__container }}
        value={searched}
        onChange={(searchVal) => handleSearch(searchVal)}
        onCancelSearch={cancelSearch}
      />

      <div className={classes.analyticsTable}>
        {filterData.length > 5 && (
          <Pagination
            page={page + 1}
            pageSize={rowsPerPage}
            toPage={setPage}
            onPageSize={handleChangeRowsPerPage}
            totalCount={totalPages}
            padding="0 0 10px 0"
          />
        )}
        <table className={classes.agileTable}>
          <thead>
            <tr>
              <th width="10%">Region</th>
              <th width="10%">Market</th>
              {analyticsTitle === constants.surveyCompltionTime && (
                <>
                  <th
                    width="10%"
                    className={classes.rightAligned}
                    onClick={() => sortResponse('count')}
                  >
                    {isFieldSort == 'count' && sortCounts('count')}
                    Avg. Survey Completion Time
                  </th>
                </>
              )}
              {analyticsTitle === constants.noOfSurveys && (
                <>
                  <th
                    width="10%"
                    className={classes.rightAligned}
                    onClick={() => sortResponse('count')}
                  >
                    {isFieldSort == 'count' && sortCounts('count')}
                    Surveys
                  </th>
                </>
              )}
              {analyticsTitle === constants.closedDeals && (
                <>
                  <th
                    width="10%"
                    className={classes.rightAligned}
                    onClick={() => sortResponse('surveyCount')}
                  >
                    Surveys
                    {isFieldSort == 'surveyCount' && sortCounts('surveyCount')}
                  </th>

                  <th
                    width="10%"
                    className={classes.rightAligned}
                    onClick={() => sortResponse('count')}
                  >
                    Closed Deals
                    {isFieldSort == 'count' && sortCounts('count')}
                  </th>
                  <th
                    width="10%"
                    className={classes.rightAligned}
                    onClick={() => sortResponse('percentClosedDeals')}
                  >
                    {' '}
                    % Closed Deals
                    {isFieldSort == 'percentClosedDeals' && sortCounts('percentClosedDeals')}
                  </th>
                </>
              )}
              {analyticsTitle === constants.providerResponseRate && (
                <>
                  <th
                    width="10%"
                    className={classes.rightAligned}
                    onClick={() => sortResponse('count')}
                  >
                    {isFieldSort == 'count' && sortCounts('count')}
                    Provider Response Rate
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={9} className="text-center">
                  No Records
                </td>
              </tr>
            )}
            {filteredData?.map((row, index) => {
              return (
                <tr key={`${row.city}-${index}`}>
                  <td width="10%">{row['region']}</td>
                  <td width="10%">{row['city']}</td>
                  {analyticsTitle !== 'Closed Deals' && (
                    <>
                      <td width="10%" className={classes.rightAligned}>
                        {Math.round(row['count'])}
                        {unit}
                      </td>
                    </>
                  )}

                  {analyticsTitle === 'Closed Deals' && (
                    <>
                      <td className={classes.rightAligned}>{row['surveyCount']}</td>
                      <td className={classes.rightAligned}>{row['count']}</td>
                      <td className={classes.rightAligned}>{row['percentClosedDeals']}%</td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {filterData.length > 5 && (
        <Pagination
          page={page + 1}
          pageSize={rowsPerPage}
          toPage={setPage}
          onPageSize={handleChangeRowsPerPage}
          totalCount={totalPages}
          padding="0 0 35px 0"
        />
      )}
    </>
  );
}

const Title = styled.div`
  font-family: 'Calibre-R';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
`;
