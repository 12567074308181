import React, { useEffect, useCallback, useState } from 'react';

import axios from 'axios';
import styled from 'styled-components';

import { styled as muiStyled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import closedDealsIcon from './../../assets/images/closedDealsIcon.svg';
import surveysIcon from './../../assets/images/nosurveysIcon.svg';
import providerResponseIcon from './../../assets/images/responseIcon.svg';
import RightArrow from './../../assets/images/rightArrow.svg';
import surveyTimeIcon from './../../assets/images/surveyTimeIcon.svg';
import LoadingSpinner from './../MySurveys/LoadingSpinner';
import classes from './AnalyticsPage.module.css';
import AnalyticsTable from './AnalyticsTable';
import { AnalyticsContext } from './context/AnalyticsContext';
import { ANALYTICS_FIRST_YEAR } from './models';

import useCounter from '../../hooks/Couters';
import constants, { KEYS_TO_ANALYTICS_DATA_BY_TITLE } from '../common/constants.js';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import Tooltip from '../common/Tooltip/Tooltip';
import Layout from '../Layout/Layout';
import { calculateAnalytics, getAnalyticsYears } from '../Utils/Utils';

const ANALYTICS_YEARS = getAnalyticsYears(ANALYTICS_FIRST_YEAR);

const StyledSelected = muiStyled(Select)({
  '& .MuiInputBase-input': {
    display: 'inline-flex !important',
    padding: '16.5px 14px !important',
    borderRadius: '4px',
    paddingRight: '32px !important',
  },
});

const analyticsTabs = [
  {
    id: 1,
    key: 'surveyTurnAroundTime',
    title: 'Avg. Survey Completion Time',
    label: 'Avg. Survey Completion Time',
    icon: surveyTimeIcon,
    measure: 'Days',
    quantity: null,
    subText: '(Business Days)',
  },
  {
    id: 2,
    key: 'noOfSurveys',
    title: 'No. of Surveys',
    label: 'No. of Surveys',
    icon: surveysIcon,
    measure: '',
    quantity: null,
    subText: '',
  },
  {
    id: 3,
    key: 'closedDeals',
    title: 'Closed Deals',
    label: 'Closed Deals',
    icon: closedDealsIcon,
    measure: '%',
    quantity: null,
    subText: '',
  },
  {
    id: 4,
    key: 'providerResponseRate',
    title: 'Provider Response Rate',
    label: 'Provider Response Rate',
    icon: providerResponseIcon,
    measure: '%',
    quantity: null,
    subText: '',
  },
];

function AnalyticsPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [surveyTime, setSurveyTime] = useState(0);

  const ROUTES_ANALYTICS_PAGES = { MAIN: 'main', CARD_DETAILS: 'card_details' };
  const [currentRoute, setCurrentRoute] = useState(ROUTES_ANALYTICS_PAGES.MAIN); // default page
  const [surveyAroundTimeData, setSurveyAroundTimeData] = useState();
  const [surveysData, setSurveysData] = useState();

  const [analyticsTitle, setAnalyticsTitle] = useState('');
  const [analyticsIcon, setAnalyticsIcon] = useState();
  const [analyticsObjectData, setAnalyticsObjectData] = useState();
  const [unit, setUnit] = useState();
  const [selectedYear, setSeletedYear] = useState(ANALYTICS_YEARS[0]);
  const { updateAnalyticsCounters } = useCounter();

  const noOfSurveysCount = (data) => {
    const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);
    let sum = 0;

    if (!Object.values(data).length) return sum;

    for (const value of Object.values(data)) {
      sum = sum + sumValues(value);
    }

    return sum;
  };
  const fetchAnalytics = useCallback(async () => {
    setIsLoading(true);
    const URL = API_URLS.agileAnalyticsURL;

    const headers = APPLICATION_HEADERS.mkiiAPIKey;

    const res = await axios.get(URL, { headers });

    if (res?.status === 200 && res?.data) {
      setIsLoading(false);
      const sObj = res.data.surveyTurnAroundTime[selectedYear] || {};

      setSurveyAroundTimeData(sObj);
      setAnalyticsIcon(surveyTimeIcon);

      const surveyTime = calculateAnalytics(sObj);
      const noOfSurveys = noOfSurveysCount(res.data.noOfSurveys[selectedYear] || {});
      const closedDeals = noOfSurveysCount(res.data.closedDeals[selectedYear] || {});
      const providerResponseRate = calculateAnalytics(
        res.data.providerResponseRate[selectedYear] || {},
      );

      analyticsTabs.map((card) => {
        card.data = res.data[card.key][selectedYear];

        if (card.key === 'noOfSurveys') {
          card.quantity = noOfSurveysCount(res.data.noOfSurveys[selectedYear] || {});
        } else {
          card.quantity = calculateAnalytics(res.data[card.key][selectedYear] || {});
        }
      });

      const countObj = {
        surveyTime: surveyTime,
        noOfSurveys: noOfSurveys,
        closedDealsCount: Math.round((closedDeals / noOfSurveys) * 100) || 0,
        providerResponseCount: providerResponseRate,
      };

      const analyticsDataObj = {
        surveyAroundData: res.data.surveyTurnAroundTime[selectedYear] || {},
        noOfSurveysData: res.data.noOfSurveys[selectedYear] || {},
        closedDealsData: res.data.closedDeals[selectedYear] || {},
        providerResponsData: res.data.providerResponseRate[selectedYear] || {},
      };

      if (analyticsTitle) {
        const dataKey = KEYS_TO_ANALYTICS_DATA_BY_TITLE[analyticsTitle];

        setSurveyAroundTimeData(analyticsDataObj[dataKey]);
      }

      setSurveyTime(countObj);
      setSurveysData(analyticsDataObj.noOfSurveysData);
      setAnalyticsObjectData(analyticsDataObj);
    }
  }, [selectedYear, analyticsTitle]);

  const onOpenCardList = React.useCallback((title, data, icon, unit) => {
    setAnalyticsTitle(title);
    setCurrentRoute(ROUTES_ANALYTICS_PAGES.CARD_DETAILS);
    setAnalyticsIcon(icon);
    setUnit(unit);
    setSurveyAroundTimeData(data);
    updateAnalyticsCounter(title);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAnalyticsCounter = (type) => {
    switch (type) {
      case constants.surveyCompltionTime:
        updateAnalyticsCounters('surveyCmptTimeTracker');
        break;

      case constants.noOfSurveys:
        updateAnalyticsCounters('noOfSurveysTracker');
        break;

      case constants.closedDeals:
        updateAnalyticsCounters('closedDealsTracker');
        break;

      case constants.providerResponseRate:
        updateAnalyticsCounters('providerRespRateTracker');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, [selectedYear]);

  const handleClose = () => {
    setCurrentRoute(ROUTES_ANALYTICS_PAGES.MAIN);
  };

  const handleYearChange = (event) => {
    setSeletedYear(event.target.value);
    setCurrentRoute(ROUTES_ANALYTICS_PAGES.MAIN);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        goToMainPage: () => {
          setCurrentRoute(ROUTES_ANALYTICS_PAGES.MAIN);
        },
      }}
    >
      <Layout>
        <div className={classes.dashboard}>
          <div className={classes.transactionWrapper}>
            <AnalyticsTitleBlock>
              <TitleBlock>
                <Title cursor="pointer" onClick={handleClose}>
                  Agile Analytics
                </Title>
                {currentRoute === ROUTES_ANALYTICS_PAGES.CARD_DETAILS && (
                  <>
                    <RightArrowImage src={RightArrow} />
                    <Title>{analyticsTitle}</Title>
                  </>
                )}
              </TitleBlock>

              <FormControl sx={{ m: 1 }}>
                <InputLabel id="year-select">Year</InputLabel>
                <StyledSelected
                  labelId="year-select"
                  value={selectedYear}
                  label="Year"
                  onChange={handleYearChange}
                >
                  {ANALYTICS_YEARS.map((year, index) => (
                    <MenuItem
                      sx={{ display: 'flex !important', padding: '6px 16px !important' }}
                      key={`${year}-${index}`}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </StyledSelected>
              </FormControl>
            </AnalyticsTitleBlock>
            {isLoading && (
              <div className={classes.spinner}>
                <LoadingSpinner />
              </div>
            )}
            {!isLoading && currentRoute === ROUTES_ANALYTICS_PAGES.MAIN && (
              <div className={classes.transactionWrapper}>
                <Grid style={{ width: '100%' }}>
                  <div>
                    <div
                      className={`${classes.card} `}
                      onClick={() =>
                        onOpenCardList(
                          constants.surveyCompltionTime,
                          analyticsObjectData['surveyAroundData'],
                          surveyTimeIcon,
                          ' Days',
                        )
                      }
                    >
                      <div className={classes.image}>
                        <img src={surveyTimeIcon} width="20" height="20" />

                        <div className={classes.quantity}>{surveyTime.surveyTime} Days</div>
                        <div className={classes.subTitle}>(Business Days)</div>
                      </div>
                      <div className={classes.stitle}>
                        {constants.surveyCompltionTime}
                        <Tooltip
                          val="analyticsTooltip"
                          text={constants.surveyCompltionTimeTooltip}
                        />
                      </div>
                    </div>
                    <div
                      className={`${classes.card} `}
                      onClick={() =>
                        onOpenCardList(
                          constants.noOfSurveys,
                          analyticsObjectData['noOfSurveysData'],
                          surveysIcon,
                          '',
                        )
                      }
                    >
                      <div className={classes.image}>
                        <img src={surveysIcon} width="20" height="20" />

                        <div className={classes.quantity}>{surveyTime.noOfSurveys}</div>
                      </div>
                      <div className={classes.title}>
                        {constants.noOfSurveys}
                        <Tooltip val="analyticsTooltip" text={constants.noOfSurveysTooltip} />
                      </div>
                    </div>
                    <div
                      className={`${classes.card} `}
                      onClick={() =>
                        onOpenCardList(
                          constants.closedDeals,
                          analyticsObjectData['closedDealsData'],
                          closedDealsIcon,
                          '%',
                        )
                      }
                    >
                      <div className={classes.image}>
                        <img src={closedDealsIcon} width="20" height="20" />
                        <div className={classes.quantity}>{surveyTime.closedDealsCount}%</div>{' '}
                      </div>
                      <div className={classes.title}>
                        {constants.closedDeals}
                        <Tooltip val="analyticsTooltip" text={constants.closedDealsTooltip} />
                      </div>
                    </div>
                    <div
                      className={`${classes.card} `}
                      onClick={() =>
                        onOpenCardList(
                          constants.providerResponseRate,
                          analyticsObjectData['providerResponsData'],
                          providerResponseIcon,
                          '%',
                        )
                      }
                    >
                      <div className={classes.image}>
                        <img src={providerResponseIcon} width="20" height="20" />
                        <div className={classes.quantity}>
                          {surveyTime.providerResponseCount}%
                        </div>{' '}
                      </div>
                      <div className={classes.title}>
                        {constants.providerResponseRate}
                        <Tooltip
                          val="analyticsTooltip"
                          text={constants.providerResponseRateTooltip}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
            )}
            {currentRoute === ROUTES_ANALYTICS_PAGES.CARD_DETAILS && (
              <AnalyticsTable
                analyticsData={surveyAroundTimeData}
                analyticsTitle={analyticsTitle}
                analyticsIcon={analyticsIcon}
                surveyData={surveysData}
                unit={unit}
                onClose={handleClose}
              />
            )}
          </div>
        </div>
      </Layout>
    </AnalyticsContext.Provider>
  );
}

const RightArrowImage = styled.img`
  width: 8px;
`;
const AnalyticsTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 14px;
  box-shadow: inset 0px -1px 0px rgb(0 63 45 / 15%);
`;
const TitleBlock = styled.div`
  display: flex;
  text-align: left;
  gap: 10px;
`;

const Title = styled.div`
  font-family: 'Calibre-R';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;

  cursor: ${({ cursor }) => cursor};
`;

export default AnalyticsPage;
