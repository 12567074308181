import { toast } from 'react-toastify';

import ToastifyAlert from './ToastifyAlert';

import './Toastify.css';

export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const toastify = ({
  title,
  subtitle,
  type,
  position,
  hideProgressBar,
  autoClose,
  ...rest
}) => {
  const toastOptions = {
    position,
    hideProgressBar,
    autoClose,
    ...rest,
    onOpen: () => {
      document.getElementsByClassName('Toastify')[0].style.position = 'fixed';
    },
    onClose: () => {
      document.getElementsByClassName('Toastify')[0].style.position = 'inherit';
    },
  };

  switch (type) {
    case TOAST_TYPES.SUCCESS: {
      return toast.success(<ToastifyAlert title={title} subtitle={subtitle} />, toastOptions);
    }
    case TOAST_TYPES.ERROR: {
      return toast.error(<ToastifyAlert title={title} subtitle={subtitle} isError />, toastOptions);
    }
    default: {
      return toast(title, toastOptions);
    }
  }
};
