import React, { useState, useCallback } from 'react';

// import { Document, Page } from 'react-pdf';
import { Document, Page, pdfjs, ReactPDF } from 'react-pdf';

// import { useResizeObserver } from '@wojtekmaj/react-hooks';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './ProviderProfile.css';
const ViewProfile = (props) => {
  const { pdfURL } = props;

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerWidth, setContainerWidth] = useState('');

  /*To Prevent right click on screen*/
  document.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const maxWidth = 600;

  return (
    <>
      <div className="provider__container">
        {pdfURL && (
          <div className="provider__container__document">
            <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                />
              ))}
            </Document>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewProfile;
