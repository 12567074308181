import React, { Component } from 'react';

import './Tooltip.css';
import MaterialInfoIcon from '../../core/MaterialIcons/MaterialInfoIcon';

class Tooltip extends Component {
  render() {
    function setTooltipStyle(val) {
      switch (val) {
        case 'price':
          return 'tooltip priceTool';
        case 'providerTooltip':
          return 'tooltip providerTool';
        case 'transactionTooltip':
          return 'tooltip transactionTooltip';
        case 'analyticsTooltip':
          return 'tooltip analyticsTooltip';
        case 'myTransaction':
          return 'tooltip mytransaction';
        default:
          return 'tooltip';
      }
    }

    return (
      <div className={setTooltipStyle(this.props.val)}>
        <span className="tooltiptext">{this.props.text || this.props.children}</span>
        <MaterialInfoIcon></MaterialInfoIcon>
      </div>
    );
  }
}

export default Tooltip;
