import React from 'react';

import styled, { css } from 'styled-components';

import backIcon from '../../assets/images/backIcon.svg';
import { Block } from '../common/StyledComponents/StyledBlock';

function createMarkup(data) {
  return { __html: data };
}

const ViewDetailsModal = (props) => {
  const { rowData } = props;

  return (
    <DetailsModal>
      <Section>
        <BackButton onClick={props.handleCloseModal}>
          <BackIcon src={backIcon} /> <BackLabel>Back</BackLabel>
        </BackButton>

        <Block marginTop="20">
          <DetailsLabel>Details for the Deal Id: {rowData.DealID}</DetailsLabel>
          <FormGroup>
            <StyledLabel>Client Name</StyledLabel>
            <Text>{rowData['Client Name']}</Text>
          </FormGroup>
          <Block overflow="hidden" margin="30px 0">
            <FormGroup width={150} withMargin>
              <StyledLabel>Desk Count</StyledLabel>
              <Text>{rowData['Projected Size']}</Text>
            </FormGroup>
            <FormGroup width={150} marginLeft="13" withMargin>
              <StyledLabel> Move-in Date</StyledLabel>
              <Text>{rowData.MoveInDate}</Text>
            </FormGroup>
          </Block>

          <FormGroup>
            <StyledLabel>Target Market</StyledLabel>
            <Text>{rowData['Target Market']}</Text>
          </FormGroup>

          <FormGroup>
            <StyledLabel> Requester email address</StyledLabel>
            <Text>{rowData.RequesterEmail}</Text>
          </FormGroup>
          {rowData.ClientCurrentLocation && rowData.ClientCurrentLocation != 'null' && (
            <FormGroup>
              <FixedLabel> Client Current Location</FixedLabel>
              <Text>{rowData.ClientCurrentLocation}</Text>
            </FormGroup>
          )}
          {rowData.SpecialRequirements && rowData.SpecialRequirements !== '\n' && (
            <FormGroup height="195" overflowY="scroll" withMargin>
              <FixedLabel>Special Requirements</FixedLabel>
              <Text>
                <div dangerouslySetInnerHTML={createMarkup(rowData.SpecialRequirements)} />
              </Text>
            </FormGroup>
          )}
        </Block>
      </Section>
    </DetailsModal>
  );
};

const DetailsModal = styled.div`
  display: flex;
  width: 500px;
  float: right;
  height: 900px;
  background: #fff;
  font-family: Calibre-R;
  font-size: 14px;
  line-height: 16px;
  outline: '0';
`;

const BackLabel = styled.label`
  font-size: 18px;
  line-height: 18px;
  margin: 5px;
  color: #1a1a1a;
  cursor: pointer;
`;

const BackButton = styled.div``;
const BackIcon = styled.img``;

const FormGroup = styled.div`
  padding: 15px;
  border: 1px solid;
  border-radius: 3px;
  margin: 30px 0px;
  width: 344px;
  min-height: 15px;
  position: relative;
  width: ${(props) => props.width}px !important;
  height: ${({ height }) => height}px;
  overflow-y: ${({ overflowY }) => overflowY};
  ${({ withMargin }) =>
    withMargin &&
    css`
      float: left;
      margin: 0px !important;
      margin-top: 5px !important;
    `}
  margin-left: ${({ marginLeft }) => marginLeft}px !important;
`;

const StyledLabel = styled.label`
  bottom: 39px;
  position: absolute;
  background: #fff;
  padding: 0px 3px;
`;

const DetailsLabel = styled.label`
  color: #1a1a1a;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

const Text = styled.span`
  border: none;
  color: rgba(38, 38, 38, 0.6);
  font-size: 16px;
  line-height: 16px;
`;

const FixedLabel = styled.label`
  position: fixed;
  margin-top: -25px;
  background: #fff;
  padding: 0px 3px;
`;

const Section = styled.section`
  margin: 20px 0px 20px 40px;
`;

export default ViewDetailsModal;
