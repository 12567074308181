import React, { Component } from 'react';

import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './RequestSurveyModal.css';
import _ from 'lodash';
import MapboxGl from 'mapbox-gl';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import convene from './../../assets/images/pin_convene.svg';
import executiveCenter from './../../assets/images/pin_executive_center.svg';
import expansive from './../../assets/images/pin_expansive.svg';
import HQ from './../../assets/images/pin_HQ.svg';
import industrious from './../../assets/images/pin_industrious.svg';
import knotel from './../../assets/images/pin_knotel.svg';
import novel from './../../assets/images/pin_novel.svg';
import otherPin from './../../assets/images/pin_other providers.svg';
import premier from './../../assets/images/pin_premier.svg';
import regus from './../../assets/images/pin_Regus.svg';
import serendipity from './../../assets/images/pin_serendipity.svg';
import spaces from './../../assets/images/pin_spaces.svg';
import wework from './../../assets/images/pin_WeWork.svg';
import ClientsDropdown from './clientsDropdown';

import 'react-quill/dist/quill.snow.css';
import { isValidEmail } from '../../utils/isEmailValid';
import DatePicker from '../common/DatePicker/DatePicker';
import { APPLICATION_HEADERS, API_URLS } from '../common/headers';
import { toastify } from '../common/Toastify';
import Spinner from '../Spinner/Spinner';
import { getKeysByValue } from '../Utils/Utils';

toast.configure();
const initialState = {
  yourEmail: '',
  // headCount: 0,
  // maxHeadCount:0,
  date: '',
  specialRequirements: '',
  selectedValue: '',
  emailError: '',
  clientError: '',
  clientNameError: '',
  headCountError: '',
  dateError: '',
  disableButton: false,
  map: null,
  showField: false,
  newClientName: '',
  loading: false,
  clientCurrentLocation: '',
  currentAddressCheck: 0,
};

class RequestSurveyModal extends Component {
  state = initialState;
  static childContextTypes = {
    map: PropTypes.object,
  };

  getChildContext = () => ({
    map: this.state.map,
  });
  OnReqChange = (data) => {
    this.setState({
      specialRequirements: data,
    });
  };
  onInputChange = (event) => {
    if (event.target.id === 'headCount') {
      this.setState({ maxHeadCount: event.target.value });
    }

    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  dropdownchange = (event) => {
    this.setState({ selectedValue: event.target.value });
    if (event.target.value === 'New Client') {
      document.querySelector('.geoCoder3').style = 'top:64% !important';
      this.setState({ showField: true });
    } else {
      this.setState({ showField: false });
    }
  };

  validate = () => {
    let headCountError = '';
    let dateError = '';
    let emailError = '';
    let clientError = '';
    let clientNameError = '';

    if (!isValidEmail(this.state.yourEmail)) {
      emailError = 'Please enter a valid email address';
    }
    if (this.state.yourEmail && this.state.yourEmail.trim() === '') {
      emailError = 'Please enter a valid email address';
    }
    if (this.state.headCount && (this.state.headCount === 0 || this.state.headCount === 'e')) {
      headCountError = 'Please enter desk count';
    }

    if (!this.state.headCount && (this.state.maxHeadCount || !this.state.maxHeadCount)) {
      headCountError = 'Min value is mandotory';
    }
    if (!this.state.headCount) {
      headCountError = 'Please enter desk count';
    }
    if (
      this.state.headCount &&
      this.state.maxHeadCount &&
      parseInt(this.state.headCount) > parseInt(this.state.maxHeadCount)
    ) {
      // headCountError = "Max desk count should be greater than Min desk count";
      headCountError = 'Min should be <= Max';
    }

    if (!this.state.date && this.state.date.trim() === '') {
      dateError = 'Please enter move-in date';
    }

    if (!this.state.selectedValue && this.state.selectedValue.trim() === '') {
      clientError = 'Please select client';
    }
    if (this.state.selectedValue === 'New Client' && this.state.newClientName.trim() === '') {
      clientNameError = 'Please enter client name';
    }
    if (emailError || headCountError || dateError || clientError || clientNameError) {
      this.setState({
        emailError,
        headCountError,
        dateError,
        clientError,
        clientNameError,
      });

      return false;
    }

    return true;
  };

  componentDidMount() {
    let counter = 0;
    //by default currnt location if added display at current loc field
    const placeName = sessionStorage.getItem('CURRENT_LOCATION');
    // this.state.clientCurrentLocation = place_name;
    //login user data

    this.setState({ loading: false });
    MapboxGl.accessToken = APPLICATION_HEADERS.mapBoxAccessToken;
    let lat, lon, zoomLevel;
    const url = window.location.href;
    const urlArray = url.split('/place/');
    const mapValues = urlArray[1]?.split('/');
    const geocoder3 = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: MapboxGl.accessToken, // Set the access token
      mapboxgl: MapboxGl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
      placeholder: 'Search for city', // Placeholder text for the search bar,
      // getItemValue:'chicago'
    });

    geocoder3.addTo('.geocoder3');
    if (placeName) {
      //if client loc already added
      geocoder3.query(placeName)._geocode(placeName);
    }

    geocoder3.on('clear', () => {
      sessionStorage.removeItem('CURRENT_CLIENT_LOCATION');
    });

    geocoder3.on('result', async function (ev) {
      const currentPlaceName = ev.result.place_name;

      sessionStorage.setItem('CURRENT_CLIENT_LOCATION', currentPlaceName);
    });

    if (mapValues) {
      lon = mapValues[0];
      lat = mapValues[1];
      zoomLevel = mapValues[2];
    } else {
      lon = -78.549668;
      lat = 39.014;
      zoomLevel = 7;
    }

    // const newLocal = 'mapbox://styles/marshallo/cjsetlieu2gn51frl4javd5cd';
    const map = new MapboxGl.Map({
      container: 'map', // container id
      // style: 'mapbox://styles/marshallo/cjsetlieu2gn51frl4javd5cd', // style URL
      style: process.env.REACT_APP_MAPBOX_STYLE.trim(),
      center: [lon, lat], // starting position [lng, lat]
      zoom: zoomLevel, // starting zoom,
      interactive: false,
    });
    const bbox = sessionStorage.getItem('BBOX');
    const res = bbox?.split(',');

    if (res) {
      map.fitBounds(res);
    }

    const showProvidersOnMap = async () => {
      const markePoints = this.props.providerdata;

      const iconProviders = [
        { url: 'img-wework', name: 'wework', imgName: wework },
        { url: 'img-regus', name: 'regus', imgName: regus },
        { url: 'img-convene', name: 'convene', imgName: convene },
        {
          url: 'img-executive_center',
          name: 'executive center',
          imgName: executiveCenter,
        },
        { url: 'img-HQ', name: 'hq', imgName: HQ },
        { url: 'img-industrious', name: 'industrious', imgName: industrious },
        { url: 'img-knotel', name: 'knotel', imgName: knotel },
        { url: 'img-novel', name: 'novel', imgName: novel },
        { url: 'img-serendipity', name: 'serendipity', imgName: serendipity },
        { url: 'img-other', name: 'other', imgName: otherPin },
        { url: 'img-nopic', name: 'noimg', imgName: otherPin },
        { url: 'img-spaces', name: 'spaces', imgName: spaces },
        { url: 'img-premier', name: 'premier', imgName: premier },
        { url: 'img-expansive', name: 'expansive', imgName: expansive },
      ];
      // make locations coordinates create one obj
      const locationObj = _.map(markePoints.data, function (o) {
        // let data = JSON.parse(o);
        const data = o;
        const coord = data.coordinates && data.coordinates.split(',');

        const d = coord[1] + ',' + coord[0];

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: JSON.parse('[' + d + ']'),
          },
          providerName: data.provider_name,
        };
      });

      const providers = this.getFilteredData(markePoints);

      const topIcons = [];

      if (counter < 1) {
        iconProviders.map((icon) => {
          //creating images for providers
          //condition for image icon display for providers
          if (icon.imgName) {
            const img = new Image(10, 10);

            img.src = icon.imgName;
            img.onload = () => map.addImage(icon.url, img);
          }
          //mapping top5 providers with icons
          providers.map((p) => {
            if (p.toLowerCase().replace(/\s/g, '') === icon.name.toLowerCase().replace(/\s/g, '')) {
              topIcons.push({ url: icon.url, name: icon.name });
            }
          });
        });
        counter++;
      }

      locationObj.map((dObj) => {
        let Obj;

        topIcons.map((n) => {
          if (n.name && dObj.providerName) {
            if (
              n.name.toLowerCase().replace(/\s/g, '') ===
              dObj.providerName.toLowerCase().replace(/\s/g, '')
            ) {
              Obj = {
                icon: n.url,
              };
              dObj.properties = Obj;
            }
          }
        });

        iconProviders.map((n) => {
          if (n.name && dObj.providerName) {
            if (
              n.name.toLowerCase().replace(/\s/g, '') ===
              dObj.providerName.toLowerCase().replace(/\s/g, '')
            ) {
              Obj = {
                icon: n.url,
              };
              dObj.properties = Obj;
            }
          }
        });
        //Providers which has no image icon condition
        if (!dObj.properties && _.includes(providers, dObj.providerName)) {
          const Obj1 = {
            // 'icon': 'img-nopic'
            icon: 'img-other',
          };

          dObj.properties = Obj1;
        }

        //condition for Other prviders
        if (!dObj.properties) {
          Obj = {
            icon: 'img-other',
          };
          dObj.properties = Obj;
        }
      });

      const finalObject = {
        type: 'FeatureCollection',
        features: locationObj,
      };

      try {
        map.addSource('single-point1', {
          type: 'geojson',
          data: finalObject,
        });
        map.addLayer({
          id: 'point1',
          source: 'single-point1',

          type: 'symbol',
          layout: {
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'icon-size': 1,
          },
        });
      } catch (error) {
        if (map.getLayer('point1')) {
          map.removeLayer('point1');
        }
        if (map.getSource('single-point1')) {
          map.removeSource('single-point1');
        }
      }
    };

    map.on('load', function () {
      showProvidersOnMap();
    });
  }
  getFilteredData = (markerPoints) => {
    const rawData = _.map(markerPoints.data, function (o) {
      // let data = JSON.parse(o);
      const data = o;

      if (data.provider_name === 'Wework') {
        data.provider_name = 'WeWork';
      }
      if (data.provider_name === 'Novel' || data.provider_name === 'Novel Coworking') {
        data.provider_name = 'Expansive';
      }

      return data.provider_name;
    });

    const count = [];

    // for (let i = 0; i < rawData.length; i++) {
    for (const i in rawData) {
      const val = rawData[i];

      count[val] = count[val] ? count[val] + 1 : 1;
    }
    const sortable = [];

    for (const v in count) {
      sortable.push([v, count[v]]);
    }
    sortable
      .sort(function (a, b) {
        return a[1] - b[1];
      })
      .reverse();
    let fData;

    if (sortable.length > 5) {
      fData = sortable.splice(0, 5);
    } else {
      fData = sortable;
    }
    //top 5 providers logic

    return fData.map((o) => {
      return o[0];
    });
  };
  handleCheckClick = () => {
    this.setState((prevState) => ({
      currentAddressCheck: !prevState.currentAddressCheck,
    }));
  };
  handleRequestSubmit = async (event) => {
    event.preventDefault();
    const isValid = this.validate();

    if (isValid) {
      this.setState({ disableButton: true });
      try {
        this.setState({ loading: true });

        const address = this.props.centerData[3];

        const res = JSON.parse(sessionStorage.getItem('result'));
        let flexpertName;

        //flexpert name check
        const userData = res.data.records[0].fields;
        const clientCurrentLocation = sessionStorage.getItem('CURRENT_CLIENT_LOCATION') || '';
        const isClientLocation = this.state.currentAddressCheck === 0 ? 'no' : 'yes';

        const surveyRequest = {
          fields: {
            client_name: this.state.selectedValue,
            client_current_location: clientCurrentLocation,
            email: this.state.yourEmail,
            headcount: parseInt(this.state.headCount),
            is_client_location: isClientLocation,
            map_url: window.location.href,
            market_address: address.place_name,
            max_headcount: parseInt(this.state.maxHeadCount),
            mkii_user_login: userData.Password,
            requested_date: this.state.date,
            special_requirements: this.state.specialRequirements,
            stage: 'Survey',
            survey_status: 'Not Started',
            target_market: address.cityState,
            mkiiLoginEmail: sessionStorage.getItem('ssoEmail'),
            vital_sign: 'Active',
          },
        };

        if (address.region) {
          const regionValue = getKeysByValue(address.region);

          surveyRequest.fields.region = regionValue.toString();
        }
        if (address.country) {
          surveyRequest.fields.country = address.country;
        }
        if (!address.cityState && address.place_name) {
          surveyRequest.fields['target_market'] = address.place_name;
        }

        //added new Client
        if (this.state.selectedValue === 'New Client') {
          surveyRequest.fields.client_name = this.state.newClientName.trim();
        }
        if (userData && userData.FlexpertName && userData.FlexpertName.length > 0) {
          surveyRequest.fields.flexpert_name = userData.FlexpertName.toString();
        }
        const isTestDeal = `${process.env.REACT_APP_IS_TEST_DEAL.trimEnd()}`;

        if (isTestDeal && isTestDeal == 'yes') {
          surveyRequest.fields.testing = true;
        }

        const headers = APPLICATION_HEADERS.mkiiAPIKey;
        const url = API_URLS.requestSurveysURL;
        const response = await axios.post(url, surveyRequest, { headers });

        if (response.data && response.data.fields && this.state.selectedValue === 'New Client') {
          sessionStorage.setItem('CURRENT_CLIENT_LOCATION', '');
          try {
            const getClientRequest = {
              clientId: response.data.fields.Client[0],
              email: sessionStorage.getItem('ssoEmail')?.toLowerCase(),
            };

            const clientURL = API_URLS.updateAccountTeamURL;
            const response1 = await axios.patch(clientURL, getClientRequest, {
              headers,
            });

            if (response1.data && response1.data.records) {
              this.setState({ loading: false });

              const clients = response1.data.records[0].fields.ClientNames;

              userData.ClientNames = clients;
              sessionStorage.setItem('USER_DATA', JSON.stringify(userData));
            }
          } catch (e) {
            toastify({
              title: 'Something went wrong..',
              type: 'error',
              position: 'top-center',
              hideProgressBar: true,
              autoClose: true,
            });
            this.props.close();
            this.setState(initialState);
          }
        }

        this.props.close();
        toastify({
          title: 'Your request was submitted',
          type: 'success',
          position: 'top-center',
          hideProgressBar: true,
          autoClose: true,
        });
        this.setState(initialState);
      } catch (e) {
        toastify({
          title: 'Something went wrong..',
          type: 'error',
          position: 'top-center',
          hideProgressBar: true,
          autoClose: true,
        });
        this.props.close();
        this.setState(initialState);
      }
    }
  };

  onDatePickerChange = (date) => {
    const event = { target: { id: 'date', value: date } };

    this.onInputChange(event);
  };

  closeModal = () => {
    this.props.close();
  };

  render() {
    const res1 = JSON.parse(sessionStorage.getItem('result'));
    const currentUser = res1.data.records[0].fields;

    const isEdgeUser = true;

    const val = new Date();
    const minDate =
      val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + ('0' + val.getDate()).slice(-2);

    return (
      <div className="modalBox surveyModalBox">
        {this.state.loading && (
          <div className="parentDisable" width="100%">
            <Spinner />
          </div>
        )}

        <div className="container1">
          <div className="left-half">
            <article>
              <h3 className="requestSurvey__title">Request a Survey</h3>
              <p className="requestSurvey__subtitle">
                You will receive a survey of providers in this map area. Requests are typically
                fulfilled in 3 business days
              </p>
              <div className="mapRequest">
                <div className="mapRequest__container" id="map"></div>
              </div>
            </article>
          </div>
          <div className="right-half">
            <h1 className="surveyHeading">Client Requirements</h1>
            <div className="surveyFormContainer">
              <form onSubmit={this.handleRequestSubmit}>
                <div className="field">
                  <label className="modalLabel">Email (required)</label>
                  <div>
                    <input
                      type="email"
                      id="yourEmail"
                      data-testid="yourEmail"
                      className="requestSurveyEmail"
                      value={this.state.yourEmail}
                      onChange={this.onInputChange}
                    />
                    {this.state.emailError ? (
                      <div className="fieldError__block">{this.state.emailError}</div>
                    ) : (
                      <div className="fieldSuccess__block"></div>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="modalLabel">Client (required)</label>
                  <div>
                    <ClientsDropdown
                      value={this.state.selectedValue}
                      change={this.dropdownchange}
                    />
                    {this.state.clientError ? (
                      <div className="fieldError__block">{this.state.clientError}</div>
                    ) : (
                      <div className="fieldSuccess__block"></div>
                    )}
                  </div>
                </div>
                {this.state.showField ? (
                  <div className="field fieldClient__block">
                    <label className="modalLabel">
                      Client name <div className="astrisk">*</div>
                    </label>
                    <div>
                      <input
                        type="text"
                        name="newClientName"
                        id="newClientName"
                        className="newClientName__input"
                        value={this.state.newClientName}
                        onChange={this.onInputChange}
                      />
                      {this.state.clientNameError ? (
                        <div className="fieldError__block">{this.state.clientNameError}</div>
                      ) : (
                        <div className="fieldSuccess__block"></div>
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="field deskCount">
                  <label className="modalLabel">Desk count (required)</label>
                  <div className="field field__container deskCountBlock">
                    {!isEdgeUser && (
                      <>
                        <input
                          type="number"
                          id="headCount"
                          className="deskCountInput"
                          min="1"
                          value={this.state.headCount}
                          onChange={this.onInputChange}
                        />
                      </>
                    )}
                    {isEdgeUser && (
                      <>
                        <div>
                          <span>Min</span>
                          <input
                            className="minDeskCount__input"
                            type="number"
                            id="headCount"
                            min="1"
                            value={this.state.headCount}
                            onChange={this.onInputChange}
                          />
                          <span>Max</span>
                          <input
                            className="maxDeskCount__input"
                            type="number"
                            id="maxHeadCount"
                            value={this.state.maxHeadCount}
                            min="0"
                            onChange={this.onInputChange}
                          />
                        </div>
                      </>
                    )}
                    <DatePicker
                      disablePast
                      inputFormat="MM/DD/YYYY"
                      label="Desired move-in"
                      dateFormat="YYYY-MM-DD"
                      fullWidth
                      handleChange={this.onDatePickerChange}
                    />
                  </div>
                  {this.state.headCountError && (
                    <div className="fieldError__block">{this.state.headCountError}</div>
                  )}
                </div>

                <div className="field chooseLocationBlock">
                  <label className="modalLabel">Client current location</label>
                  {this.state.showField ? (
                    <div id="geocoder3" className="geocoder geocoder3"></div>
                  ) : (
                    <div id="geocoder3" className="geocoder geocoder3"></div>
                  )}
                  <div className="fieldSuccess__block"></div>
                </div>

                <div className="field">
                  <div className="sliderCheck">
                    <span className="staybtn">Stay close by </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        data-testid="current-address-input"
                        checked={this.state.currentAddressCheck}
                        onChange={this.handleCheckClick}
                        className="sliderInput"
                        id="filled-in-box"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="fieldSuccess__block"></div>
                </div>

                <div className=" field textareaField">
                  <label className="modalLabel">Special requirements</label>
                  <ReactQuill
                    theme="snow"
                    id="specialRequirements"
                    value={this.state.specialRequirements}
                    preserveWhitespace
                    onChange={this.OnReqChange}
                  />
                </div>

                <div className="field surveyBtnField">
                  <button className="button secondary" onClick={this.closeModal}>
                    Cancel
                  </button>
                  <button
                    className="button primary"
                    data-testid="submitrequest__survey"
                    disabled={this.state.disableButton}
                  >
                    Submit request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestSurveyModal;
