import React from 'react';

import styled from 'styled-components';

import uploadIcon from '../../../../assets/images/provider_locations/dragIcon.svg';
import { Block } from '../../../common/StyledComponents/StyledBlock';

const FileUpload = ({ onUpload }) => {
  return (
    <Container>
      <MainContainer>
        <Label htmlFor="upload-image">
          <LabelContainer display="flex">
            <img src={uploadIcon} />
            <Block>
              <Block display="flex" gap="6">
                <Title>Drag & drop photos to upload or </Title>
                <UnderlineTitle>Browse file</UnderlineTitle>
              </Block>
              <SubTitle>
                Please upload high resolution images of size up to 10MB and of types: png, svg or
                jpg
              </SubTitle>
            </Block>
          </LabelContainer>
        </Label>
        <FormField id="upload-image" type="file" multiple onChange={onUpload} />
      </MainContainer>
    </Container>
  );
};

const FileUploadPreview = ({ onUpload }) => {
  return (
    <FileUploadPreviewContainer>
      <Label htmlFor="upload-image">
        <Block display="flex" flexDirection="column" gap="8">
          <WantToUploadMore>Want to upload more photos?</WantToUploadMore>
          <UnderlineTitle>Browse file</UnderlineTitle>
        </Block>
      </Label>
      <FormField id="upload-image" type="file" multiple onChange={onUpload} />
    </FileUploadPreviewContainer>
  );
};

const FileUploadFloorPreview = ({ onUpload }) => {
  return (
    <FileUploadPreviewContainer>
      <Label htmlFor="upload-image">
        <Block display="flex" flexDirection="column" gap="8">
          <WantToUploadMore>
            Do you want to upload a different floor plan?
            <br />
            Please delete the existing floor plan and upload a new one.
          </WantToUploadMore>
          <UnderlineTitle>Browse file</UnderlineTitle>
        </Block>
      </Label>
      <FormField id="upload-image" type="file" multiple onChange={onUpload} />
    </FileUploadPreviewContainer>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  background: #e6f4ec;
  display: block;
  border-radius: 8px;
  height: 120px;
  position: relative;
`;

const FileUploadPreviewContainer = styled.div`
  margin-top: 24px;
`;

const MainContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 18px);
  position: relative;
  border: 1px dashed #003f2d;
  border-radius: 8px;
  margin: 8px;
`;

const WantToUploadMore = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
`;

const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }
`;

const Label = styled.label``;
const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: 100%;
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 63, 45, 0.5);
`;
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 63, 45, 0.5);
`;
const UnderlineTitle = styled(Title)`
  text-decoration-line: underline;
  color: #003f2d;
`;

export { FileUpload, FileUploadPreview, FileUploadFloorPreview };
