import React, { useContext, useState, useRef, useCallback, useEffect, useMemo } from 'react';

import axios from 'axios';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { Lightbox } from 'react-modal-image';
import { useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  TextField,
  MenuItem,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Autocomplete,
  InputAdornment,
  Typography,
} from '@mui/material';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';

import { FileUpload } from './LocationInformation/FileUpload';
import ModalScreen from './Modal';
import UploadedImageBlockComponent from './UploadedImageBlockComponent';

import closeIcon from '../../../assets/images/provider_locations/close.svg';
import { useSnackBar } from '../../../context/snackBarContext';
import useCouter from '../../../hooks/Couters';
import useDebounce from '../../../hooks/useDebounce';
import useImageUrlValidation from '../../../hooks/useImageUrlValidation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { loadState } from '../../../store/ProviderLocationsContext';
import Accordion from '../../common/Accordion/AccordianLocation';
import { PrimaryButton, SecondaryButton } from '../../common/Button';
import CheckBox from '../../common/Checkbox';
import { APPLICATION_HEADERS, API_URLS } from '../../common/headers';
import MaterialDatePicker from '../../common/MaterialDatePicker/MaterialDatePicker';
import SnackBar from '../../common/Snackbar/ErrorSnackBar';
import { Block } from '../../common/StyledComponents/StyledBlock';
import { toastify } from '../../common/Toastify';
import { CompressingImageService } from '../../Utils/CompressingImageService';
import { UploadImageService } from '../../Utils/UploadImage';
import { sortAlphabeticalStrings, strToArray } from '../../Utils/Utils';

toast.configure();

const suggestionsDefault = {
  selected: {},
  suggestionsData: {},
  suggestionsListOpened: true,
};

const defaultInputData = {
  option: '',
  provider: '',
  address: '',
};

export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const SOMETHING_WENT_WRONG_SIZE_ISSUE = `Please try uploading single images one after the other. If the issue still persists, please convert the image to .JPEG or .JPG and try uploading the image again.`;
export const SOMETHING_WENT_WRONG_SUBMIT_ISSUE = `Something went wrong. Please click on Submit button again.`;
export const LOCATION_ALREADY_EXIST =
  'The location you are trying to add already exists in the database. Please verify the details and add the provider location again.';

export const FAIL_SNACKBAR_OBJECT_SIZE_ISSUE = {
  success: false,
  fail: true,
  title: SOMETHING_WENT_WRONG_SIZE_ISSUE,
  delay: 10000,
};

export const SUCCESS_SNACKBAR_OBJECT = {
  success: true,
  fail: false,
};

export const FAIL_SNACKBAR_OBJECT = {
  success: false,
  fail: true,
  title: SOMETHING_WENT_WRONG,
};

export const FAIL_SNACKBAR_OBJECT_SUBMIT_ISSUE = {
  success: false,
  fail: true,
  title: SOMETHING_WENT_WRONG_SUBMIT_ISSUE,
  delay: 10000,
};

export const SELECT_ALL = 'Select All';

const STAGES = {
  ADD_SUCCESS: 'add_success',
  ADD_FORM: 'add_form',
};

const widthProps = {
  width: '80%',
};
const MAX_ALLOWED_LOCATION_IMAGES = 10;

const NewOptionModal = ({
  isOpen,
  onClose,
  providerName,
  userType,
  majorProvidersList,
  currentRow,
  isEditOption,
  onSuccessEditLocation,
  onSuccessEditLocationAlreadyExists,
}) => {
  //   const dispatch = useDispatch();
  const [values, setValues] = useState(defaultInputData);
  const [suggestions, setSuggestions] = useState(suggestionsDefault);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessSnackBar, setIsSuccessSnackBar] = useState(false);
  const [isErrorSnackBar, setIsErrorSnackBar] = useState(false);
  const [isErrorSnackBarImageURLs, setIsErrorSnackBarImageURLs] = useState(false);
  const [imageError, setImageError] = useState('');
  const [currentStage, setCurrentStage] = useState(STAGES.ADD_FORM);
  const [addedCount, setAddedCount] = useState(0);
  const [onProcessUrls, setOnProcessUrls] = useState(false);
  const [defaultValuePro, setDefaultValue] = useState('');
  const [isResetDatePickerLoc, setIsResetDatePickerLoc] = useState(true);
  const [isAccorianLocOpen, setIsAccorianLocOpen] = useState(false);
  const [fixedAmenitiesList, setFixedAmenitiesList] = useState([]);
  const [currencies, setCurrencies] = useState({});

  const formRef = useRef(null);

  const { inventoryRecordupdateCounter, inventoryDataUploadCounter } = useCouter();

  useEffect(() => {
    const storedState = loadState();

    storedState?.fixedAmenitiesList?.length > 0 &&
      setFixedAmenitiesList(storedState?.fixedAmenitiesList);

    storedState?.currenciesList &&
      Object.keys(storedState?.currenciesList)?.length > 0 &&
      setCurrencies(storedState?.currenciesList);
  }, []);

  const SELECTED_AMENITIES = useMemo(
    () =>
      fixedAmenitiesList?.length
        ? [
            { name: SELECT_ALL, selected: false },
            ...fixedAmenitiesList.map((amenity) => ({
              name: amenity?.name,
              selected: false,
            })),
          ]
        : [],
    [fixedAmenitiesList],
  );

  const [amenitiesData, setAmenitiesData] = useState(SELECTED_AMENITIES);

  if (!!SELECTED_AMENITIES?.length > 0 && amenitiesData?.length === 0) {
    setAmenitiesData(SELECTED_AMENITIES);
  }

  const schema = useMemo(() => {
    const schemaObj = yup.object().shape({
      provider: yup.string().required('Provider Name is required'),
      emailid: yup
        .string()
        .nullable(true)
        .notRequired()
        .test('emailid is invalid', 'Please enter valid email(s)', (value) => {
          if (!value) {
            return true;
          }

          const emails = value.split(',').map((email) => email.trim());

          // const emailRegex = /[a-z0-9A-Z.-]+@[a-zA-Z]+\.[a-zA-Z]{2,3}/;
          const emailRegex = /^(?!\d+@)\w+([-+.']\w+)*@(?!\d+\.)\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

          return emails.every((email) => emailRegex.test(email));
        }),
      address: !isEditOption
        ? yup.string().required('Address is required')
        : yup.string().nullable(true).notRequired(),
      city: !isEditOption
        ? yup.string().required('City is required')
        : yup.string().nullable(true).notRequired(),
      state: yup.string().nullable(true).notRequired(),
      zipCode: !isEditOption
        ? yup.string().required('Zip Code is required')
        : yup.string().nullable(true).notRequired(),
      country: !isEditOption
        ? yup.string().required('Country is required')
        : yup.string().nullable(true).notRequired(),
      region: !isEditOption
        ? yup.string().required('Region is required')
        : yup.string().nullable(true).notRequired(),
      currency: yup.string(),
      price: yup
        .string()
        .nullable(true)
        .notRequired()
        .test('is-integer', 'Price must be a positive number or an integer', (value) => {
          if (!value) {
            return true;
          }

          return /^[1-9]$|^[1-9][0-9]*$/.test(value);
        }),
      selectedDate: yup.string(),
      amneties: yup.string(),
      images: yup.string(),
    });

    return schemaObj;
  }, [isEditOption]);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      provider: '',
      emailid: '',
      address: '',
      formatted_address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      lat: '',
      long: '',
      region: '',
      currency: '',
      price: '',
      selectedDate: '',
      amneties: '',
      images: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const historyRoute = useHistory();

  useEffect(() => {
    setDefaultValue(
      isEditOption ? getValues()?.provider : userType === 'Provider' ? providerName : '',
    );
  }, [getValues, isEditOption, providerName, userType]);

  useEffect(() => {
    if (!loading && !isResetDatePickerLoc) {
      setIsResetDatePickerLoc(true);
    }
  }, [isResetDatePickerLoc, loading]);

  useEffect(() => {
    if (isEditOption) {
      Object.keys(currentRow).forEach((fieldName) => {
        if (fieldName === 'provider_name') {
          setValue('provider', currentRow[fieldName]);
        }
        if (fieldName === 'provider_contact_info') {
          const emails = currentRow[fieldName] && currentRow[fieldName]?.map((obj) => obj.email);

          setValue('emailid', emails.join(', '));
        }
        if (fieldName === 'formatted_address') {
          setValue(fieldName, currentRow[fieldName]);
        }
        if (fieldName === 'address') {
          if (currentRow[fieldName]) {
            setValue(fieldName, currentRow[fieldName]);
          } else {
            setValue(fieldName, currentRow['formatted_address']);
          }
        }
        if (fieldName === 'city') {
          setValue(fieldName, currentRow[fieldName]);
        }
        if (fieldName === 'state') {
          setValue(fieldName, currentRow[fieldName]);
        }
        if (fieldName === 'country') {
          setValue(fieldName, currentRow[fieldName]);
        }
        if (fieldName === 'zipPostal') {
          setValue('zipCode', currentRow[fieldName]);
        }
        if (fieldName === 'region') {
          setValue(fieldName, currentRow[fieldName]);
        }
        if (fieldName === 'provider_id') {
          setValue(fieldName, currentRow[fieldName]);
        }
        if (fieldName === 'floor_pricing' && !!currentRow?.floor_pricing) {
          setValue('currency', currentRow?.floor_pricing['pricing_currency']);
          setValue('price', currentRow?.floor_pricing['pricing_1_month_term']);
        }
        if (fieldName === 'availabilityDate') {
          setValue(fieldName, moment(currentRow[fieldName]).format('DD-MMM-YYYY'));
          setValue('selectedDate', moment(currentRow[fieldName]).format('DD-MMM-YYYY'));
        }
      });
    }
  }, [currentRow, setValue, isEditOption]);

  const onSubmit = async (data) => {
    setLocationAlreadyExists(false);
    const url = `${process.env.REACT_APP_PROVIDER_FILE_UPLOAD_URL}/providerlocation`;
    let reqData = {};
    const userDataStore = JSON.parse(sessionStorage.getItem('USER_DATA'));

    if (!isEditOption) {
      reqData = {
        provider_name: data?.provider,
        address: data?.address,
        formatted_address: data?.formatted_address,
        lng: data?.long,
        lat: data?.lat,
        email: data?.emailid,
        floor_amenities: data?.amneties,
        suite_amenities: data?.amneties,
        region: data?.region,
        city: data?.city,
        state: data?.state,
        zipPostal: data?.zipCode,
        country: data?.country,
        floor_pricing: { pricing_currency: data?.currency, pricing_1_month_term: data?.price },
        availabilityDate: moment(data.selectedDate).isValid()
          ? moment(data.selectedDate).format('YYYY-MM-DD')
          : '',
        picture_path: data.images,

        status: 'Active',
        source_system: ['RESEARCH'],
        coordinates: {
          Latitude: data?.lat,
          Longitude: data?.long,
        },
        soft_delete_summary: {
          reason: '',
          is_softdeleted: 'no',
        },
        provider_contact_info: [
          {
            last_name: '',
            phone_number: '',
            first_name: '',
            email: data?.emailid,
          },
        ],
        property_id: {
          baidu_uid: '',
          research_property_id: '',
          google_place_id: '',
        },
        createdBy: userDataStore['User email'],
      };
    } else {
      const isFormattedAddressChanged = () => {
        return currentRow['formatted_address'] !== data['formatted_address'];
      };

      const isValuesAreChanged = (val) => {
        return currentRow[val] !== data[val] && data[val] !== null;
      };

      reqData = {
        provider_id: data?.provider_id,
        updatedBy: userDataStore['User email'],
        provider_name: data?.provider,
        provider_contact_info: [
          {
            last_name: '',
            phone_number: '',
            first_name: '',
            email: data?.emailid,
          },
        ],
        floor_pricing: { pricing_currency: data?.currency, pricing_1_month_term: data?.price },
        picture_path: data.images,
        floor_amenities: data?.amneties,
        suite_amenities: data?.amneties,
        availabilityDate: moment(data.selectedDate).isValid()
          ? moment(data.selectedDate).format('YYYY-MM-DD')
          : '',
        building_id: currentRow['building_id'],
        floor_id: currentRow['floor_id'],
        suite_id: currentRow['suite_id'],
      };

      const fieldsToCheckAddress = ['city', 'state', 'country', 'region'];

      if (isFormattedAddressChanged()) {
        reqData['formatted_address'] = data['formatted_address'];
      }

      if (isFormattedAddressChanged() && isValuesAreChanged('address')) {
        reqData['address'] = data['address'];
      }

      fieldsToCheckAddress.forEach((field) => {
        if (isValuesAreChanged(field)) {
          reqData[field] = data[field];
        }
      });

      if (currentRow['zipPostal'] !== data['zipCode'] && data['zipCode'] !== null) {
        reqData['zipPostal'] = data['zipCode'];
      }
    }

    if (!moment(data.selectedDate).isValid()) {
      delete reqData.availabilityDate;
    }

    setLoading(true);

    try {
      let data = null;

      if (isEditOption) {
        ({ data } = await axios.put(url, reqData, {
          headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
        }));
        inventoryRecordupdateCounter();
      } else {
        ({ data } = await axios.post(url, reqData, {
          headers: APPLICATION_HEADERS.agileBaseLocationsAPIKey,
        }));

        inventoryDataUploadCounter();
      }

      if (isEditOption) {
        if (data?.locationAlreadyExists) {
          onSuccessEditLocationAlreadyExists();
        } else {
          onSuccessEditLocation();
        }
      }

      setAddedCount(1);
      setCurrentStage(STAGES.ADD_SUCCESS);
      setAmenitiesData(SELECTED_AMENITIES);
      setIsSuccessSnackBar(true);
      if (data?.locationAlreadyExists) {
        setLocationAlreadyExists(true);
      }
      setCurrencySymbol('');
      setUploadedImages([]);
    } catch (e) {
      console.error('Occured error while trying to add custom option - ', e);
      setIsErrorSnackBar(true);
    } finally {
      setValue('selectedDate', '');
      setIsResetDatePickerLoc(false);
      setLoading(false);
      onClose();
      reset();
      if (userType === 'Provider') {
        setValue('provider', providerName);
      }
      formRef.current.scrollIntoView({ behavior: 'smooth' });
      setIsAccorianLocOpen(true);
    }
  };

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  const addressRef = React.useRef(null);
  const debouncedAddress = useDebounce(values.address, 400);

  const inputRef = useRef();

  useEffect(() => {
    if (userType == 'Provider') {
      setValue('provider', providerName);
    }
  }, [userType]);

  const sortedCurrencies = React.useMemo(() => {
    const sorted = Object.entries(currencies ?? {}).sort((a, b) => {
      // Check if either currency is USD and prioritize it
      if (a[0]?.toLowerCase() === 'usd') return -1;
      if (b[0]?.toLowerCase() === 'usd') return 1;

      // If neither currency is USD, perform alphabetical sorting
      const firstCurrency = a[0]?.toLowerCase();
      const secondCurrency = b[0]?.toLowerCase();

      // USD currency should be at the top of the list
      if (firstCurrency.toLowerCase() === 'usd') return -1;
      if (firstCurrency > secondCurrency) return 1;
      if (firstCurrency < secondCurrency) return -1;

      return 0;
    });

    return sorted;
  }, [currencies]);

  const sortedRegion = [
    { name: 'APAC', region: 'APAC' },
    { name: 'NAM (US & Canada)', region: 'NAM' },
    { name: 'EMEA', region: 'EMEA' },
    { name: 'LATAM', region: 'LATAM' },
  ];

  const getAddressComponent = useCallback((components, type) => {
    const result = components.find((component) => component.types.includes(type));

    return result ? result.long_name : '';
  }, []);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();

    if (place) {
      let { city, state, zipCode, country } = ''; // Onchange address input, need to clear the existing values of address input fields.
      const { address_components } = place;

      city = getAddressComponent(address_components, 'locality');
      state = getAddressComponent(address_components, 'administrative_area_level_1');
      zipCode = getAddressComponent(address_components, 'postal_code');
      country = getAddressComponent(address_components, 'country');
      setValue('address', place.name);
      setValue('formatted_address', place.formatted_address);
      setValue('lat', place.geometry.location.lat());
      setValue('long', place.geometry.location.lng());
      setValue('city', city);
      setValue('state', state);
      setValue('zipCode', zipCode);
      // if (zipCode) {
      //   handleFieldFocus('zipCode');
      // }
      setValue('country', country);

      clearErrors(['address', 'city', 'state', 'zipCode', 'country']);
    }
  };

  const handleCloseWindow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    reset();
    setValue('selectedDate', '');
    setIsResetDatePickerLoc(false);
    setAmenitiesData(SELECTED_AMENITIES);
    setCurrencySymbol('');
    setUploadedImages([]);
    setSuggestions({
      selected: {},
      suggestionsData: {},
      suggestionsListOpened: false,
    });
    setCurrentStage(STAGES.ADD_FORM);
    setLoading(false);
    onClose(addedCount);

    if (userType === 'Provider') {
      setValue('provider', providerName);
    }
    formRef.current.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      setIsAccorianLocOpen(true);
    }, 10);
  };

  const handleCloseDropdown = () => {
    setSuggestions((prev) => ({ ...prev, suggestionsListOpened: false }));

    if (values?.address?.trim() === '') return;

    if (suggestions.selected?.place_name && suggestions.selected?.place_name !== values.address) {
      setValues((prev) => ({
        ...prev,
        address: suggestions.selected?.place_name,
      }));
      setIsSelected(true);
    }
  };

  useOutsideClick(addressRef, handleCloseDropdown);

  React.useEffect(() => {
    if (isSuccessSnackBar) {
      onClose();
      setValues(defaultInputData);
      setSuggestions(suggestionsDefault);
    }
  }, [isSuccessSnackBar]);

  React.useEffect(() => {
    if (!debouncedAddress) {
      handleCloseDropdown();

      return;
    }

    if (isSelected) {
      setIsSelected(false);

      return;
    }
  }, [debouncedAddress]);

  //   const { setSnackBars } = useSnackBar();
  const [uploadingProgress, setUploadingProgress] = React.useState(0);
  const [progressImageUpload, setProgressImageUpload] = useState(0);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [currentImage, setCurrentImage] = React.useState(null);
  const [isCurrentImageModalOpen, setIsCurrentImageModalOpen] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [urlsText, setUrlsText] = useState('');
  const [urlsListLinks, setUrlsListLinks] = useState([]);
  const [dragOverImages, setDragOverImages] = React.useState({
    floorPlan: false,
    locationPlan: false,
  });
  const [isHoveredImage, setIsHoveredImage] = useState({});
  const [locationAlreadyExists, setLocationAlreadyExists] = useState(false);

  const validationResults = useImageUrlValidation(urlsListLinks);

  const updateImagesPostData = useCallback(
    (imagesArray) => {
      const imageURLsCommaSeperate = imagesArray
        ?.filter((imgObj) => imgObj?.imgURL)
        .map((imgObj) => imgObj?.imgURL)
        .join(',');

      setValue('images', imageURLsCommaSeperate);
    },
    [setValue],
  );

  const setAmenitiesDataCB = useCallback((Amenities) => {
    setAmenitiesData((prevAmenitiesData) => {
      let newAmenitiesData = [...Amenities];

      newAmenitiesData = newAmenitiesData.map((amenity) => {
        if (amenity) {
          return {
            name: amenity.name,
            selected: amenity.selected,
          };
        }

        return amenity;
      });

      setValue(
        'amneties',
        newAmenitiesData
          .filter((amn) => amn?.selected)
          .map((amn) => amn?.name)
          .join(','),
      );

      return newAmenitiesData;
    });
  }, []);

  // only single time code executed for Edit location

  useEffect(() => {
    if (!isEditOption || fixedAmenitiesList?.length === 0 || !currentRow['floor_amenities']) {
      return;
    }

    const defaultAmen = fixedAmenitiesList?.length
      ? [
          { name: SELECT_ALL, selected: false },
          ...fixedAmenitiesList.map((amenity) => ({
            name: amenity?.name,
            selected: false,
          })),
        ]
      : [];

    const strAmentiesConverssion = strToArray(currentRow['floor_amenities']);

    const finalAmenetiesArray = defaultAmen.map((obj, index) => {
      if (strAmentiesConverssion.includes(obj?.name)) {
        obj.selected = true;
      }

      return obj;
    });

    setAmenitiesDataCB(finalAmenetiesArray);
  }, [
    fixedAmenitiesList,
    currentRow,
    isEditOption,
    setAmenitiesDataCB,
    fixedAmenitiesList?.length,
  ]);

  useEffect(() => {
    if (!isEditOption) {
      return;
    }
    const strImageConverssion = strToArray(currentRow['picture_path']);

    if (strImageConverssion?.length > 0) {
      const changeImagesAssr = strImageConverssion
        ?.filter((obj) => {
          return !!obj;
        })
        .map((obj) => {
          return {
            imgURL: obj,
            url: obj,
            isUploading: false,
            isCompleted: true,
          };
        });

      setUploadedImages(changeImagesAssr);
      const imageURLsCommaSeperate = changeImagesAssr?.map((imgObj) => imgObj?.imgURL).join(',');

      setValue('images', imageURLsCommaSeperate);
    }
  }, []);

  // console.log('uploadedImages', uploadedImages);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (validationResults?.length > 0) {
        const validUrlsList = validationResults
          ?.filter((obj) => {
            return !!obj.valid;
          })
          .map((obj) => {
            return {
              imgURL: obj?.url,
              ...obj,
              isUploading: false,
              isCompleted: true,
            };
          });

        setUploadedImages((prev) => {
          const concatenateTwoArray = [...prev, ...validUrlsList];

          updateImagesPostData(concatenateTwoArray);

          return concatenateTwoArray;
        });
        setUrlsListLinks([]);

        const unvalidURLSCount = validationResults?.length - validUrlsList?.length;

        if (!!unvalidURLSCount && unvalidURLSCount > 0) {
          setIsErrorSnackBarImageURLs(true);
          setImageError(
            `${unvalidURLSCount} out of ${validationResults.length} images uploaded do not have valid image urls. Please verify and upload again.`,
          );
        }
      }
      setUrlsText('');
      setOnProcessUrls(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [validationResults, updateImagesPostData]);

  const handleUploadImages = useCallback(
    async (e, type) => {
      const { errorsLength, images, errors } = UploadImageService.validateEventImages(e);

      if (errors?.length > 0) {
        setIsErrorSnackBarImageURLs(true);
        setImageError(`${errors[0]}`);
      }

      if (errorsLength !== 0 || images?.length === 0) return;

      if (
        images.length > MAX_ALLOWED_LOCATION_IMAGES ||
        uploadedImages?.length + images.length > MAX_ALLOWED_LOCATION_IMAGES
      ) {
        setIsErrorSnackBarImageURLs(true);

        setImageError(
          `You can upload only ${MAX_ALLOWED_LOCATION_IMAGES} images for location images`,
        );
      }

      const compressingService = new CompressingImageService();
      const modifiedFields = images.map((file) => {
        compressingService.addImage(file);

        return { url: file };
      });

      setUploadedImages((prev) => [...prev, ...modifiedFields]);

      const formData = await compressingService.getCompressedImages();

      await UploadImageService.upload(formData, {
        onSuccess: (response) => {
          let resultImages;

          if (Object.keys(response?.data)?.length > 0) {
            resultImages = Object.entries(response.data).map(([name, url]) => {
              return { url };
            });
          }
          setUploadedImages((prev) => {
            const updatedData = prev.map((item) => {
              const match = resultImages?.find((obj) => {
                const isMatch =
                  obj?.url?.split('_')?.slice(1)[0] ===
                  item?.url?.name?.replace(/[^a-zA-Z0-9.]/g, '');

                return isMatch;
              });
              let newItem = { ...item };

              if (!newItem?.isCompleted) {
                newItem = { ...newItem, isUploading: false, isCompleted: true };
              }

              if (match) {
                newItem = { ...newItem, imgURL: match?.url };
              }

              return newItem;
            });

            updateImagesPostData(updatedData);

            return updatedData;
          });
        },
        onFail: (msg) => {
          if (msg && msg.length > 0) {
            setUploadedImages((prev) => {
              return prev.filter((obj) => {
                return obj?.isCompleted;
              });
            });
            setIsErrorSnackBarImageURLs(true);
            setImageError(
              `Please try uploading single images one after the other. If the issue still persists, please convert the image to .JPEG or .JPG and try uploading the image again.`,
            );
          } else {
            setIsErrorSnackBarImageURLs(true);
            setImageError(`Something went wrong`);
          }
        },
        onUploadProgress: (progressEvent) => {
          setProgressImageUpload(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        },
      });
    },
    [
      updateImagesPostData,
      // setSnackBars,
      uploadedImages?.length,
    ],
  );

  const handleDeleteImage = useCallback(
    (e, index, url) => {
      e.stopPropagation();
      setUploadedImages((prev) => {
        const updatedData = prev.filter(
          (item, itemIndex) => !(item?.imgURL === url && index === itemIndex),
        );

        updateImagesPostData(updatedData);

        return updatedData;
      });
      setIsHoveredImage((prev) => ({ ...prev, [index]: false }));
    },
    [updateImagesPostData],
  );

  const handleImageClick = useCallback(
    (e, index) => {
      e.stopPropagation();
      setCurrentImage(uploadedImages[index]?.imgURL);
      setIsCurrentImageModalOpen(true);
    },
    [uploadedImages],
  );

  const handleDrag = (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragOverImages((prev) => ({ ...prev, [type]: true }));
    }

    if (e.type === 'dragleave') {
      setDragOverImages((prev) => ({ ...prev, [type]: false }));
    }
  };

  const handleChangeURL = useCallback((e) => {
    const newUrls = e.target?.value.split(',').map((url) => {
      return { url: url.trim() };
    });

    if (newUrls?.length > 0) {
      setUrlsListLinks((prev) => [...prev, ...newUrls]);
      setUrlsText('Validating image URLs...');
      setOnProcessUrls(true);
    }
  }, []);

  const handleChangeAmenity = React.useCallback(
    (amenityName) => {
      setAmenitiesData((prevAmenitiesData) => {
        let newAmenitiesData = [...prevAmenitiesData];

        if (amenityName === SELECT_ALL) {
          const allAmenities = newAmenitiesData.find((amenity) => amenity.name === SELECT_ALL);

          if (allAmenities) {
            newAmenitiesData = newAmenitiesData.map((amenity) => ({
              ...amenity,
              selected: !allAmenities.selected,
            }));
          }
        } else {
          newAmenitiesData = newAmenitiesData.map((amenity) => {
            if (
              amenity &&
              amenity.name &&
              amenity.name.toLowerCase().trim() === amenityName.toLowerCase().trim()
            ) {
              return {
                ...amenity,
                selected: !amenity.selected,
              };
            }

            return amenity;
          });

          const isAllSelected = newAmenitiesData
            .filter((amenity) => amenity.name !== SELECT_ALL)
            .every((amenity) => amenity.selected);

          newAmenitiesData = newAmenitiesData.map((amenity) => {
            if (amenity.name === SELECT_ALL) {
              return {
                ...amenity,
                selected: isAllSelected,
              };
            }

            return amenity;
          });
        }
        setValue(
          'amneties',
          newAmenitiesData
            .filter((amn) => amn?.selected && amn?.name !== SELECT_ALL)
            .map((amn) => amn?.name)
            .join(','),
        );

        return newAmenitiesData;
      });
    },
    [setValue],
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const openNewLocationModal = () => {
    reset();
    setCurrentStage(STAGES.ADD_FORM);
    setLoading(false);
  };

  const handleFieldFocus = (fieldName) => {
    clearErrors(fieldName);
  };
  const handleFieldBlur = async (fieldName) => {
    await trigger(fieldName);
  };
  const handlePriceChange = (event) => {
    let cleanValue = event?.target?.value.replace(/[^0-9]/g, '');

    cleanValue = cleanValue.replace(/^0+/, '');

    setValue('price', cleanValue);
  };

  const currencyHandleChange = (event) => {
    const targetString = event.target?.value;
    const result = sortedCurrencies?.find((subArray) => subArray[0] === targetString)?.[1] ?? null;
    const lastCharacter = result?.trim()?.split('-').pop()?.trim() ?? null;

    !!lastCharacter && setCurrencySymbol(lastCharacter);
  };

  const handleMouseEnterUploadedImage = useCallback(
    (index) => setIsHoveredImage((prev) => ({ ...prev, [index]: true })),
    [],
  );
  const handleMouseLeaveUploadedImage = useCallback(
    (index) => setIsHoveredImage((prev) => ({ ...prev, [index]: false })),
    [],
  );

  const uploadedImagesElements = React.useMemo(() => {
    return uploadedImages?.map((image, index) => (
      <UploadedImageBlockComponent
        key={`${image?.url}-${index}`}
        index={index}
        image={image}
        isHoveredImage={isHoveredImage[index]}
        handleMouseEnterUploadedImage={handleMouseEnterUploadedImage}
        handleMouseLeaveUploadedImage={handleMouseLeaveUploadedImage}
        handleDeleteImage={handleDeleteImage}
        progressImageUpload={uploadingProgress}
        handleImageClick={handleImageClick}
      />
    ));
  }, [
    uploadedImages,
    handleMouseEnterUploadedImage,
    handleMouseLeaveUploadedImage,
    handleDeleteImage,
    isHoveredImage,
    uploadingProgress,
    handleImageClick,
  ]);

  const renderFormContent = () => (
    <Block
      ref={formRef}
      display="flex"
      flexDirection="column"
      gap="30"
      margin={isEditOption ? '0' : '30px 24px 60px 0'}
    >
      <OptionTitleBlock className="optionMainBlock">
        <OptionTitle>{isEditOption ? 'Edit' : 'Add New'} Location</OptionTitle>
        {isEditOption && (
          <ClosedModalButton onClick={handleCloseWindow}>
            <img src={closeIcon} />
          </ClosedModalButton>
        )}
      </OptionTitleBlock>

      <form onSubmit={handleSubmit(onSubmit)}>
        <BlockChildContainer>
          <Controller
            name="provider"
            // ref={providerAutoRef}
            control={control}
            render={({ field: { onBlur, onChange, value } }) => (
              <Autocomplete
                freeSolo
                options={sortAlphabeticalStrings('providerName', majorProvidersList)}
                value={
                  isEditOption
                    ? getValues()?.provider
                    : userType === 'Provider'
                    ? providerName
                    : '' || value
                }
                disabled={userType == 'Provider'}
                getOptionLabel={(option) =>
                  typeof option === 'object' ? option.providerName : option
                }
                onInputChange={(_, newValue) => {
                  onChange(newValue || '');
                }}
                onFocus={() => handleFieldFocus('provider')}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.provider)}
                    helperText={errors.provider ? errors.provider.message : ''}
                    variant="outlined"
                    fullWidth
                    label="Provider Name*"
                    onKeyDown={handleKeyDown}
                  />
                )}
                clearIcon={null}
                popupIcon={null}
              />
            )}
          />
          <div>
            <Controller
              name="emailid"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.emailid)}
                  helperText={errors.emailid ? errors.emailid.message : ''}
                  variant="outlined"
                  fullWidth
                  label="Email"
                  onFocus={() => handleFieldFocus('emailid')}
                  onBlur={() => handleFieldBlur('emailid')}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                  }}
                />
              )}
            ></Controller>
            <div style={{ fontSize: '12px', margin: '3px 13px' }}>
              Commas can be used to separate multiple emails.
            </div>
          </div>
        </BlockChildContainer>

        <OptionTitleBlock>
          <OptionTitle>Address</OptionTitle>
        </OptionTitleBlock>

        <BlockChildContainer>
          <div>
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="address"
                    error={Boolean(errors.address)}
                    helperText={errors.address ? errors.address.message : ''}
                    variant="outlined"
                    fullWidth
                    label={isEditOption ? 'Address' : 'Address*'}
                    onFocus={() => handleFieldFocus('address')}
                    onBlur={() => handleFieldBlur('address')}
                    placeholder=""
                    onKeyDown={handleKeyDown}
                    InputLabelProps={{
                      shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                    }}
                  />
                )}
              />
            </StandaloneSearchBox>
          </div>

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : ''}
                variant="outlined"
                fullWidth
                label={isEditOption ? 'City' : 'City*'}
                onFocus={() => handleFieldFocus('city')}
                onBlur={() => handleFieldBlur('city')}
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                }}
              />
            )}
          />

          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.state)}
                helperText={errors.state ? errors.state.message : ''}
                variant="outlined"
                fullWidth
                label="State"
                onFocus={() => handleFieldFocus('state')}
                onBlur={() => handleFieldBlur('state')}
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                }}
              />
            )}
          />

          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode ? errors.zipCode.message : ''}
                variant="outlined"
                fullWidth
                label={isEditOption ? 'Zip Code' : 'Zip Code*'}
                // autoFocus={false}
                onFocus={() => handleFieldFocus('zipCode')}
                onBlur={() => handleFieldBlur('zipCode')}
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                }}
              />
            )}
          />

          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.country)}
                helperText={errors.country ? errors.country.message : ''}
                variant="outlined"
                fullWidth
                // label="Country"
                label={isEditOption ? 'Country' : 'Country*'}
                onFocus={() => handleFieldFocus('country')}
                onBlur={() => handleFieldBlur('country')}
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: (field.value !== '' && field.value !== null) || field.onFocus,
                }}
              />
            )}
          />

          <Controller
            name="region"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                error={Boolean(errors.region)}
                helperText={errors.region ? errors.region.message : ''}
                variant="outlined"
                onFocus={() => handleFieldFocus('region')}
                fullWidth
                // label="Region"
                label={isEditOption ? 'Region' : 'Region*'}
                onKeyDown={handleKeyDown}
              >
                {sortAlphabeticalStrings('name', sortedRegion)?.map((option) => (
                  <MenuItem
                    className="muiCustomMenuStyles"
                    key={option.region}
                    value={option.region}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </BlockChildContainer>

        <AdditionalInformationWrapper>
          <Accordion
            defaultIsOpen={isEditOption ? true : isAccorianLocOpen}
            title="Additional Information"
            id="additionalInformationGroup"
          >
            <AccordionContentContainer>
              <BlockChildContainer>
                <Controller
                  name="currency"
                  control={control}
                  render={({ onChange, field }) => (
                    <TextField
                      {...field}
                      select
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.currency)}
                      helperText={errors.currency ? errors.currency.message : ''}
                      label="Currency"
                      onChange={(e) => {
                        field.onChange(e);
                        currencyHandleChange(e);
                      }}
                      onKeyDown={handleKeyDown}
                    >
                      {sortedCurrencies?.map(([currency, name], index) => {
                        return (
                          <MenuItem className="muiCustomMenuStyles" key={index} value={currency}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />

                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.price)}
                      value={getValues().price}
                      helperText={errors.price ? errors.price.message : ''}
                      variant="outlined"
                      fullWidth
                      label="Price Per Month For 1 Month Term"
                      onChange={handlePriceChange}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      onFocus={() => handleFieldFocus('price')}
                      onBlur={() => handleFieldBlur('price')}
                      InputProps={{
                        startAdornment:
                          currencySymbol && currencySymbol.trim() !== '' ? (
                            <InputAdornment position="start">
                              <Typography variant="body1">{currencySymbol}</Typography>
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                  )}
                />

                <Controller
                  key={isResetDatePickerLoc}
                  name="selectedDate"
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <MaterialDatePicker
                      label="Availability Date"
                      handleChange={(date) => {
                        setValue('selectedDate', date);
                      }}
                      disablePast
                      inputFormat="DD-MMM-YYYY"
                      dateFormat="YYYY-MM-DD"
                      selectedDate={getValues()?.selectedDate}
                      paddingContainer="2.5px 10px"
                      borderRadiusContainer="4px"
                      error={Boolean(errors.selectedDate)}
                      helperText={errors.selectedDate ? errors.selectedDate.message : ''}
                      onKeyDown={handleKeyDown}
                      isResetDatePickerLoc
                    ></MaterialDatePicker>
                  )}
                />
              </BlockChildContainer>

              <OptionTitleBlock>
                <OptionTitle>Amenities</OptionTitle>
              </OptionTitleBlock>

              <BlockChildContainer>
                <CheckboxContainer>
                  {amenitiesData?.map((amenity, index) => {
                    return (
                      <Block display="inline-flex" gap="20" key={index}>
                        <CheckBox
                          checked={amenity.selected}
                          onChange={() => handleChangeAmenity(amenity.name)}
                        />
                        <AmenityName>{amenity.name}</AmenityName>
                      </Block>
                    );
                  })}
                </CheckboxContainer>
              </BlockChildContainer>

              <OptionTitleBlock>
                <OptionTitle>Location Images</OptionTitle>
              </OptionTitleBlock>

              <ImageWrapperContainer>
                <FileUpload onUpload={(e) => handleUploadImages(e)} addNewLocation="true" />

                {dragOverImages?.locationPlan && (
                  <OnDragOverBlock
                    onDragOver={(e) => handleDrag(e, 'locationPlan')}
                    onDragLeave={(e) => handleDrag(e, 'locationPlan')}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleUploadImages(e, 'locationImages');
                      setDragOverImages((prev) => ({
                        ...prev,
                        locationPlan: false,
                      }));
                    }}
                  >
                    <OnDragOverBlockBorder>
                      <OnDragOverBlockText>Drop images to upload</OnDragOverBlockText>
                    </OnDragOverBlockBorder>
                  </OnDragOverBlock>
                )}
              </ImageWrapperContainer>

              <div
                style={{
                  display: 'grid',
                  textAlign: 'center',
                  margin: '10px 0',
                }}
              >
                OR
              </div>

              <BlockChildContainer
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  margin: '0 0 30px',
                  gap: '5px',
                }}
              >
                <TextField
                  id="urls"
                  variant="outlined"
                  value={urlsText}
                  className={onProcessUrls ? 'hashTextValidating' : ''}
                  onChange={handleChangeURL}
                  fullWidth
                  label="Image URLs"
                  onKeyDown={handleKeyDown}
                />
                <span style={{ fontSize: '12px' }}>
                  *If you are uploading multiple images , please enter comma separated image URLs
                </span>
              </BlockChildContainer>

              <UploadedImagesContainer>{uploadedImagesElements}</UploadedImagesContainer>
            </AccordionContentContainer>
          </Accordion>
        </AdditionalInformationWrapper>

        <ButtonContainer>
          {isErrorSnackBar && (
            <SnackBar
              title={imageError}
              onClose={() => setIsErrorSnackBar(false)}
              delay={5000}
              isError
            />
          )}
          <SecondaryButton onClick={handleCloseWindow}>
            {!isEditOption ? 'Clear' : 'Cancel'}
          </SecondaryButton>
          <PrimaryButton disabled={loading}>
            {loading ? <CircularProgress size={20} /> : !isEditOption ? 'Add' : 'Update'}
          </PrimaryButton>
        </ButtonContainer>
      </form>
    </Block>
  );

  return (
    <>
      {isEditOption ? (
        <ModalScreen
          isOpen={isOpen}
          widthProps={{ width: currentStage == STAGES.ADD_FORM ? '80%' : '40%' }}
          onClose={handleCloseWindow}
          withoutCloseBtn
        >
          {renderFormContent()}
        </ModalScreen>
      ) : (
        renderFormContent()
      )}

      <SnackBarContainer>
        {isSuccessSnackBar && !locationAlreadyExists && (
          <SnackBar
            title="Location added successfully"
            onClose={() => setIsSuccessSnackBar(false)}
            delay={5000}
            customClass="snackBarBlock"
          />
        )}

        {isSuccessSnackBar && locationAlreadyExists && (
          <SnackBar
            title={LOCATION_ALREADY_EXIST}
            onClose={() => setIsSuccessSnackBar(false)}
            delay={8000}
            isError
            customClass="snackBarBlock"
          />
        )}
        {isErrorSnackBarImageURLs && (
          <SnackBar
            title={imageError}
            onClose={() => setIsErrorSnackBarImageURLs(false)}
            delay={8000}
            isError
            customClass="snackBarBlock"
          />
        )}
      </SnackBarContainer>
      <Block>
        {isCurrentImageModalOpen && (
          <Lightbox medium={currentImage} onClose={() => setIsCurrentImageModalOpen(false)} />
        )}
      </Block>
    </>
  );
};

export const AmenitiesSubtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  &:nth-child(1) {
    font-weight: 600;
  }
`;

const AmenityName = styled(AmenitiesSubtitle)``;
const SnackBarContainer = styled.div`
  position: relative;
  & .snackBarBlock {
    position: fixed;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%);
  }
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 230px);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  & div:first-child > div:nth-child(2) {
    font-weight: 600;
  }

  margin-bottom: 10px;
  max-width: 80%;

  @media screen and (max-width: 1700px) {
    grid-template-columns: repeat(5, 230px);
  }

  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(4, 200px);
  }

  @media screen and (max-width: 1150px) {
    grid-template-columns: repeat(4, 170px);
  }

  @media screen and (max-width: 870px) {
    grid-template-columns: repeat(3, 150px);
  }
`;

const ImageWrapperContainer = styled.div`
  display: grid;
`;
const BlockChildContainer = styled.div`
  ::-webkit-scrollbar {
    height: 0;
  }
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  position: relative;

  .hashTextValidating {
    & .MuiInputBase-input {
      color: #999;
    }
  }
  .CheckBoxLocationHeading {
    grid-column: 1/ -1;
    margin-top: 25px;
  }

  .MuiAutocomplete-input {
    padding: 6.5px 5px 6px 7px !important;
  }

  .MuiFormControl-root {
    .MuiFormLabel-root {
      line-height: 1em;
      color: #1a1a1a60 !important;
    }
    .MuiFormLabel-filled,
    .MuiInputLabel-shrink {
      line-height: 1.6em;
    }
    .MuiInputBase-formControl {
      line-height: 1em;
      background: #fff;
      .MuiInputAdornment-root {
        svg {
          width: 0.7em;
          height: 0.7em;
        }
      }
      &.MuiInputBase-adornedStart {
        .MuiInputAdornment-root {
          color: rgba(0, 0, 0, 0.87);
        }
        .MuiOutlinedInput-input {
          padding-left: 0px;
        }
      }
      .MuiOutlinedInput-input {
        height: 1em;
        padding: 15px 14px;
        min-height: auto;
      }
    }
  }
`;

const AdditionalInformationWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  position: relative;
  margin: 30px 0 0;
  box-sizing: border-box;
  > div {
    box-sizing: border-box;
    width: 100%;
    display: grid;
  }
`;
const AccordionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0;
  box-sizing: border-box;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 30px;
`;

const OptionTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 15px;
  &.optionMainBlock {
    margin: 0;
    & div {
      font-size: 24px;
    }
  }
`;

const Title = styled.div`
  font-weight: 500;
  color: #1a1a1a;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize || '20px'};
`;

const OptionTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
`;

const ClosedModalButton = styled.div`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;

// For Image upload

const UploadedImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  z-index: 10;
`;

const OnDragOverBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(26, 26, 26, 0.46);
  border-radius: 4px;
  top: 0;
  left: 0;
  z-index: 10000000;
`;

const OnDragOverBlockBorder = styled.div`
  position: absolute;
  border: 3px dashed #e3e3e3;
  border-radius: 4px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  left: 25px;
  top: 25px;
`;

const OnDragOverBlockText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  color: #ffffff;
  transform: translate(-50%, -50%);
`;
const ErrorContainer = styled.div`
  position: absolute;
  z-index: 100;
  left: 25%;
  bottom: 8px;
  padding: 12px 16px;
  background: ${({ isError }) => (isError ? '#FBEEEE' : '#e6f4ec')};
  border: 1px solid ${({ isError }) => (isError ? 'none' : '#28573c')};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ErrorTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ isError }) => (isError ? '#A03530' : '#28573c')};
`;

export default NewOptionModal;
