import React, { useEffect } from 'react';

import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from '../../../assets/images/errorIcon.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/provider_locations/successIcon.svg';
import { Block } from '../StyledComponents/StyledBlock';

const ErrorSnackBar = ({ title, delay, onClose, isError, customClass }) => {
  useEffect(() => {
    const onCloseTimeout = setTimeout(onClose, delay);

    return () => {
      clearTimeout(onCloseTimeout);
    };
  }, []);

  return (
    <Container isError={isError} className={customClass}>
      <Block display="flex" gap="8">
        {isError ? <ErrorIcon /> : <SuccessIcon />}
        <Title isError={isError}>{title}</Title>
      </Block>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: 100;
  left: 25%;
  padding: 12px 16px;
  background: ${({ isError }) => (isError ? '#FBEEEE' : '#e6f4ec')};
  border: 1px solid ${({ isError }) => (isError ? 'none' : '#28573c')};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ isError }) => (isError ? '#A03530' : '#28573c')};
`;

export default ErrorSnackBar;
