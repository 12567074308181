import axios from 'axios';

import { APPLICATION_HEADERS, API_URLS } from '../common/headers';

export const ALLOWED_EXTENSIONS = ['png', 'svg', 'jpeg', 'jpg'];
export const MAX_ALLOWED_SIZE_OF_IMAGE_IN_BYTES = 10485760;

export class UploadImageService {
  static getExtension(fileName) {
    return fileName.split('.').pop();
  }

  static validateExtension(extension) {
    return ALLOWED_EXTENSIONS.includes(extension?.toLowerCase());
  }

  static validateEventImages(event, setSnackBars = () => {}) {
    const errors = [];
    const images = [];

    Array.prototype.forEach.call(event?.dataTransfer?.files ?? event.target.files, (image) => {
      const ext = UploadImageService.getExtension(image.name);
      const isExtAllowed = UploadImageService.validateExtension(ext);

      if (!isExtAllowed) {
        setSnackBars({
          success: false,
          fail: true,
          title: `Not allowed extension. Only ${ALLOWED_EXTENSIONS.join(', ')}.`,
        });
        errors.push(`Not allowed extension. Only ${ALLOWED_EXTENSIONS.join(', ')}.`);

        return;
      }

      images.push(image);
    });

    return { errorsLength: errors.length, images, errors };
  }

  static async upload(formData, { onSuccess, onFail, onUploadProgress }) {
    const url = `${process.env.REACT_APP_MK2_URL}/uploadimages`;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          ...APPLICATION_HEADERS.mkiiAPIKey,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });

      onSuccess(response);
    } catch (e) {
      console.error('Occured error while trying to upload images, ', e);
      e.request.readyState === 4 && e.request.status === 0
        ? onFail?.(`Image upload size issue`)
        : onFail?.();
    }
  }
}
