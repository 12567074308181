import React, { useState } from 'react';

import styled from 'styled-components';

import { PrimaryButton, SecondaryButton } from '../common/Button';
import ModalScreen from '../common/Modal';
import { Block } from '../common/StyledComponents/StyledBlock';

const reasons = ['Closed', 'Not a coworking space', 'Not available', 'Not interesting'];

const DeleteProfileModal = ({ isOpen, onClose, handleDelete, providerName }) => {
  return (
    <>
      <ModalScreen isOpen={isOpen} onClose={onClose} width="30%">
        <Container>
          <>
            <Block display="flex" flexDirection="column" gap="8">
              <Title>Are you sure you want to delete this provider profile ?</Title>
              {/* <Title fontSize="16px">Provider Name: {providerName}</Title> */}
            </Block>
            <Block display="flex" marginTop="25" gap="30">
              <BackButton onClick={onClose}>No</BackButton>
              <NextButton onClick={handleDelete}>Yes</NextButton>
            </Block>
          </>
        </Container>
      </ModalScreen>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const Title = styled.div`
  font-weight: 500;
  color: #1a1a1a;
  font-size: ${({ fontSize }) => fontSize || '20px'};
`;

export const BackButton = styled(SecondaryButton)`
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
  background: #fff;
  color: rgb(160, 53, 48);
  border: 1px solid rgb(160, 53, 48);
  &:hover {
    border: 1px solid rgb(160, 53, 48);
    cursor: pointer;
  }
`;
export const NextButton = styled(PrimaryButton)`
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
  background: rgb(160, 53, 48);
  color: #fff;
  border: 1px solid rgb(160, 53, 48);
  &:hover {
    background: rgb(160, 53, 48);
    cursor: pointer;
    color: #ffffff;
  }
`;
const Error = styled.div`
  color: #eb5534;
  font-size: 14px;
  font-weight: 400;
`;

export default DeleteProfileModal;
