import React, { Component, useEffect } from 'react';

import Modal from 'react-modal';
import { BrowserRouter as Router, Redirect, Route, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute } from '@okta/okta-react';

import AddLocations from './components/AddLocations/index';
import Analytics from './components/Analytics/AnalyticsPage';
import Locations from './components/Locations';
import './App.css';
import MySurveysPage from './components/MySurveys/MySurveysPage';
import OktaLogin from './components/OktaLogin';
import { IsLogin } from './components/OktaLogin/isLogin';
import CustomLoginCallback from './components/OktaLogin/LoginCallback';
import 'react-toastify/dist/ReactToastify.css';
import ProviderDashboard from './components/ProviderDashboard/ProviderDashboard';
import ProviderFileUpload from './components/ProviderFileUpload/ProviderFileUpload';
import ProviderLocations from './components/ProviderLocations/index';
import providerProfile from './components/ProviderProfile';
import ProviderTransactions from './components/ProviderTransactions/ProviderTransactions';
import RequestProvider from './components/RequestAccess/RequestProvider';
import history from './history';
import AuthContext from './store/auth-context';
import { ProviderLocationsContextProvider } from './store/ProviderLocationsContext';

toast.configure();
Modal.setAppElement('body');

const OKTA_LOGIN_CALLBACK_URL = '/';
const OKTA_AUTHORIZATION_URL = '/okta';
const MK2_REDIRECT_URL = window.location.origin + '/MKII';

const isProtectedRoutePath = () => {
  const pathNamesArray = window.location.pathname?.split('/');

  return pathNamesArray?.includes('ssologin');
};

class App extends Component {
  constructor(props) {
    super(props);

    this.oktaAuth = new OktaAuth({
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID.trim(),
      issuer: process.env.REACT_APP_OKTA_ISSUER_URL.trim(),
      redirectUri: MK2_REDIRECT_URL,
      scopes: ['offline_access', 'email', 'openid'],
      logoutUrl: process.env.REACT_APP_OKTA_LOGOUT_URL.trim(),
      postLogoutRedirectUri: MK2_REDIRECT_URL + '/logout',
    });

    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.push(toRelativeUrl('/MKII', MK2_REDIRECT_URL));
    };
  }

  componentDidMount() {
    let tabId = sessionStorage.getItem('tabId');

    // if (!tabId && isProtectedRoutePath()) {
    if (!tabId) {
      const existingTabId = localStorage.getItem('existingTabId');

      const timeLastUpdated = Date.now() - (localStorage.getItem('lastUpdated') || Date.now());

      // If existingTabId exists and it was updated in the last 5 minutes, it's a refresh.

      if (existingTabId && timeLastUpdated < 5 * 60 * 1000) {
        tabId = existingTabId;
      } else {
        // It's a new tab
        tabId = Date.now().toString();
      }
      sessionStorage.setItem('tabId', tabId);
    }

    const activeTabs = JSON.parse(localStorage.getItem('activeTabsMKII') || '[]');

    if (!activeTabs.includes(tabId)) {
      activeTabs.push(tabId);

      localStorage.setItem('activeTabsMKII', JSON.stringify(activeTabs));
    }

    if (window.location.pathname && window.location.pathname.includes('place')) {
      sessionStorage.setItem('prevPath', window.location.pathname.replace('MKII/', ''));
      localStorage.setItem('prevPath', window.location.pathname.replace('MKII/', ''));
    }

    window.addEventListener('beforeunload', () => {
      const isAuthenticatedCB = async () => {
        const isAuthenticated = await this.oktaAuthInitial?.isAuthenticated();

        return isAuthenticated;
      };

      if (!isAuthenticatedCB()) {
        return;
      }

      const loginAccess = localStorage.getItem('MKII_access');

      if (loginAccess?.toLowerCase() !== 'yes') {
        return;
      }

      localStorage.setItem('lastUpdated', Date.now().toString());

      const activeTabs = JSON.parse(localStorage.getItem('activeTabsMKII') || '[]');

      const updatedActiveTabs = activeTabs.filter((id) => id !== tabId);

      localStorage.setItem('activeTabsMKII', JSON.stringify(updatedActiveTabs));

      if (updatedActiveTabs.length === 0) {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie.split(';').forEach((c) => {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
      }
    });
  }
  componentWillUnmount() {
    return () => {
      window.removeEventListener('beforeunload', this);
    };
  }

  render() {
    return (
      <div className="App">
        <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
          <ProviderLocationsContextProvider>
            <IsLogin />
            <Route exact path={OKTA_LOGIN_CALLBACK_URL} component={CustomLoginCallback} />
            <SecureRoute exact path={OKTA_AUTHORIZATION_URL} component={OktaLogin} />
            <Route path="/requestAccess" component={RequestProvider} />
            <SecureRoute exact path="/locations/place/:lng/:lat/:zoom" component={Locations} />
            <SecureRoute exact path="/analytics" component={Analytics} />
            <SecureRoute exact path="/myTransactions" component={MySurveysPage} />
            <SecureRoute path="/uploadFile" component={ProviderFileUpload} />
            <SecureRoute path="/providerDashboard" component={ProviderDashboard} />
            <SecureRoute path="/providerTransactions" component={ProviderTransactions} />
            <SecureRoute path="/providerLocations" component={ProviderLocations} />
            <SecureRoute path="/providerProfile" component={providerProfile} />

            <SecureRoute path="/addLocation/single" component={AddLocations} />
            <SecureRoute
              exact
              path="/logout"
              render={() => {
                return <Redirect to="/locations/place/-78.549668/39.014/7" />;
              }}
            />
          </ProviderLocationsContextProvider>
        </Security>
      </div>
    );
  }
}

const AppWithRouterAccess = withRouter(App);
// eslint-disable-next-line
export default class extends Component {
  render() {
    return (
      <Router basename={'MKII'}>
        <AppWithRouterAccess />
      </Router>
    );
  }
}
