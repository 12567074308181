import React, { useContext } from 'react';

import { NavLink } from 'react-router-dom';

import classes from './LeftNavbar.module.css';

import folderIcon from '../../assets/images/folderIcon.svg';
import mapIcon from '../../assets/images/mapIcon.svg';
import surveyIcon from '../../assets/images/surveyIcon.svg';
import useHttp from '../../hooks/http';
import { AnalyticsContext } from '../Analytics/context/AnalyticsContext';

function LeftNavbar(props) {
  const redirectURL = sessionStorage.getItem('pathNeeded')
    ? sessionStorage.getItem('pathNeeded')
    : '/locations/place/-78.549668/39/7';

  const analyticContext = useContext(AnalyticsContext);

  return (
    <div className={`${classes.mk2} ${classes.leftBar}`}>
      <nav className={classes.nav}>
        <ul>
          <li title="Locations">
            <NavLink
              to={redirectURL}
              className={classes.locations}
              activeClassName={classes.active}
            >
              <div className={classes.iconBox}>
                {/* <img src={mapIcon} alt="map" className={classes.image}></img> */}
              </div>
            </NavLink>
          </li>
          {/* <li>
                    <NavLink to="#">
                    <div className={classes.iconBox}><img src={folderIcon} alt="folder" className={classes.image}></img></div>
                    </NavLink>
                </li> */}
          <li title="My Transactions">
            <NavLink
              to="/myTransactions"
              className={classes.providers}
              activeClassName={classes.active}
            >
              <div className={classes.iconBox}></div>
            </NavLink>
          </li>
          <li title="Agile Analytics" onClick={analyticContext?.goToMainPage}>
            <NavLink to="/analytics" className={classes.analytics} activeClassName={classes.active}>
              <div className={classes.iconBox}></div>
            </NavLink>
          </li>
          {/* {isEdgeUser &&
                        <li title="Upload File">
                            <NavLink to='/uploadFile' activeClassName={classes.active}>
                                <div className={classes.iconBox}><img src={folderIcon} alt="folder" className={classes.image}></img></div>
                            </NavLink>
                        </li>
                    } */}
        </ul>
      </nav>
    </div>
  );
}

export default LeftNavbar;
