import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import useOutsideClick from '../../../hooks/useOutsideClick';
import { isNumberPattern } from '../../../utils/patterns';

const selectOptions = [10, 25, 50, 100];

export const isPageValid = (currentPage, totalPages) => {
  const firstPage = 1;

  if (!isNumberPattern.test(currentPage)) return false;

  const page = Number(currentPage);

  if (firstPage > page) return false;
  if (page > totalPages) return false;

  return true;
};

const Pagination = ({
  page,
  pageSize,
  totalCount,
  onPageSize,
  toPage,
  pageSizeOptions,
  debounceDelay,
  padding,
  gap,
}) => {
  const [currentPage, setCurrentPage] = React.useState(page || 1);
  const [isPageInputOpened, setIsPageInputOpened] = React.useState(false);
  const [pageSizeVariants] = React.useState(pageSizeOptions || selectOptions);
  const inputRef = React.useRef(null);

  const toFirstPage = () => toPage(0);
  const toLastPage = () => toPage(totalCount - 1);
  const toNextPage = () => toPage((prev) => prev + 1);
  const toPrevPage = () => toPage((prev) => prev - 1);

  const isCanPrevPage = page > 1;
  const isCanNextPage = page < totalCount;

  const onChangeCurrentPage = React.useCallback(
    (e) => {
      const { value } = e.target;

      if (isPageValid(value, totalCount)) {
        setCurrentPage(Number(value));
      }

      if (!value) {
        setCurrentPage('');
      }
    },
    [setCurrentPage, totalCount],
  );

  const onDebounceChangePage = React.useCallback(
    (e) => {
      const { value } = e.target;

      // if page number is valid and current page doesn't equal to prev page
      if (isPageValid(value, totalCount) && !_.isEqual(Number(value), page)) {
        toPage(Number(value) - 1);
        setIsPageInputOpened(false);
      }
    },
    [toPage, totalCount, page],
  );

  const onCloseInput = () => setIsPageInputOpened(false);

  const onChangePageDebounce = React.useMemo(
    () => _.debounce(onDebounceChangePage, debounceDelay || 700),
    [onDebounceChangePage, debounceDelay],
  );

  // update current page from props
  React.useEffect(() => {
    setCurrentPage(page);
  }, [page, setCurrentPage]);

  React.useEffect(() => {
    if (!isPageInputOpened && !currentPage) {
      setCurrentPage(page);
    }
  }, [isPageInputOpened, currentPage, page]);

  useOutsideClick(inputRef, onCloseInput);

  return (
    <PaginationContainer padding={padding} gap={gap}>
      <PageContainer>
        <MaxPerPage>Rows Per Page:</MaxPerPage>
        <SelectInputContainer>
          <SelectInput value={pageSize} onChange={(e) => onPageSize(Number(e.target.value))}>
            {pageSizeVariants.map((select, index) => (
              <InputOption key={index} value={select}>
                {select}
              </InputOption>
            ))}
          </SelectInput>
        </SelectInputContainer>
      </PageContainer>
      <AllPagesContainer>
        <GoByPage onClick={toFirstPage} disabled={!isCanPrevPage}>
          {'I<'}
        </GoByPage>
        <GoByPage onClick={toPrevPage} disabled={!isCanPrevPage}>
          {'<'}
        </GoByPage>
        <CurrentPage>
          {isPageInputOpened ? (
            <ChangePage
              ref={inputRef}
              value={currentPage}
              autoFocus
              onChange={(e) => {
                onChangeCurrentPage(e);
                onChangePageDebounce(e);
              }}
            />
          ) : (
            <TotalPages onClick={() => setIsPageInputOpened(true)}>
              {currentPage} of {totalCount}
            </TotalPages>
          )}
        </CurrentPage>
        <GoByPage onClick={toNextPage} disabled={!isCanNextPage}>
          {'>'}
        </GoByPage>
        <GoByPage onClick={toLastPage} disabled={!isCanNextPage}>
          {'>I'}
        </GoByPage>
      </AllPagesContainer>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => padding || '20px 0'};
  gap: ${({ gap }) => gap || '10px'};
`;

const PageContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 24px;
`;

const AllPagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
`;

const MaxPerPage = styled.div`
  font-family: 'Calibre-R';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: rgba(26, 26, 26, 0.6);
`;

const TotalPages = styled.div`
  cursor: pointer;
  width: 100%;
`;

const SelectInputContainer = styled.div``;

const SelectInput = styled.select`
  border: none;
  width: 65px;
  height: 24px;
  color: #1a1a1a;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
  }
`;
const InputOption = styled.option`
  background: #fff;
  color: #1a1a1a;
  cursor: pointer;
`;

const CurrentPage = styled.div`
  display: flex;
  padding: 4px 8px;
  border: 1px solid rgba(26, 26, 26, 0.6);
  border-radius: 10px;
  font-size: 12px;
`;

const ChangePage = styled.input`
  border: none;
  font-size: 12px;
  color: #1a1a1a;
  text-align: center;
  margin-right: 8px;
  width: 50px;
`;

const GoByPage = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  font-size: 20px;
  background: #ffffff;
  color: ${(props) => (props?.disabled ? 'rgba(26, 26, 26, 0.12)' : 'rgba(26, 26, 26, 0.6)')};
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  &:not(:disabled):hover {
    background-color: #ededed;
  }
`;

export default Pagination;
